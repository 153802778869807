import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { ConfigHeader, InfoText, Slider, Tracker } from "../../Modal/ModalStyle";
import { Participants } from "../../../../assets/icons/Icons";
import styled from "styled-components";
import { DEFAULT_MAX_GROUP_SIZE } from "../../../../lib/defaults";
import { store, useStore } from "../../../../session/store";

const TrackerWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #000;
`;

const RangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 30px auto 0px auto;
`;

const SlidersControl = styled.div`
  position: relative;
  min-height: 30px;
`;

const UnmatchedParticipants = styled.div`
  color: red;
`;

export const GroupSizeConfig = () => {
  const currentFlowConfig = store.use.currentFlowConfig() as LobbyInvitation;

  const preferMinMax = (value: number) => {
    if (value === currentFlowConfig.min) {
      return "min";
    } else if (value === currentFlowConfig.max) {
      return "max";
    } else {
      return undefined;
    }
  };

  const [preference, setPreference] = useState(preferMinMax(currentFlowConfig.preferred));

  const handleMaxValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    // check if value is bigger than the min value, if not set the max value to min value
    const updatedFlowConfig = Object.assign({}, currentFlowConfig);
    if (value <= currentFlowConfig.min) {
      updatedFlowConfig.min = value;
      updatedFlowConfig.max = value;
    } else {
      updatedFlowConfig.max = value;
    }
    if (preference === "max" || value < currentFlowConfig.preferred) {
      updatedFlowConfig.preferred = value;
    }
    useStore.setState({ currentFlowConfig: updatedFlowConfig });
  };

  const handleMinValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value);
    const updatedFlowConfig = Object.assign({}, currentFlowConfig);
    // check if value is smaller than the max value, if not set the min value to max value
    if (value >= currentFlowConfig.max) {
      updatedFlowConfig.min = value;
      updatedFlowConfig.max = value;
    } else {
      updatedFlowConfig.min = value;
    }
    if (preference === "min" || value > currentFlowConfig.preferred) {
      updatedFlowConfig.preferred = value;
    }
    useStore.setState({ currentFlowConfig: updatedFlowConfig });
  };

  const setPreferredGroupSize = (newPreferred: number) => {
    const updatedFlowConfig = Object.assign({}, currentFlowConfig);
    updatedFlowConfig.preferred = newPreferred;
    useStore.setState({ currentFlowConfig: updatedFlowConfig });
  };

  return (
    <>
      <ConfigHeader style={{ padding: "10px 10px 5px 0" }}>
        <Participants stroke="#000" />
        <FormattedMessage id="group_size.title" defaultMessage="Group Size" />

        {currentFlowConfig.numUnmatchedParticipants ? (
          <UnmatchedParticipants>
            <FormattedMessage
              id="group_size.unmatched_participants"
              defaultMessage="{numUnmatchedParticipants} unmatched participants"
              values={{
                numUnmatchedParticipants: currentFlowConfig.numUnmatchedParticipants,
              }}
            />
          </UnmatchedParticipants>
        ) : (
          <></>
        )}
      </ConfigHeader>
      <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        <InfoText>
          {currentFlowConfig.min === currentFlowConfig.max ? (
            <FormattedMessage
              id="group_size.info_same"
              defaultMessage="Groups of exactly {min} participants"
              values={{ min: currentFlowConfig.min }}
            />
          ) : (
            <>
              <FormattedMessage
                id="group_size.info"
                defaultMessage="Groups from {min} to {max}"
                values={{ min: currentFlowConfig.min, max: currentFlowConfig.max }}
              />
              <span style={{ fontWeight: 500 }}>
                <FormattedMessage
                  id="group_size.prefered"
                  defaultMessage=" ( Prefered: {preferedGroupSize} )"
                  values={{ preferedGroupSize: currentFlowConfig.preferred }}
                />
              </span>
            </>
          )}
        </InfoText>
        <TrackerWrapper>
          <Tracker
            onClick={() => {
              setPreference("min");
              setPreferredGroupSize(currentFlowConfig.min);
            }}
            selected={"min" === preference}
          >
            <FormattedMessage id="group_size.min" defaultMessage="Min: " />
            {currentFlowConfig.min}
          </Tracker>

          <Tracker
            onClick={() => {
              setPreference("max");
              setPreferredGroupSize(currentFlowConfig.max);
            }}
            selected={"max" === preference}
          >
            <FormattedMessage id="group_size.max" defaultMessage="Max: " />
            {currentFlowConfig.max === DEFAULT_MAX_GROUP_SIZE + 1 ? (
              <FormattedMessage id="group_size.all" defaultMessage="All" />
            ) : (
              currentFlowConfig.max
            )}
          </Tracker>
        </TrackerWrapper>
        <RangeContainer>
          <SlidersControl>
            <Slider
              type="range"
              className="fromSlider"
              value={currentFlowConfig.min}
              min={1}
              max={DEFAULT_MAX_GROUP_SIZE + 1}
              onChange={handleMinValueChange}
            ></Slider>
            <Slider
              type="range"
              className="toSlider"
              value={currentFlowConfig.max}
              min={1}
              max={DEFAULT_MAX_GROUP_SIZE + 1}
              onChange={handleMaxValueChange}
            ></Slider>
          </SlidersControl>
        </RangeContainer>
      </div>
    </>
  );
};
