import React, { FC } from "react";
import { styled } from "styled-components";

const Input = styled.input`
  font-weight: normal;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #888;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  background-color: light-dark(#e8e8e8, #333);
`;

interface Props {
  className?: string;
  id?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  pattern?: string;
  placeholder?: string;
  ref?: React.RefObject<HTMLInputElement | null>;
  type?: string;
  value?: string;
}

export const TextInput: FC<Props> = (props) => {
  return <Input {...props} />;
};
