import React, { FC } from "react";
import { ScreenshareTile } from "./ScreenshareTile";
import { VideoGridTile } from "./VideoGridTile";
import { PackedGrid } from "react-packed-grid";
import { PaginationControls } from "./PaginationControls";
import { ScreenShare } from "@daily-co/daily-react";
import { PARTICIPANTS_PER_PAGE } from "../../../lib/defaults";
import { AnalyticsWidget } from "../AnalyticsWidget/AnalyticsWidget";
import { store } from "../../../session/store";
import { VideoPlayerTile } from "./VideoPlayerTile";
import { MatchingStep, useShowMatchingStep } from "../Steps/MatchingStep";
import Username from "../Username/Username";

interface VideoGridDesktopProps {
  filteredParticipants: Participant[];
  screens: ScreenShare[];
  showSelf: boolean;
  currentUser: UserResponse;
  roleData: RoleData;
  page: number;
  setPage: (page: number) => void;
  video?: HTMLVideoElement;
}

export const GridView: FC<VideoGridDesktopProps> = ({ filteredParticipants, screens, page, setPage, video }) => {
  const pages = Math.ceil(filteredParticipants.length / PARTICIPANTS_PER_PAGE);
  const currentUser = store.use.currentUser();
  const roleData = store.use.roleData();
  const participants = store.use.participants();
  const showExternalContent = store.use.showExternalContent();
  const currentStep = store.use.currentStep();
  const showMatchingStep = useShowMatchingStep(currentStep);

  return (
    <>
      {screens.length > 0 && (
        <div style={{ width: "80%", flexShrink: 0, height: "100%" }}>{<ScreenshareTile screen={screens[0]} />}</div>
      )}
      {showMatchingStep ? (
        <MatchingStep showMatchingStep={true} />
      ) : video ? (
        <div style={{ width: "80%", flexShrink: 0, height: "100%" }}>{<VideoPlayerTile video={video} />}</div>
      ) : (
        showExternalContent && <AnalyticsWidget />
      )}

      <div
        style={{
          height: "calc(100% - 60px)",
          width: "100%",
          alignSelf: "top",
          justifySelf: "flex-start",
        }}
      >
        <PackedGrid boxAspectRatio={0.9} className="fullscreen">
          {filteredParticipants.slice(page * PARTICIPANTS_PER_PAGE, (page + 1) * PARTICIPANTS_PER_PAGE).map((p) => {
            return (
              <VideoGridTile
                dailySessionID={p.dailySessionID}
                userName={p.name}
                warmspaceID={p.id}
                participantLocation={p.location}
                key={`video-${p.id}`}
              />
            );
          })}
        </PackedGrid>

        {filteredParticipants.length > PARTICIPANTS_PER_PAGE && (
          <PaginationControls>
            <div className={`pageIndicator`} key={`prevPage`} onClick={() => setPage(Math.max(0, page - 1))}>
              &lt;
            </div>
            {Array.from(Array(pages).keys()).map((i) => {
              return (
                <div
                  className={`pageIndicator ${page === i ? "active" : ""}`}
                  key={`page-${i}`}
                  onClick={() => setPage(i)}
                >
                  {i + 1}
                </div>
              );
            })}
            <div className={`pageIndicator`} key={`nextPage`} onClick={() => setPage(Math.min(pages - 1, page + 1))}>
              &gt;
            </div>
          </PaginationControls>
        )}
        <Username
          style={{ bottom: "70px", right: "10px", position: "fixed" }}
          participantLocation={"session"}
          solo={false}
          isLocal={true}
          dailyID={currentUser.dailySessionID || participants[currentUser.id].dailySessionID} // TODO VG: Fix this. This isn't right. Something must be going wrong somewhere
          roleData={roleData[currentUser.id]}
          userId={currentUser.id}
        />
      </div>
    </>
  );
};
