import styled from "styled-components";
import React, { useEffect, useRef } from "react";
import { ParticipantsButton } from "./ParticipantsButton";
import SwitchDigitalPhysicalButton from "./SwitchDigitalPhysicalButton";
import BlurBackground from "./BlurBackground";
import { store, useStore } from "../../../session/store";
import { useFullscreenHandler } from "../../../hooks/useFullscreenhandler";
import MenuButton from "./MenuButton";
import expand from "../../../assets/icons/expand.svg";
import compress from "../../../assets/icons/compress.svg";
import { FormattedMessage } from "react-intl";
import { Video, Microphone, Speaker } from "../../../assets/icons/Icons";
import { useDevices } from "@daily-co/daily-react";
import { modalClear } from "../Modal/Modals";
import { RaiseHandButton } from "./RaiseHandButton";
import { RecordButton } from "./RecordButton";
import { updateEndLobbyUrl } from "../../../session/SessionChannel/updateEndLobbyUrl";
import { can } from "../../../helpers/can";
import { updateAutoLaunchFlow } from "../../../session/SessionChannel/updateAutoLaunchFlow";
import ClosedCaptionsButton from "./ClosedCaptionsButton";
import NoiseCancellation from "./NoiseCancellation";
import { TextInput } from "../TextInput/TextInput";

const Wrapper = styled.div`
  width: 100%;
  max-width: 430px;
  margin: auto;
  text-align: center;
  background-color: #222;
  padding: 10px 10px;

  border-radius: 10px;
  box-sizing: border-box;

  position: relative;
  z-index: 10;
  color: #fff;
`;

// const EmojisWrapper = styled.div`
//   display: flex;
//   aling-items: center;
//   justify-content: space-evenly;
//   padding: 8px;
// `;

const SeparatorTop = styled.div`
  width: 100%;
  height: 0;
  margin: 10px auto 5px;

  border-top: 1px solid #444;
  border-bottom: 1px solid #111;
`;

const SeparatorBottom = styled.div`
  width: 100%;
  height: 0;
  margin: 5px auto 10px;

  border-top: 1px solid #444;
  border-bottom: 1px solid #111;
`;

const SubMenuButtons = styled.div`
  gap: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  > div.menuButton {
    width: 100px;
    max-width: 100px;
    flex-shrink: 0;
    flex-grow: 1;
  }

  box-sizing: border-box;
`;

export const Select = styled.select`
  width: 100%;
  background-color: #333;
  border: none;
  color: #fff;

  font-size: 16px;
  box-sizing: border-box;
  padding: 4px;
  border-radius: 10px;

  border: 1px solid #333;

  &:hover {
    background-color: #555;
  }

  &:focus {
    outline: none;
    border: 1px solid #999;
  }
`;

const DeviceMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin: auto;
  margin-bottom: 10px;
  gap: 8px;
  overflow: hidden;

  > div {
    display: flex;
    gap: 5px;
    align-items: center;
    background-color: #333;
    padding-left: 10px;
    border-radius: 10px;
  }

  button {
    border: 1px solid #383838;
    background-color: #333;
    border-radius: 10px;
    max-width: 32%;
    flex: 1;
    flex-shrink: 0;

    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        max-width: 100%;
      }
    }

    > div {
      padding: 7px 6px;
    }

    &:hover {
      background-color: #555;
    }
  }
`;

const VolumeSliders = styled.div`
  display: flex;
  font-size: 14px;
  gap: 10px;
  width: 100%;
  margin: auto;
  margin-bottom: 10px;
`;

const LobbySettings = styled.div`
  font-size: 14px;
  width: 100%;
  margin: auto;
  margin-bottom: 10px;
  display: flex;
  gap: 8px;

    button {
        border: 1px solid #383838;
        background-color: #333;
        border-radius: 10px;
        width: 100%;

        > div {
            padding: 5px 9px 4px;

            span {
                height: 19px;
                overflow: hidden;
            }
        }

        &:hover {
            background-color: #555;
        }
`;

const SettingsExternalUrl = styled.span`
  width: 100%;
`;

const SettingsAutoLaunchFlow = styled.span`
  width: 50%;
`;

const InputWrapper = styled.div`
  width: 100%;
  position: relative;

  input {
    border-width: 0;
    background: #333;
    color: #fff;
    border-radius: 11px;
    padding: 6px 12px;
    font-weight: 200;
    width: 100%;
    box-sizing: border-box;
  }
  input:focus {
    box-shadow: none;
  }
`;

const SmallLabel = styled.div`
  font-size: 11px;
  line-height: 18px;
  padding-left: 7px;
  text-align: left;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const SavingMessage = styled.span`
  border-radius: 9px;
  font-size: 19px;
  background-color: #444;
  position: absolute;
  width: fit-content;
  padding: 0 18px;
  top: 4px;
  right: 4px;
  color: #eee;

  &.button:hover {
    background-color: #666;
    cursor: pointer;
  }

  &.flashing {
    animation-name: flashing;
    animation-duration: 1.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    transition: none !important;
  }

  @keyframes flashing {
    0% {
      background-color: #444;
    }
    50% {
      background-color: #666;
    }
    100% {
      background-color: #444;
    }
  }
`;

const VolumeSliderContainer = styled.div`
  text-align: left;
  padding: 4px 17px;
  padding-top: 8px;
  background-color: #333;
  border-radius: 10px;
  width: 100%;

  input {
    width: 100%;
    margin: 0px;
  }
`;

const MoreMenu = () => {
  const inPerson = store.use.inPerson();
  const musicVolume = store.use.musicVolume();
  const musicPlaying = store.use.musicPlaying();
  const narrationVolume = store.use.narrationVolume();
  const interfaceRules = store.use.interfaceRules();
  const deviceClassification = store.use.deviceClassification();
  const invitation = store.use.invitation();
  const currentUser = store.use.currentUser();
  const transcribing = store.use.transcribing();

  const {
    currentCam,
    currentMic,
    currentSpeaker,
    refreshDevices,
    cameras,
    microphones,
    speakers,
    setCamera,
    setMicrophone,
    setSpeaker,
  } = useDevices();

  useEffect(() => {
    if (!inPerson) {
      refreshDevices();
    }
  }, [inPerson]);

  function changeMusicVolume(volume: string) {
    if (volume === "0" && musicPlaying) {
      useStore.setState({ musicPlaying: false });
    } else if (volume !== "0" && !musicPlaying) {
      useStore.setState({ musicPlaying: true });
    }
    useStore.setState({ musicVolume: parseFloat(volume) });
  }

  function changeNarrationVolume(volume: string) {
    useStore.setState({ narrationVolume: parseFloat(volume) });
  }

  const { toggleFullscreen, isFullscreen } = useFullscreenHandler();

  const isBiggerScreen = deviceClassification.size !== "small";
  const isLobby = invitation.lobby;

  const inputRef = useRef(null);

  const refAutolaunchDelay = useRef<HTMLSelectElement>(null);
  const refAutolaunchFlow = useRef<HTMLSelectElement>(null);

  function selectEndLobbyUrl() {
    const textInput = inputRef.current! as HTMLInputElement;
    textInput.select();
    textInput.setSelectionRange(0, 99999); // For mobile devices according to w3schools.com
  }

  useEffect(() => {
    !inPerson &&
      isLobby &&
      inputRef.current &&
      setTimeout(() => {
        const textInput = inputRef.current as HTMLInputElement | null;
        if (textInput) {
          textInput.value = invitation.endLobbyUrl || "";
        }
      }, 100);
  }, [invitation]);

  // Install an esc key listener to close the modal
  useEffect(() => {
    const escFunction = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        modalClear("MoreMenu");
      }
    };
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  });

  const [updateMeetingUrlTimeout, setUpdateMeetingUrlTimeout] = React.useState<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);

  const [savingMeetingLink, setSavingMeetingLink] = React.useState<boolean>(false);
  const [showSaveMeetingLink, setShowSaveMeetingLink] = React.useState<boolean>(false);

  function onChangeEndLobbyUrl() {
    setShowSaveMeetingLink(false);
    const textInput = inputRef.current! as HTMLInputElement;
    if (textInput.value != invitation.endLobbyUrl) {
      if (updateMeetingUrlTimeout) {
        clearTimeout(updateMeetingUrlTimeout);
      }
      const timeout = setTimeout(() => {
        if (textInput.value != invitation.endLobbyUrl) {
          setShowSaveMeetingLink(true);
        }
      }, 300);
      setUpdateMeetingUrlTimeout(timeout);
    }
  }

  function saveEndLobbyUrl() {
    const textInput = inputRef.current! as HTMLInputElement;
    if (textInput.value != invitation.endLobbyUrl) {
      setSavingMeetingLink(true);
      updateEndLobbyUrl(textInput.value, () => {
        setSavingMeetingLink(false);
      });
    }
    setShowSaveMeetingLink(false);
  }

  const disableAutoLaunch: unknown = {
    name: "[none]",
    hashId: "",
  };

  let flows: LobbyInvitation[] = [];
  if (isLobby && invitation.lobbyInvitations) {
    flows = invitation.lobbyInvitations.slice();
    flows.unshift(disableAutoLaunch as LobbyInvitation);
  }

  const submenuButtons: React.JSX.Element[] = [];

  if (!isBiggerScreen) submenuButtons.push(<ParticipantsButton key={"participants"} />);
  if (!inPerson && !isBiggerScreen) submenuButtons.push(<RecordButton key={"record"} />);
  if (!inPerson && !isBiggerScreen && isLobby) submenuButtons.push(<RaiseHandButton key={"raiseHands"} />);
  if (!inPerson) submenuButtons.push(<BlurBackground key={"blur"} />);
  if (!inPerson) submenuButtons.push(<NoiseCancellation key={"noiseCancellation"} />);
  if (interfaceRules.allowModeSwitching)
    submenuButtons.push(<SwitchDigitalPhysicalButton inPerson={inPerson} key={"switchMode"} />);
  if (transcribing) submenuButtons.push(<ClosedCaptionsButton key={"cc"} />);
  submenuButtons.push(
    <MenuButton
      buttonIcon={<img height="18px" src={isFullscreen ? compress : expand} />}
      onClick={() => {
        modalClear("MoreMenu");
        toggleFullscreen(isFullscreen);
      }}
      key={"fullscreen"}
    >
      {isFullscreen && <FormattedMessage id="more_menu.exit-fullscreen" defaultMessage={"Exit Fullscreen"} />}
      {!isFullscreen && <FormattedMessage id="more_menu.enter-fullscreen" defaultMessage={"Fullscreen"} />}
    </MenuButton>,
  );

  return (
    <Wrapper>
      {/* <EmojisWrapper>
        <Button className="emoji" title="Thumbs up">
          &#128077;
        </Button>
        <Button className="emoji" title="Red heart">
          &#x2764;&#xFE0F;
        </Button>
        <Button className="emoji" title="Tears of joy">
          &#128514;
        </Button>
        <Button className="emoji" title="Open Mouth">
          &#128558;
        </Button>
        <Button className="emoji" title="Disappointed but relieved face">
          &#128549;
        </Button>
        <Button className="emoji" title="Pray">
          &#128591;
        </Button>
      </EmojisWrapper>
       */}
      {(!invitation.disableMusic || invitation.audioPaths.length > 0) && (
        <VolumeSliders>
          {!invitation.disableMusic && !inPerson && (
            <VolumeSliderContainer>
              <label>Music volume:</label>
              <input
                onChange={(e) => changeMusicVolume(e.target.value)}
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={invitation.disableMusic ? 0 : musicVolume}
                disabled={invitation.disableMusic}
                data-testid="music-volume"
              />
            </VolumeSliderContainer>
          )}

          {invitation.audioPaths.length > 0 && (
            <VolumeSliderContainer>
              <label>Narration volume:</label>
              <input
                onChange={(e) => changeNarrationVolume(e.target.value)}
                type="range"
                min="0"
                max="1"
                step="0.01"
                value={narrationVolume}
                data-testid="narration-volume"
              />
            </VolumeSliderContainer>
          )}
        </VolumeSliders>
      )}
      {!inPerson && (
        <>
          <DeviceMenu>
            <div>
              <Video width={15} fill="#ddd" />
              <Select
                name="camera"
                id="camera"
                value={currentCam?.device.deviceId}
                onChange={(e) => {
                  localStorage.setItem("warmspace.cameraDeviceId", e.target.value);
                  setCamera(e.target.value);
                }}
              >
                {cameras.map((c) => (
                  <option key={c.device.deviceId} value={c.device.deviceId}>
                    {c.device.label}
                  </option>
                ))}
              </Select>
            </div>

            <div>
              <Microphone width={10} fill="#ddd" />
              <Select
                name="microphone"
                id="microphone"
                value={currentMic?.device.deviceId}
                onChange={(e) => {
                  localStorage.setItem("warmspace.microphoneDeviceId", e.target.value);
                  setMicrophone(e.target.value);
                }}
              >
                {microphones.map((m) => (
                  <option key={m.device.deviceId} value={m.device.deviceId}>
                    {m.device.label}
                  </option>
                ))}
              </Select>
            </div>
            <div>
              <Speaker />
              <Select
                name="speaker"
                id="speaker"
                value={currentSpeaker?.device.deviceId}
                onChange={(e) => {
                  localStorage.setItem("warmspace.speakerDeviceId", e.target.value);
                  setSpeaker(e.target.value);
                }}
              >
                {speakers.map((s) => (
                  <option key={s.device.deviceId} value={s.device.deviceId}>
                    {s.device.label}
                  </option>
                ))}
              </Select>
            </div>
          </DeviceMenu>
          <SeparatorTop />
        </>
      )}
      <SubMenuButtons key={"SubMenuButtons"}>{submenuButtons.map((button) => button)}</SubMenuButtons>
      {isLobby && can("updateInvitationSettings", currentUser, invitation) && (
        <>
          <SeparatorBottom />
          {!inPerson && (
            <LobbySettings>
              <SettingsExternalUrl>
                <SmallLabel>
                  <FormattedMessage
                    id="more_menu.external_meeting_url"
                    defaultMessage="External Meeting Link for {lobbyName}"
                    values={{ lobbyName: invitation.title }}
                  />
                </SmallLabel>
                <InputWrapper>
                  <TextInput
                    placeholder="enter a Zoom/Teams/Meet link"
                    ref={inputRef}
                    onFocus={selectEndLobbyUrl}
                    onChange={onChangeEndLobbyUrl}
                  />
                  {savingMeetingLink && (
                    <SavingMessage className="flashing">
                      <FormattedMessage id="more_menu.saving_link_message" defaultMessage="Saving..." />
                    </SavingMessage>
                  )}
                  {showSaveMeetingLink && (
                    <SavingMessage className="button" onClick={saveEndLobbyUrl}>
                      <FormattedMessage id="more_menu.save_link_button" defaultMessage="Save" />
                    </SavingMessage>
                  )}
                </InputWrapper>
              </SettingsExternalUrl>
            </LobbySettings>
          )}

          <LobbySettings>
            <SettingsAutoLaunchFlow>
              <SmallLabel>
                <FormattedMessage id="more_menu.auto_lanch_delay" defaultMessage="Auto-launch Delay" />
              </SmallLabel>
              <Select
                ref={refAutolaunchDelay}
                value={invitation.autoLaunchDelaySec}
                onChange={() => {
                  updateAutoLaunchFlow(
                    parseInt(refAutolaunchDelay.current!.value),
                    refAutolaunchFlow.current!.value ?? undefined,
                  );
                }}
              >
                <option value="30">
                  <FormattedMessage id="more_menu.auto_lanch_delay_30_seconds" defaultMessage="30 seconds" />
                </option>
                <option value="60">
                  <FormattedMessage id="more_menu.auto_lanch_delay_minute" defaultMessage="1 minute" />
                </option>
                {[2, 3, 4, 5].map((minutes) => (
                  <option key={minutes} value={60 * minutes}>
                    <FormattedMessage
                      id="more_menu.auto_lanch_delay_minutes"
                      defaultMessage="{minutes} minutes"
                      values={{ minutes }}
                    />
                  </option>
                ))}
              </Select>
            </SettingsAutoLaunchFlow>
            <SettingsAutoLaunchFlow>
              <SmallLabel>
                <FormattedMessage id="more_menu.auto_lanch_flow" defaultMessage="Auto-launch Flow" />
              </SmallLabel>
              <Select
                ref={refAutolaunchFlow}
                name="autoLaunch"
                id="autoLaunch"
                value={invitation.autoLaunchFlowHashId}
                onChange={() => {
                  updateAutoLaunchFlow(
                    parseInt(refAutolaunchDelay.current!.value),
                    refAutolaunchFlow.current!.value ?? undefined,
                  );
                }}
              >
                {flows.map((flow) => (
                  <option key={flow.hashId} value={flow.hashId}>
                    {flow.name}
                  </option>
                ))}
              </Select>
            </SettingsAutoLaunchFlow>
          </LobbySettings>
        </>
      )}
    </Wrapper>
  );
};

export default MoreMenu;
