import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { DailyEventObjectInputSettingsUpdated, DailyEventObjectNonFatalError } from "@daily-co/daily-js";
import { useInputSettings } from "@daily-co/daily-react";
import toast from "react-simple-toasts";
import { BLUR_LEVEL } from "../../../lib/defaults";
import MenuButton from "./MenuButton";
import { Blur } from "../../../assets/icons/Icons";
import DailyIframe from "@daily-co/daily-js";
import { modalClear } from "../Modal/Modals";

const BlurBackground = () => {
  const intl = useIntl();
  const browserInfo = DailyIframe.supportedBrowser();
  const toggleBlurBackground = () => {
    updateInputSettings({
      video: {
        processor: {
          type: blurEnabled ? "none" : "background-blur",
          config: blurEnabled ? {} : { strength: BLUR_LEVEL },
        },
      },
    });
    const newBlurSetting = !blurEnabled;
    setBlurEnabled(newBlurSetting);
    if (newBlurSetting) {
      localStorage.setItem("warmspace.blurEnabled", "1");
    } else {
      localStorage.removeItem("warmspace.blurEnabled");
    }
    modalClear("MoreMenu");
  };

  const { updateInputSettings, inputSettings } = useInputSettings({
    onError: useCallback((event: DailyEventObjectNonFatalError) => {
      toast(`Error setting up blur backgroud`, 2000);
      console.debug("BlurBackground " + event.errorMsg);
    }, []),

    onInputSettingsUpdated: useCallback((event: DailyEventObjectInputSettingsUpdated) => {
      if (event.inputSettings?.video?.processor?.type === "background-blur") {
        setBlurEnabled(true);
      } else {
        setBlurEnabled(false);
      }
    }, []),
  });
  const [blurEnabled, setBlurEnabled] = useState<boolean>(inputSettings?.video?.processor?.type === "background-blur");

  if (!browserInfo.supportsVideoProcessing) {
    return <></>;
  }
  return (
    <MenuButton buttonIcon={<Blur fill="white" />} onClick={() => toggleBlurBackground()}>
      {!blurEnabled
        ? intl.formatMessage({
            id: "blur_background.blur_background",
            defaultMessage: "Blur",
          })
        : intl.formatMessage({
            id: "blur_background.unblur_background",
            defaultMessage: "Unblur",
          })}
    </MenuButton>
  );
};

export default BlurBackground;
