import styled from "styled-components";

export const ButtonArea = styled.div`
  display: flex;
  height: 55px;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  bottom: -20px;

  box-sizing: border-box;
  position: absolute;

  opacity: 0;
  z-index: 0;
  pointer-events: none; // Allow clicks to pass through

  transition: all 0.3s ease;

  &.showUI {
    bottom: 3px;
    z-index: 10;
    opacity: 1;
    display: flex;

    &.medium&.participants-1,
    &.large&.participants-1 {
      bottom: 8px;
    }
  }
`;
