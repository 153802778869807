import React, { JSX, useEffect, useState } from "react";
import styled from "styled-components";
import { Widget } from "./AnalyticsWidget";

const Stat = styled.div`
  position: relative;
  top: -8px;
`;

export const NumberWidget = ({ label, value, bgImage }: { label: string; value: number; bgImage?: JSX.Element }) => {
  const [currentValue, setCurrentValue] = useState(0);

  console.debug(`numer value: ${value}`);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  // @ts-ignore
  return (
    <Widget style={{ height: "68px" }}>
      {bgImage}
      {/*<Stat className="stat" style={{ "--num": currentValue }} />*/}
      <Stat className="stat">
        <span>+</span>
        {currentValue}
      </Stat>
      <span style={{ color: "#fff", fontSize: "16px", position: "absolute", bottom: "5px" }}>{label}</span>
    </Widget>
  );
};
