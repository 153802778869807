import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { getFlowTimeString } from "./FlowItem";
import { store } from "../../../session/store";
import { can } from "../../../helpers/can";

interface Props {
  flow: LobbyInvitation;
}

export const FlowItemGroupSize: FC<Props> = ({ flow }) => {
  const invitation = store.use.invitation();
  const recentHeat = store.use.recentHeat()[invitation.heatType];
  const currentUser = store.use.currentUser();
  const flowDurationString = getFlowTimeString(flow);
  const numGroupsString = flow.numGroups
    ? flow.numGroups == 1
      ? `${flow.numGroups} group`
      : `${flow.numGroups} groups`
    : "groups";

  const minGroupSize = flow.minGroupSize || flow.min;
  const maxGroupSize = flow.maxGroupSize || flow.max;
  const canBypassLockedFlows = can("bypassLockedFlows", currentUser, invitation);

  return (
    <div
      className="group-size"
      style={{
        fontSize: "14px",
        fontWeight: 400,
        color: "#4d4a4a",
        position: "relative",
        bottom: "-4px",
      }}
    >
      {flow.minHeat > recentHeat && !canBypassLockedFlows ? (
        <FormattedMessage
          id="flows_list.unlocks_at"
          defaultMessage="Unlocks at {minHeat} warmth"
          values={{ minHeat: flow.minHeat }}
        />
      ) : minGroupSize === maxGroupSize ? (
        <FormattedMessage
          id="flows_list.min_groups"
          defaultMessage="{duration} min / {numGroups} of {min}"
          values={{ duration: flowDurationString, min: minGroupSize, numGroups: numGroupsString }}
        />
      ) : flow.isGather ? (
        <FormattedMessage id="flows_list.groups_gather_all" defaultMessage="Gather all" />
      ) : minGroupSize === maxGroupSize ? (
        <FormattedMessage
          id="flows_list.min_groups"
          defaultMessage="{duration} min / {numGroups} of {min}"
          values={{ duration: flowDurationString, min: minGroupSize, numGroups: numGroupsString }}
        />
      ) : flow.flowGroup ? (
        <FormattedMessage
          id="flows_list.flow_group_min_max_groups"
          defaultMessage="{duration} min / {numGroups} of {min} - {max}"
          values={{
            duration: flowDurationString,
            min: minGroupSize,
            max: maxGroupSize,
            numGroups: numGroupsString,
          }}
        />
      ) : (
        <FormattedMessage
          id="flows_list.min_max_groups"
          defaultMessage="{duration} min / {numGroups} of {min} - {max} (prefer: {preferred})"
          values={{
            duration: flowDurationString,
            min: minGroupSize,
            max: maxGroupSize,
            preferred: flow.preferred,
            numGroups: numGroupsString,
          }}
        />
      )}
    </div>
  );
};
