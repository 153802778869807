import React, { FC, useCallback, useEffect, useState } from "react";
import { PageToggle } from "./PageToggle";
import { FormattedMessage, useIntl } from "react-intl";
import { BigButton, ConfigButton, ConfigButtons, RoomsPage } from "./RoomsPage";
import { StartNewPage, obtainFilteredParticipants } from "./StartNewPage";
import { BreakoutRoomMessage } from "../../../types/CableMessage";
import { CardBody, CardHeader, NarrowCard, TextHeader } from "../Modal/ModalStyle";
import { Button } from "../ButtonV2/Button";
import { Close, Participants, Settings } from "../../../assets/icons/Icons";
import { store, useStore } from "../../../session/store";
import { modalClear, modalShow } from "../Modal/Modals";
import styled from "styled-components";
import { FlowItem } from "./FlowItem";
import { Well } from "../Well/Well";
import { MatchingCheckBox } from "./MatchingCheckBox";
import postBreakout from "../../../lib/requests/postBreakout";
import toast from "react-simple-toasts";
import { GroupSizeConfig } from "./RoomsConfig/GroupSizeConfig";
import { AutoJoinConfig } from "./RoomsConfig/AutoJoinConfig";
import { PromptsConfig } from "./RoomsConfig/PromptsConfig";
import postTimeEstimate from "../../../lib/requests/postTimeEstimate";
import { FlowDurationConfig } from "./RoomsConfig/FlowDurationConfig";
import { can } from "../../../helpers/can";
import { deepIsEquals } from "../../../session/util";
import { PartnerCodeJoinConfig } from "./RoomsConfig/PartnerCodeJoinConfig";

interface Props {
  rooms: BreakoutRoomMessage[];
  nonLobbyRooms: BreakoutRoomMessage[];

  invitation: InvitationResponse;
}

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 4px;
  gap: 5px;
`;

const ConfigWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 100%;
  background-color: #fff;
  border-radius: 9px;
  padding: 10px;
  overflow-y: auto;
  margin-top: -8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

export const FlowsConfig: FC<Props> = ({ nonLobbyRooms, invitation }) => {
  const selectedFlow = store.use.selectedFlow();
  const selectedFlowConfigPage = store.use.selectedFlowConfigPage();
  const currentFlowConfig = store.use.currentFlowConfig();
  const participants = store.use.participants();
  const allParticipants = store.use.allParticipants();
  const excludedParticipantIds = store.use.excludedParticipantIds();
  const showRoomsConfig = store.use.showModals().includes("RoomsConfig");
  const currentUser = store.use.currentUser();

  const activeRooms = nonLobbyRooms.filter((r) =>
    r.participantIds.find((p) => {
      const participant = allParticipants[p];

      if (!participant) {
        console.log(`[RoomsPage] Expected a room participant record. participantId: ${p}`, r);
      }

      return participant && participant.location == "session" && participant.curRoomHashId == r.hashId;
    }),
  );
  const inactiveRooms = nonLobbyRooms.filter((r) => !activeRooms.find((activeRoom) => activeRoom == r));

  const intl = useIntl();
  const roomsLabel = intl.formatMessage(
    {
      id: "breakout_modal.rooms",
      defaultMessage: "Rooms {roomsCount}",
    },
    { roomsCount: activeRooms.length > 0 ? `(${activeRooms.length})` : "" },
  );

  const startNewLabel = intl.formatMessage({
    id: "breakout_modal.start_new",
    defaultMessage: "Start New",
  });

  const pages = [startNewLabel, roomsLabel];

  const [page, setPage] = useState<string>(selectedFlowConfigPage || startNewLabel);

  // It's possible the rooms label has changed due to a different number of rooms, so the label for the 'pages' tab needs to be updated
  if (!pages.includes(page)) {
    setPage(roomsLabel);
  }

  const [loading, setLoading] = React.useState(false);

  // Only include the participants that made it into the session
  const lobbyParticipants = Object.values(participants);

  const { inSessionParticipants, allExcludedParticipantIds } = obtainFilteredParticipants(
    lobbyParticipants,
    excludedParticipantIds,
  );

  const [prevSelectedFlow, setPrevSelectedFlow] = React.useState(selectedFlow || undefined);

  // If the config values weren't previously initialized or the selected flow has changed, then set them now.
  if (selectedFlow && prevSelectedFlow != selectedFlow) {
    setPrevSelectedFlow(selectedFlow);
  }

  const [minParticipants, setMinParticipants] = React.useState(selectedFlow?.min);
  const [maxParticipants, setMaxParticipants] = React.useState(selectedFlow?.max);
  const [prefParticipants, setPrefParticipants] = React.useState(selectedFlow?.preferred);
  const [timeEstimateTimeout, setTimeEstimateTimeout] = React.useState<ReturnType<typeof setTimeout> | undefined>(
    undefined,
  );

  useEffect(() => {
    if (currentFlowConfig) {
      if (prefParticipants != currentFlowConfig?.preferred) setPrefParticipants(currentFlowConfig.preferred);
      if (minParticipants != currentFlowConfig?.min) setMinParticipants(currentFlowConfig.min);
      if (maxParticipants != currentFlowConfig?.max) setMaxParticipants(currentFlowConfig.max);
    }
  }, [currentFlowConfig]);

  useEffect(() => {
    useStore.setState({ selectedFlowConfigPage: page });
  }, [page]);

  const numParticipants = inSessionParticipants.length - allExcludedParticipantIds.length;
  useEffect(() => {
    if (currentFlowConfig && !currentFlowConfig.flowGroup) {
      const estimateRequest = {
        lobbyHashId: invitation.lobbyHashId,
        flowHashId: currentFlowConfig!.hashId,
        min: currentFlowConfig!.min,
        max: currentFlowConfig!.max,
        pref: currentFlowConfig!.preferred,
        numParticipants: numParticipants,
      };

      // Wait 150 ms before making the call in case the inputs are actively changing
      if (timeEstimateTimeout) {
        clearTimeout(timeEstimateTimeout);
      }
      setTimeEstimateTimeout(
        setTimeout(() => {
          const promise = postTimeEstimate(estimateRequest);

          promise.then((result) => {
            const newFlowConfig = Object.assign({}, currentFlowConfig);
            newFlowConfig.minDuration = result.minDuration;
            newFlowConfig.maxDuration = result.maxDuration;
            newFlowConfig.minGroupSize = result.minGroupSize;
            newFlowConfig.maxGroupSize = result.maxGroupSize;
            newFlowConfig.numUnmatchedParticipants = result.numUnmatchedParticipants;
            newFlowConfig.numGroups = result.numGroups;

            // If the duration preference hasn't been explicitly selected, then if the
            // maxGroupSize is the same as the preferred group size, automatically choose
            // the "Long" duration option so that the typical group doesn't get cut short
            if (
              !newFlowConfig.durationPreferenceSelected &&
              result.maxGroupSize == newFlowConfig.preferred &&
              result.maxGroupSize != result.minGroupSize
            ) {
              newFlowConfig.durationPreference = "Long";
            }

            // Do a deep comparison to see if anything actually changed
            if (!deepIsEquals(currentFlowConfig, newFlowConfig)) {
              useStore.setState({ currentFlowConfig: newFlowConfig });
            }
          });
        }, 150),
      );
    }
  }, [currentFlowConfig, numParticipants]);

  const handleStartRoom = useCallback(async () => {
    if (selectedFlow === null) return;

    setLoading(true);

    const activeFlowConfig = useStore.getState().currentFlowConfig || selectedFlow;

    useStore.setState({
      selectedFlow,
      showMatchingConfig: false,
    });

    let promptsChanged = false;
    if (activeFlowConfig != null && activeFlowConfig != selectedFlow) {
      for (let i = 0; i < activeFlowConfig.prompts.length; i++) {
        if (activeFlowConfig.prompts[i] != selectedFlow.prompts[i]) {
          promptsChanged = true;
        }
      }
    }

    const { allExcludedParticipantIds } = obtainFilteredParticipants(
      Object.values(participants),
      excludedParticipantIds,
    );

    try {
      const state = useStore.getState();

      await postBreakout({
        lobbyInvitationHashId: invitation.lobbyHashId,
        breakoutInvitationHashId: selectedFlow!.hashId,
        excludeUserIds: allExcludedParticipantIds,
        minParticipants: activeFlowConfig!.min,
        maxParticipants: activeFlowConfig!.max,
        preferredParticipants: activeFlowConfig!.preferred,
        meetingLocation: state.inPerson ? "in_person" : "online",
        duration: activeFlowConfig!.duration, // 0 means don't enforce duration
        autoJoin: state.autoJoin,
        prompts: promptsChanged ? activeFlowConfig!.prompts : null, // null means don't override the prompts
        durationPreference: activeFlowConfig!.durationPreference,
        partnerCodeJoin: activeFlowConfig!.partnerCodeJoin === true,
      });

      if (allExcludedParticipantIds.indexOf(currentUser.id) == -1) {
        useStore.setState({ showModals: [] });
      } else {
        setPage(roomsLabel);
      }
    } catch (e) {
      toast("Unable to start flow. Please try again in a few minutes.");
      console.log(e);
    } finally {
      setLoading(false);
    }
  }, [selectedFlow, excludedParticipantIds, participants]);

  return (
    <>
      <NarrowCard className="breakout-modal">
        <CardHeader className="fullscreen">
          <TextHeader>
            <FormattedMessage id="breakout_modal.title" defaultMessage={"Flows"} />
          </TextHeader>
          <Button
            className="link"
            style={{
              position: "absolute",
              right: "15px",
            }}
            onClick={() => {
              modalClear("FlowsModal");
            }}
          >
            <Close />
          </Button>
        </CardHeader>
        <CardBody className="fullscreen">
          <PageToggle
            pages={pages}
            setPage={(page) => {
              setPage(page);
              modalClear("AddParticipantToRoom");
            }}
            activePage={page}
          />

          {page === startNewLabel && !selectedFlow && (
            <StartNewPage flows={invitation.lobbyInvitations} showGroupFlows={false} />
          )}
          {page === startNewLabel && selectedFlow?.flowGroup && (
            <StartNewPage flows={selectedFlow?.flowGroup} showGroupFlows={true} />
          )}

          {page === startNewLabel && selectedFlow && !selectedFlow?.flowGroup && (
            <>
              <Well>
                <FlowItem
                  key={selectedFlow?.hashId}
                  flow={currentFlowConfig || selectedFlow!}
                  data-testid={`flow-${selectedFlow?.hashId}`}
                  mode="back"
                />
              </Well>
              {!showRoomsConfig && <MatchingCheckBox participants={inSessionParticipants} />}

              {showRoomsConfig && (
                <ConfigWrapper>
                  <GroupSizeConfig />
                  <FlowDurationConfig />
                  <AutoJoinConfig />
                  {can("partnerCodeJoin", currentUser, invitation) && <PartnerCodeJoinConfig />}

                  {can("editCustomPrompts", currentUser, currentFlowConfig as unknown as InvitationResponse) && (
                    <PromptsConfig />
                  )}
                </ConfigWrapper>
              )}

              <ButtonWrapper>
                <ConfigButtons>
                  <ConfigButton
                    onClick={() => {
                      modalClear("RoomsConfig");
                    }}
                    className={!showRoomsConfig ? "selected" : ""}
                  >
                    <Participants />
                  </ConfigButton>
                  <ConfigButton
                    className={showRoomsConfig ? "selected" : ""}
                    onClick={() => {
                      modalShow("RoomsConfig", false);
                    }}
                  >
                    <Settings width="24px" height="24px" />
                  </ConfigButton>
                </ConfigButtons>
                <BigButton
                  disabled={
                    inSessionParticipants.length - allExcludedParticipantIds.length <
                    ((currentFlowConfig || selectedFlow)?.min || 0)
                  }
                  loading={loading}
                  onClick={handleStartRoom}
                >
                  <FormattedMessage id="confirm_breakout_modal.confirm_button" defaultMessage="Start Flow" />
                </BigButton>
              </ButtonWrapper>
            </>
          )}

          {page === roomsLabel && (
            <RoomsPage
              rooms={nonLobbyRooms}
              activeRooms={activeRooms}
              inactiveRooms={inactiveRooms}
              inSessionParticipants={inSessionParticipants}
              allParticipants={allParticipants}
            />
          )}
        </CardBody>
      </NarrowCard>
    </>
  );
};
