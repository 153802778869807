import React, { useRef, useEffect, memo } from "react";
import styled from "styled-components";
import { useIntl } from "react-intl";
import audioLevelStates from "../../../assets/audio-level-states.svg";

export enum ElementPosition {
  TopRight = "top-right",
  TopLeft = "top-left",
  BottomLeft = "bottom-left",
  BottomRight = "bottom-right",
  Circle = "circle",
  None = "",
  SelfView = "selfview",
  TileView = "tileview",
  TileViewV2 = "tileviewV2",
}

const Wrapper = styled.div`
  display: flex;
  height: 32px;
  width: auto;
  justify-content: center;

  position: absolute;
  padding: 1px 8px 0px 10px;
  background: #222;

  &.top-right {
    top: 0px;
    right: 0px;
    border-bottom-left-radius: 8px;
  }

  &.top-left {
    top: 0px;
    left: 0px;
    border-bottom-right-radius: 8px;
  }

  &.bottom-right {
    bottom: 0px;
    right: 0px;
    border-top-left-radius: 8px;
  }

  &.bottom-left {
    bottom: 0px;
    left: 0px;
    border-top-right-radius: 8px;
  }

  &.circle {
    margin-top: 8px;
    height: 35px;
    width: 35px;
    border-radius: 30px;
    padding: 5px;
    right: 5px;
  }

  &.selfview {
    position: relative;
    background: transparent !important;
    height: 25px;
    padding: 0px;
  }

  &.tileview {
    position: relative;
    background: transparent !important;
    height: 24px;
    padding: 0px;
    margin-left: 4px;
  }
  &.tileviewV2 {
    position: relative;
    background: transparent !important;
    height: 24px;
    padding: 0px;
    margin-right: 4px;
  }

  .boxContainer {
    display: flex;
    top: 2px;
    position: relative;
    justify-content: space-between;
    height: 20px;

    overflow: hidden;
    align-items: center;

    background-image: url(${audioLevelStates});
    background-repeat: no-repeat;
    background-position: 0px 8px;

    width: 20px;

    img {
      top: 8px;
      position: absolute;
    }

    &.speaking {
      background-position: 0px -13px;
    }

    &.speaking2 {
      transition: none;
      background-position: 0px -34px;
    }
  }
`;

export default memo(function AudioLevel({
  position,
  volume,
  hasAudio,
}: {
  position: ElementPosition;
  volume: number;
  hasAudio: boolean;
}) {
  const volRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  useEffect(() => {
    if (volRef.current != null && hasAudio) {
      //If volume is low, don't move animation.

      if (volume <= 0.05) {
        volRef.current.classList.remove("speaking");
        volRef.current.classList.remove("speaking2");
      } else if (volume <= 0.08) {
        volRef.current.classList.add("speaking");
      } else {
        volRef.current.classList.add("speaking2");
      }
    }
  }, [volume]);

  useEffect(() => {
    //In case audio is muted, animation should be stopped.
    if (!hasAudio) {
      if (volRef.current != null) {
        volRef.current.classList.remove("speaking");
        volRef.current.classList.remove("speaking2");
      }
    }
  }, [hasAudio]);

  return (
    <Wrapper
      className={position}
      title={intl.formatMessage({
        id: "audio_level_inner.indicator",
        defaultMessage: "Audio level indicator",
      })}
    >
      <div className="boxContainer" ref={volRef}>
        {/* <img src={audioLevelStates} color="white" /> */}
      </div>
    </Wrapper>
  );
});
