import React from "react";
import { Participants } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { store } from "../../../session/store";
import { modalToggle } from "../Modal/Modals";
import { NotificationDot } from "./ChatButton";
import { can } from "../../../helpers/can";

export const ParticipantsButton = () => {
  const participants = store.use.participants();
  const invitation = store.use.invitation();
  const currentUser = store.use.currentUser();
  const showWaitingRoomNotification =
    can("manageParticipants", currentUser, invitation) &&
    Object.values(participants).filter((p) => p.location == "waitingRoom").length > 0;

  return (
    <MenuButton
      buttonIcon={
        <>
          <Participants stroke="white" />
          <span className="badge">{Object.values(participants).length}</span>
          {showWaitingRoomNotification && (
            <NotificationDot style={{ position: "relative", left: "-36px", width: "10px", top: "-16px" }} />
          )}
        </>
      }
      onClick={() => {
        modalToggle("ManageParticipants");
      }}
    >
      <FormattedMessage id="participants_button.label" defaultMessage="Participants" />
    </MenuButton>
  );
};
