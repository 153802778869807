import styled from "styled-components";
import React, { ReactElement } from "react";

export const MenuButtonWrapper = styled.div`
  line-height: 19px;
  display: flex;
  flex-grow: 1;
  flex-basis: 1;
  flex-shrink: 0;
  text-align: center;
  position: relative;
  justify-content: center;

  height: 60px;
  overflow: visible;

  white-space: nowrap;

  @media (min-width: 736px) {
  }

  &:hover {
    background: #333;
  }

  svg {
    height: 20px;
  }

  button {
    background: transparent;
    border: none;
    color: var(--clr-fg-0);

    font-size: 13px;
    width: 100%;
    padding: 0px 10px;

    &.selected {
      color: #ffa100;
    }
  }

  .badge {
    position: relative;
    top: -5px;
    right: 0px;
    border-radius: 20px;
    font-weight: bold;
  }
`;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonIcon: ReactElement;
  children: React.ReactNode;
  menu?: ReactElement;
}
const MenuButton: React.FC<ButtonProps> = ({ buttonIcon, children, menu, ...buttonProps }) => {
  return (
    <MenuButtonWrapper className="menuButton">
      <button {...buttonProps}>
        {buttonIcon}
        <div>{children}</div>
      </button>
      {menu}
    </MenuButtonWrapper>
  );
};

export default MenuButton;
