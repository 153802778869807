// This is replacing setupSessionConsumer.tsx
import CableMessage from "../../types/CableMessage";
import { useStore } from "../store";
import { postAction, ClearLoaderFunc } from "../../lib/requests/postAction";
import { Core } from "../core";

class SessionChannel {
  socket: WebSocket | null = null;
  closed: boolean = false;
  presenceInterval: NodeJS.Timeout | null = null;

  close() {
    this.closed = true;
    this.socket?.close();
  }
}

export type Subscription = {
  perform: (action: string, data?: unknown, clearLoader?: ClearLoaderFunc) => void;
  unsubscribe: () => void;
};

let channel = new SessionChannel();

// Add channel to window for debug purposes.
declare global {
  interface Window {
    Warmspace: {
      channel: SessionChannel;
    };
  }
}
window.Warmspace = window.Warmspace || {};
window.Warmspace.channel = channel;

export const resetSessionChannel = () => {
  console.debug("[session-ui]: Resetting session channel");
  channel.close();
  channel = new SessionChannel();
};

export const setupSessionChannel = async (onReceive: (message: CableMessage) => void) => {
  console.debug("[session-ui]: Setting up new session channel");

  return new Promise<Subscription>((resolve) => {
    function initWebSocket(attempt: number = 0) {
      if (!process.env.WARMSPACE_SCHEDULE_BACKEND_WEBSOCKET_URL) {
        throw new Error("WARMSPACE_SCHEDULE_BACKEND_WEBSOCKET_URL is not set");
      }

      const curChannel = channel;
      curChannel.socket = new WebSocket(process.env.WARMSPACE_SCHEDULE_BACKEND_WEBSOCKET_URL);

      const subscription = {
        perform: (action: string, data: unknown, clearLoader: ClearLoaderFunc | undefined = undefined) => {
          console.debug("[session-ui]: Session Channel sending message", { action, data });

          postAction(Core.GetInstance().clientID, { action: action, data: data }, clearLoader).then();
        },

        unsubscribe: () => {
          resetSessionChannel();
        },
      };

      curChannel.socket.onmessage = function (event) {
        onReceive(JSON.parse(event.data));
      };

      curChannel.socket.onerror = function () {
        console.log("[session-ui]: Session Channel webSocket encountered error. Closing socket.");
        curChannel.socket?.close();
      };

      curChannel.socket.onopen = function () {
        attempt = 0;
        initializeSocket(curChannel, subscription);
      };

      function initializeSocket(
        curChannel: SessionChannel,
        subscription: {
          unsubscribe: () => void;
          perform: (action: string, data: unknown, clearLoader?: ClearLoaderFunc | undefined) => void;
        },
      ) {
        if (!curChannel.socket?.OPEN) {
          if (attempt == 20) {
            throw Error(`[session-ui] Websocket failed to open within 2 seconds.`);
          } else {
            attempt++;
            console.info(
              `[session-ui] Websocket is not yet open, so waiting a 100ms before trying to initialize. Attempt: ${attempt}`,
            );
            setTimeout(() => {
              initializeSocket(curChannel, subscription);
            }, 100);
          }
        }

        // Send the relevant bits to initiate a connection
        const state = useStore.getState();
        const location = state.mode == "session" ? "session" : "ready-room";

        curChannel.socket?.send(
          state.invitation.hashedID +
            "/" +
            state.core.clientID +
            "/" +
            location +
            "/" +
            (state.currentUser.dailySessionID || ""),
        );

        console.debug("[session-ui]: Session Channel WebSocket is connected.");
        resolve(subscription);

        curChannel.presenceInterval = setInterval(() => {
          curChannel.socket?.send(JSON.stringify({ action: "ping" }));
        }, 5000);

        // Immediately request the latest state
        subscription.perform("get_state", {});
        useStore.setState({ sessionConsumerIsConnected: true });

        attempt = 0;
      }

      curChannel.socket.onclose = function () {
        if (curChannel.presenceInterval) {
          clearInterval(curChannel.presenceInterval);
        }
        curChannel.presenceInterval = null;

        // If the current channel hasn't been deliberately closed, then automatically reconnect
        if (!curChannel.closed) {
          // Reconnect in 300 ms
          const reconnectTime = 3000;
          console.debug("[session-ui]: Session Channel WebSocket is closed. Reconnecting...");
          if (attempt >= 2) {
            useStore.setState({ sessionConsumerIsConnected: false });
          }

          setTimeout(function () {
            initWebSocket(attempt + 1);
          }, Math.floor(reconnectTime));
        } else {
          console.debug(
            "[session-ui]: Session Channel WebSocket is closed. Not reconnecting because this Channel was closed deliberately.",
          );
        }
      };
    }

    initWebSocket();
  });
};
