import React, { FC, useState } from "react";
import { FormattedMessage } from "react-intl";
import { muteParticipant } from "../../../session/SessionChannel/muteParticipant";
import styled from "styled-components";
import { useParticipantProperty } from "@daily-co/daily-react";
import { SmallButton } from "./ManageParticipantModal";
import { admitParticipant } from "../../../session/SessionChannel/admitParticipant";
import { Button } from "../ButtonV2/Button";
import { makeParticipantFacilitator } from "../../../session/SessionChannel/makeParticipantFacilitator";
import { useStore } from "../../../session/store";
import { moveToWaitingRoom } from "../../../session/SessionChannel/moveToWaitingRoom";
import { dropParticipant } from "../../../session/SessionChannel/dropParticipant";

const Participant = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 5px 5px 5px 0;
  overflow: visible;

  i {
    margin-left: 6px;
    font-size: 12px;
    color: #555;
  }
`;

const ParticipantButtons = styled.div`
  display: flex;
  gap: 5px;
  position: relative;
`;

const OptionsButton = styled(Button)`
  background: #ddd;
  color: #444;
  font-size: 20px;
  line-height: 8px;
  padding: 0 5px 9px;
  border-radius: 11px;
  font-weight: bold;
`;

const OptionsMenu = styled.div`
  position: absolute;
  right: 33px;
  width: max-content;
`;

const OptionsMenuItem = styled(Button)`
  display: inline-block;
  background: #ddd;
  font-weight: 400;
  width: max-content;
  font-size: 12px;
  padding: 6px 10px 4px;
  color: #111;
  margin-left: 5px;
  position: relative;
  top: -0.5px;
  right: -0.5px;
`;

interface Props {
  currentUser: UserResponse;
  participant: Participant;
  mutingEnabled: boolean;
  yourId: number;
  index: number;
  setShowDropParticipantsModal: (value: ((prevState: boolean) => boolean) | boolean) => void;
  setParticipant: (
    value: ((prevState: Participant | undefined) => Participant | undefined) | Participant | undefined,
  ) => void;
  canManageParticipants: boolean;
  inPerson: boolean;
}

export const ManageParticipantItem: FC<Props> = ({
  currentUser,
  participant,
  mutingEnabled,
  yourId,
  index,
  setShowDropParticipantsModal,
  setParticipant,
  canManageParticipants,
  inPerson,
}) => {
  const inWaitingRoom = participant.location === "waitingRoom";
  const trackState = useParticipantProperty(participant.dailySessionID, "tracks");
  const showMute = mutingEnabled && participant.location === "session";
  const hasAudio = trackState && trackState.audio.state === "playable";

  const [showContextMenuForParticipant, setShowContextMenuForParticipant] = useState<number | null>(null);

  return (
    <Participant key={`drop-user-${index}`} data-testid={`participant-${participant.name}`}>
      <div style={{ display: "flex" }}>
        <div key={`drop-user-name-${index}`}>
          {participant.name}
          {participant.authorizations.length > 0 && (
            <FormattedMessage
              id="manage_participant_modal.role"
              defaultMessage=" ({role})"
              values={{ role: participant.authorizations.join(",") }}
            />
          )}
          <i>
            {participant.location === "ready-room" ? (
              <FormattedMessage id="manage_participant_modal.getting_ready" defaultMessage=" (Getting ready)" />
            ) : (
              ""
            )}
          </i>
          {inWaitingRoom && <FormattedMessage id="manage_participant_modal.tile.you" defaultMessage="(Waiting room)" />}
          {participant?.id == yourId && (
            <FormattedMessage id="manage_participant_modal.tile.you" defaultMessage="(you)" />
          )}
          &nbsp;
        </div>
        {participant.location === "offline" ? (
          <i>
            <FormattedMessage id="manage_participant_modal.offline" defaultMessage="Offline" />
          </i>
        ) : (
          ""
        )}
      </div>
      <ParticipantButtons>
        {inWaitingRoom && canManageParticipants ? (
          <SmallButton
            onClick={() => {
              admitParticipant(participant.id);
            }}
            style={{ width: "auto" }}
          >
            <FormattedMessage id="manage_participant_modal.admit" defaultMessage="Admit" />
          </SmallButton>
        ) : (
          showMute && (
            <SmallButton
              onClick={() => {
                muteParticipant(participant.id, hasAudio);
              }}
              style={{ width: "auto" }}
            >
              {hasAudio ? (
                <FormattedMessage id="manage_participant_modal.mute" defaultMessage="Mute" />
              ) : (
                <FormattedMessage id="manage_participant_modal.unmute" defaultMessage="Unmute" />
              )}
            </SmallButton>
          )
        )}
        {participant.location === "offline" && (
          <SmallButton
            onClick={() => {
              setShowDropParticipantsModal(true);
              setParticipant(participant);
            }}
            style={{ width: "auto" }}
          >
            <FormattedMessage id="manage_participant_modal.drop" defaultMessage="Drop" />
          </SmallButton>
        )}
        <>
          {(canManageParticipants || currentUser.id == participant.id) && (
            <OptionsButton
              onClick={() =>
                setShowContextMenuForParticipant(
                  showContextMenuForParticipant == participant.id ? null : participant.id,
                )
              }
            >
              ...
            </OptionsButton>
          )}

          {showContextMenuForParticipant == participant.id && (
            <OptionsMenu>
              {canManageParticipants && (
                <>
                  {participant.authorizations.indexOf("facilitator") == -1 ? (
                    <OptionsMenuItem
                      onClick={() => {
                        makeParticipantFacilitator(participant.id, true, setShowContextMenuForParticipant);
                      }}
                    >
                      <FormattedMessage
                        id="manage_participant_modal.make_facilitator"
                        defaultMessage="Make Facilitator"
                      />
                    </OptionsMenuItem>
                  ) : (
                    <OptionsMenuItem
                      onClick={() => {
                        makeParticipantFacilitator(participant.id, false, setShowContextMenuForParticipant);
                      }}
                    >
                      <FormattedMessage
                        id="manage_participant_modal.remove_facilitator"
                        defaultMessage="Remove Facilitator"
                      />
                    </OptionsMenuItem>
                  )}
                  {!inWaitingRoom && !inPerson && (
                    <OptionsMenuItem
                      onClick={() => {
                        moveToWaitingRoom(participant.id, setShowContextMenuForParticipant);
                      }}
                    >
                      <FormattedMessage
                        id="manage_participant_modal.move_to_waiting_room"
                        defaultMessage="Move to Waiting Room"
                      />
                    </OptionsMenuItem>
                  )}
                  <OptionsMenuItem
                    onClick={() => {
                      dropParticipant(participant.id, setShowContextMenuForParticipant);
                    }}
                  >
                    <FormattedMessage
                      id="manage_participant_modal.drop_participant"
                      defaultMessage="Drop Participant"
                    />
                  </OptionsMenuItem>
                </>
              )}
              <OptionsMenuItem onClick={() => useStore.setState({ showRenameParticipantModal: participant.id })}>
                <FormattedMessage id="manage_participant_modal.rename_user" defaultMessage="Edit Name" />
              </OptionsMenuItem>
            </OptionsMenu>
          )}
        </>
      </ParticipantButtons>
    </Participant>
  );
};
