export const darkTheme = {
  body: {
    background: "#222222",
  },

  content: {
    background: "#222222",
    foreground: "#FFFFFF",
  },

  controls: {
    active: "#FFFFFF",
    inactive: "#777777",
  },

  warmthTitles: ["#58B873", "#659AC8", "#B56BCE", "#D87E08"],

  button: {
    color: "#FFFFFF",

    border: {
      radius: "5px",
    },

    colors: {
      primary: "#FFA100",
      secondary: "#222",
      primaryHover: "#FF9900",
      primaryClicked: "#FF9900",
      warning: "#561709",
      choices: "#3F3F3F",
    },
  },
};

export const lightTheme = {
  body: {
    background: "#FFFFFF",
  },

  content: {
    background: "#FFFFFF",
    foreground: "#222222",
  },

  controls: {
    active: "#222222",
    inactive: "#777777",
  },

  button: {
    color: "#FFFFFF",

    border: {
      radius: "5px",
    },

    colors: {
      primary: "#FFA100",
      secondary: "#222",
      primaryHover: "#FF9900",
      primaryClicked: "#FF9900",
      warning: "#561709",
      choices: "#3F3F3F",
    },
  },
};
