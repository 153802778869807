import React, { useEffect } from "react";
import ErrorMessage from "../ErrorMessages/ErrorMessage";
import { FormattedMessage } from "react-intl";
import { store } from "../../../session/store";
import postReportPartner from "../../../lib/requests/postReportPartner";
import toast from "react-simple-toasts";

export const ReportPartnerEndScreen = () => {
  const invitation = store.use.invitation();
  const currentUser = store.use.currentUser();

  const mailTemplate = `Dear Warmspace,%0D%0A%0D%0A
I would like to report inappropriate behaviour from my partner in my session.%0D%0A%0D%0A
Session ID: ${invitation.hashedID}%0D%0A
Date: ${new Date().toLocaleDateString()}%0D%0A
Time: ${new Date().toLocaleTimeString()}%0D%0A
%0D%0A
%0D%0A
Here are more details about what happened:%0D%0A%0D%0A%0D%0A%0D%0A
Kind regards,`;

  useEffect(() => {
    postReportPartner({
      invitationHashId: invitation.hashedID,
      userID: currentUser.id,
    });
    toast("✅ Report submitted.");
  }, []);

  return (
    <ErrorMessage heading="Thank you.">
      <FormattedMessage
        defaultMessage="We're sorry you had a bad experience with your partner."
        id="app.report_partner_end_screen_message"
      />
      <br />
      <br />
      <FormattedMessage
        defaultMessage="We have been notified and will take appropriate action."
        id="app.report_partner_end_screen_message_2"
      />
      <br />
      <br />
      <FormattedMessage
        defaultMessage="We may reach out to you for more information."
        id="app.report_partner_end_screen_message_3"
      />
      <br />
      <br />
      <FormattedMessage
        defaultMessage="If you wish to provide more details yourself please send an email to"
        id="app.report_partner_end_screen_message_3"
      />
      &nbsp;
      <a
        href={`mailto:love@warmspace.io?subject=Reporting innaproriate partner in my session (${invitation.hashedID})&body=${mailTemplate}`}
      >
        <FormattedMessage id="report_partner_end_screen.email" defaultMessage={"love@warmspace.io"} />
      </a>
    </ErrorMessage>
  );
};
