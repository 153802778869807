import { HeatUpdateMessage } from "../../types/CableMessage";
import { useStore } from "../store";

export const levelUpToast = function (heatUpdateMessage: HeatUpdateMessage) {
  setTimeout(() => {
    useStore.setState({ levelUpToast: heatUpdateMessage });
  }, 0);

  setTimeout(() => {
    useStore.setState({ levelUpToast: null });
  }, 8000);
};
