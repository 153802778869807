import axios from "axios";
import toast from "react-simple-toasts";
import { store, useStore } from "../../session/store";
import { resetSessionChannel, setupSessionChannel } from "../../session/SessionChannel/setupSessionChannel";
import { Core } from "../../session/core";
import { onMessage } from "../../session/SessionChannel/onMessage";

export type ClearLoaderFunc = (loaderId: null) => any;

interface ActionMessage {
  action: string;
  data: unknown;
}

export const postAction = async (
  clientId: string,
  args: ActionMessage,
  clearLoader: ClearLoaderFunc | undefined,
  retry: number = 1,
): Promise<any> => {
  const hashedInvitationID = store.getState().invitation.hashedID;
  return axios
    .post(
      `${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}/api/v1/session_app/invitations/${hashedInvitationID}/session/action/${clientId}`,
      args,
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      },
    )
    .then((response) => {
      if (clearLoader) {
        clearLoader(null);
      }
      return response.data;
    })
    .catch(async (reason) => {
      const errorResponseMsg = reason.response?.data as string;
      if (retry < 15 && (reason.code == "ERR_NETWORK" || errorResponseMsg?.indexOf("retry") != -1)) {
        const startSocketWaitTime = new Date().getTime();

        // If the error is because the websocket is in a bad state, then reset the websocket before retrying
        // But only after a few retries because the current socket might still be connecting
        if (retry > 5 && errorResponseMsg?.indexOf("No websocket found") != -1) {
          resetSessionChannel();
          const core = Core.GetInstance();
          await setupSessionChannel(onMessage.bind(core));
        }
        const endSocketWaitTime = new Date().getTime();

        // If we had to wait for the websocket to reconnect, subtract that wait time from the retry time.
        const timeBeforeRetry = 100 * retry - (endSocketWaitTime - startSocketWaitTime);
        const timeToWait = Math.min(1000, Math.max(1, timeBeforeRetry));

        await new Promise((r) => setTimeout(r, timeToWait));
        return postAction(clientId, args, clearLoader, retry + 1);
      } else {
        if (clearLoader) {
          clearLoader(null);
        }
        const userMsgStr = "message for user: ";
        const userMsgStrIndex = errorResponseMsg.indexOf(userMsgStr);
        // Check if we should show a server message to the user
        if (userMsgStrIndex >= 0) {
          toast(errorResponseMsg.substring(userMsgStrIndex + userMsgStr.length));

          // If there was an error before the user message, then log that to the console
          if (userMsgStrIndex > 0) {
            console.log(
              `[postAction] Unexpected request error. clientId: ${clientId}, server message: ${errorResponseMsg.substring(0, userMsgStrIndex)}`,
              reason,
              args,
            );
          }
        } else {
          if (useStore.getState().sessionConsumerIsConnected) {
            toast("Something went wrong. Please try again or refresh the page.");
          }
          console.log(`[postAction] Unhandled request error. clientId: ${clientId}`, reason, args);
        }
      }
    });
};
