import React, { FC } from "react";

import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../ButtonV2/Button";
import { Close } from "../../../assets/icons/Icons";

import { Overlay, Card, CardBody, CardHeader, CardFooter } from "../Modal/ModalStyle";
import { useStore } from "../../../session/store";
import { modalClearAll } from "../Modal/Modals";
import { dropParticipant } from "../../../session/SessionChannel/dropParticipant";
interface Props {
  dropParticipantId: number;
}
export const DropParticipantModal: FC<Props> = ({ dropParticipantId }) => {
  const intl = useIntl();

  return (
    <>
      <Card>
        <CardHeader>
          <FormattedMessage id="drop_participant_modal.header" defaultMessage={"Drop user from session"} />
          <Button
            className="link"
            onClick={() => {
              useStore.setState({ showDropParticipantModal: -1 });
            }}
          >
            <Close />
          </Button>
        </CardHeader>
        <CardBody>
          <FormattedMessage
            id="drop_participant_modal.you_can_drop_a_user"
            defaultMessage={"You can drop a user that you think isn't coming back so that the flow can continue."}
          />
          <FormattedMessage
            id="drop_participant_modal.this_will_rewind"
            defaultMessage={"This will rewind the flow a few steps to the start of the current section."}
          />
        </CardBody>
        <CardFooter>
          <Button
            onClick={() => {
              useStore.setState({ showDropParticipantModal: -1 });
            }}
            className="link"
            style={{ color: "black" }}
          >
            <FormattedMessage id="drop_participant_modal.cancel" defaultMessage={"Cancel"} />
          </Button>
          <Button
            title={intl.formatMessage({
              id: "drop_participant_modal.drop_user",
              defaultMessage: "Drop User",
            })}
            onClick={async () => {
              await dropParticipant(dropParticipantId);
              useStore.setState({ showDropParticipantModal: -1 });
              modalClearAll();
            }}
            style={{ width: "auto", fontSize: "16px" }}
          >
            <FormattedMessage id="drop_participant_modal.drop_user" defaultMessage={"Drop User"} />
          </Button>
        </CardFooter>
      </Card>
      <Overlay
        onClick={() => {
          useStore.setState({ showDropParticipantModal: -1 });
        }}
        $blur={false}
      />
    </>
  );
};

export default DropParticipantModal;
