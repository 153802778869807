import { DailyEventObjectActiveSpeakerChange } from "@daily-co/daily-js";
import { Core } from "../core";
import { useStore } from "../store";
import { PARTICIPANTS_PER_PAGE } from "../../lib/defaults";

export const onActiveSpeakerChange = function (this: Core, event?: DailyEventObjectActiveSpeakerChange) {
  console.debug("[session-ui]: onActiveSpeakerChange:", event?.activeSpeaker?.peerId);

  if (event?.activeSpeaker?.peerId) {
    const participants = Object.values(useStore.getState().participants) as Participant[];

    const activeSpeakerDailySessionID = event?.activeSpeaker?.peerId;
    const activeSpeakerWarmspaceID = participants.find((p) => p.dailySessionID === activeSpeakerDailySessionID)?.id;

    if (!activeSpeakerWarmspaceID) {
      console.log("[session-ui]: Active speaker not found in participants list");
      return;
    }

    const newParticipantsLastActiveDates = Object.assign({}, useStore.getState().participantsLastActiveDates);
    const newParticipantsLastActiveDatesSpeaker = Object.assign(
      {},
      useStore.getState().participantsLastActiveDatesSpeaker,
    );

    // Initialize the newParticipantsLastActiveDates so that every participant has a date
    const now = new Date();
    const ONE_DAY_IN_MS = 24 * 60 * 60 * 1000;
    for (let i = 0; i < participants.length; i++) {
      const participant = participants[i];
      if (!newParticipantsLastActiveDates[participant.id]) {
        const newParticipantDate = new Date(now.getTime() - (ONE_DAY_IN_MS + i));
        newParticipantsLastActiveDates[participant.id] = newParticipantDate;
        newParticipantsLastActiveDatesSpeaker[participant.id] = newParticipantDate;
      }
    }

    newParticipantsLastActiveDatesSpeaker[activeSpeakerWarmspaceID] = now;

    // This code adjusts when we move the active speaker to the first slot.
    // Basically when we are showing the mobile style view, we always move the active speaker to the first slot.
    // When we are showing the desktop style view, we only move people if they or not on the page.
    // Currently though the multi-page desktop view is not active. We show the mobile style view on desktop too
    // when going past 12 participants.
    const sortedParticipants = participants.sort((a, b) => {
      const aLastActiveDate = newParticipantsLastActiveDates[a.id] || 0;
      const bLastActiveDate = newParticipantsLastActiveDates[b.id] || 0;

      if (aLastActiveDate > bLastActiveDate) return -1;
      if (bLastActiveDate < aLastActiveDate) return 1;
      return 0;
    });

    const activeSpeakerIndex = sortedParticipants.findIndex((p) => p.id === activeSpeakerWarmspaceID);

    if (activeSpeakerIndex > PARTICIPANTS_PER_PAGE - 1) {
      newParticipantsLastActiveDates[activeSpeakerWarmspaceID] = now;

      // While we're moving video boxes around, move anyone who doesn't have their camera on
      // and isn't the active speaker off the first page to prioritize those with their camera on
      const offCameraFirstPageParticipants: Participant[] = [];
      const onCameraLaterPageParticipants: Participant[] = [];
      const dailyParticipants = Core.GetInstance().callObject?.participants();
      sortedParticipants.forEach((p, idx) => {
        const dailyParticipant = dailyParticipants?.[p.dailySessionID];
        const cameraOn = dailyParticipant && dailyParticipant?.tracks?.video?.state !== "playable";
        if (idx > 0 && idx < PARTICIPANTS_PER_PAGE && cameraOn) {
          offCameraFirstPageParticipants.push(p);
        }
        if (
          idx >= PARTICIPANTS_PER_PAGE &&
          cameraOn &&
          onCameraLaterPageParticipants.length < offCameraFirstPageParticipants.length
        ) {
          onCameraLaterPageParticipants.push(p);
        }
      });
      // Swap the last active dates between those with cameras on and off to move the on-participants to the first page
      for (let i = 0; i < onCameraLaterPageParticipants.length; i++) {
        const onCameraParticipant = onCameraLaterPageParticipants[i];
        const offCameraParticipant = offCameraFirstPageParticipants[i];

        const onCameraActiveDate = newParticipantsLastActiveDates[onCameraParticipant.id];
        const offCameraActiveDate = newParticipantsLastActiveDates[offCameraParticipant.id];

        newParticipantsLastActiveDates[onCameraParticipant.id] = offCameraActiveDate;
        newParticipantsLastActiveDates[offCameraParticipant.id] = onCameraActiveDate;
      }
    }

    useStore.setState({
      participantsLastActiveDates: newParticipantsLastActiveDates,
      participantsLastActiveDatesSpeaker: newParticipantsLastActiveDatesSpeaker,
    });
  }
};
