import { store } from "../session/store";
import { isFeedbackScoreStep, isFeedbackWordsStep, isIframeStep } from "../types/type_guards";

export const useGoToFeedback = () => {
  const currentStep = store.use.currentStep();
  const lastStep = store.use.lastStep();
  const invitation = store.use.invitation();
  const core = store.use.core();

  const isSomeonePresent = core.isSomeonePresent;
  const isLobby = invitation.lobby;

  // Requests to leave if the session is over and no one else is present.
  if (
    !isLobby &&
    lastStep &&
    !isSomeonePresent &&
    currentStep &&
    !isIframeStep(currentStep) &&
    !isFeedbackWordsStep(currentStep) &&
    !isFeedbackScoreStep(currentStep)
  ) {
    console.debug("Requesting to end session (todo vg: this has been disabled)", {
      isLobby,
      isSomeonePresent,
      currentStep,
      lastStep,
    });
    // doAction(core, "end_session", -1, core.currentUser.id);
  }
};
