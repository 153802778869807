import React, { useMemo } from "react";
import { store } from "../../../session/store";
import { getTitle } from "../Participants/getTitle";
import { getLevel } from "../Participants/getLevel";
import { styled } from "styled-components";

const HeatLevelTitleImage = styled.img`
  width: 24px;
  height: 24px;
  margin-right: -5px;
`;

export const HeatLevelImage = ({ participant }: { participant: Participant }) => {
  const invitation = store.use.invitation();
  const settings = store.use.settings();

  const cumulativeHeat = participant.cumulativeHeat[invitation.heatType];
  const heatTypeToLevels = settings.heatTypeToLevels[invitation.heatType];
  const heatTypeToTitles = settings.heatTypeToTitles[invitation.heatType];

  const { level } = useMemo(() => getLevel(cumulativeHeat, heatTypeToLevels), [cumulativeHeat, heatTypeToLevels]);
  const { heatLevelTitle } = useMemo(() => getTitle(level, heatTypeToTitles), [level, heatTypeToTitles]);

  return (
    <HeatLevelTitleImage
      src={`${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}${heatLevelTitle.imageUrl}?w=256`}
      alt={heatLevelTitle.title}
    />
  );
};
