import React, { FC, useEffect } from "react";
import { store } from "../../session/store";
import { AudioProgress } from "../UI/AudioProgress";
import styled from "styled-components";
import { isChoicesStep, isContentStep, isFeedbackScoreStep, isFeedbackWordsStep } from "../../types/type_guards";
import ChoiceStep from "../UI/Steps/ChoiceStep";
import { VideoGrid } from "../UI/VideoGrid/VideoGrid";
import Actions from "../UI/Actions/Actions";
import FeedbackScoreStep from "../UI/Steps/FeedbackScoreStep";
import FeedbackWordsStep from "../UI/Steps/FeedbackWordsStep";
import { Branding } from "../UI/Branding/Branding";
import { useScreenShare } from "@daily-co/daily-react";
import { InfinityToast } from "../UI/InfinityToast/InfinityToast";
import { LightOverlay } from "../UI/Modal/ModalStyle";
import { useShowMatchingStep } from "../UI/Steps/MatchingStep";
import { selectScreens } from "../../session/selectors/selectScreens";

interface VideoSessionProps {
  isWaitingForPartner: boolean;
}

const VideoSessionWrapper = styled.div`
  width: 100%;
  height: 100%;

  &.medium,
  &.large {
    &.modalVisible {
      padding-left: 405px;
    }

    &.chatVisible {
      padding-right: 390px;
    }
  }

  box-sizing: border-box;
`;

export const VideoSession: FC<VideoSessionProps> = ({ isWaitingForPartner }) => {
  const participants = store.use.participants();
  const stepContent = store.use.stepContent();
  const roleData = store.use.roleData();
  const stepData = store.use.stepData();
  const deviceClassification = store.use.deviceClassification();
  const currentStep = store.use.currentStep();
  const sessionConsumerIsConnected = store.use.sessionConsumerIsConnected();
  const locale = store.use.locale();
  const showExternalContent = store.use.showExternalContent();

  const showVideo = isContentStep(currentStep) && currentStep.audioFile[locale]?.fileName.includes(".mp4");
  const showFlowsModal = store.use.showModals().includes("FlowsModal");
  const showChat = store.use.showChat();
  const recording = store.use.recording();
  const transcribing = store.use.transcribing();
  const iframeVisible = store.use.iframeVisible();
  const invitation = store.use.invitation();
  const currentUser = store.use.currentUser();
  const showInfinityToast = store.use.showInfinityToast();

  const showMatchingStep = useShowMatchingStep(currentStep);

  const [currentText, setCurrentText] = React.useState<string | undefined>("");
  const [showUI, setShowUI] = React.useState<boolean>(true);

  // This is about animating the text when new text comes in.
  useEffect(() => {
    if (!showMatchingStep && isContentStep(currentStep) && currentStep.audioFile[locale]?.url) {
      setCurrentText(stepContent?.text);
      return;
    }
    setShowUI(false);

    const timeout = setTimeout(() => {
      setShowUI(true);
      setCurrentText(stepContent?.text);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [stepContent?.text]);

  const { screens: allScreens } = useScreenShare();
  const screens = selectScreens({ participants, allScreens });

  const showScreenOrContent = screens.length > 0 || showExternalContent || showVideo;
  const classes = [];
  classes.push(deviceClassification.size);
  if (showScreenOrContent) classes.push("screensharing");
  if (showFlowsModal) classes.push("modalVisible");
  if (showChat) classes.push("chatVisible");

  return (
    <VideoSessionWrapper className={classes.join(" ")}>
      <VideoGrid
        recordOption={recording ? "recording" : transcribing ? "transcribing" : null}
        participants={participants}
        showUI={showUI && sessionConsumerIsConnected}
        contentAreaContent={
          <>
            {currentStep?.type == "ContentStep" &&
              currentStep.audioFile &&
              (currentStep.audioFile[locale] || currentStep.audioFile["en"]) && (
                <AudioProgress role={roleData?.[currentUser.id]?.role} step={currentStep} userID={currentUser.id} />
              )}
            {currentText &&
              !isFeedbackScoreStep(currentStep) &&
              !isFeedbackWordsStep(currentStep) &&
              !iframeVisible &&
              !invitation.lobby && <p>{currentText}</p>}
          </>
        }
        buttonAreaContent={
          stepContent?.actions && stepContent.actions.length > 0 && <Actions wrapperClasses={"nohide"} />
        }
      />

      {invitation.lobby &&
        Object.values(participants).filter((p) => !invitation.waitingRoomEnabled || p.location == "session").length ===
          1 &&
        !invitation.solo &&
        !invitation.muteAll && (
          <Branding
            fill={"#888"}
            style={{
              width: "250px",
              height: "46px",
              marginLeft: "-125px",
              marginTop: "-23px",
              top: "50%",
              left: "50%",
              position: "absolute",
              zIndex: 0,
            }}
          />
        )}

      {isChoicesStep(currentStep) && sessionConsumerIsConnected && (
        <ChoiceStep
          stepData={stepData}
          roleData={roleData}
          yourID={currentUser.id}
          step={currentStep}
          choices={stepContent?.choices}
        />
      )}
      {sessionConsumerIsConnected && !isWaitingForPartner && isFeedbackScoreStep(currentStep) && (
        <FeedbackScoreStep
          stepData={stepData}
          roleData={roleData}
          yourID={currentUser.id}
          step={currentStep}
          stepContent={stepContent!}
          text={stepContent?.text}
        />
      )}
      {sessionConsumerIsConnected && !isWaitingForPartner && isFeedbackWordsStep(currentStep) && (
        <FeedbackWordsStep
          stepData={stepData}
          yourID={currentUser.id}
          step={currentStep}
          stepContent={stepContent!}
          text={stepContent?.text}
        />
      )}

      {showInfinityToast && (
        <>
          <InfinityToast />
          <LightOverlay $blur={true} />
        </>
      )}
    </VideoSessionWrapper>
  );
};
