import React, { memo, useCallback, useState } from "react";
import { useDailyEvent, useMediaTrack } from "@daily-co/daily-react";
import AudioLevelInner from "./AudioLevelInner";
import { ElementPosition } from "./AudioLevelInner";
import { store } from "../../../session/store";

export default memo(function AudioLevel({
  id,
  position,
  local,
}: {
  id: string;
  position: ElementPosition;
  local: boolean;
}) {
  const audioTrack = useMediaTrack(id, "audio");
  const hasAudio = audioTrack.state === "playable";
  const [volume, setVolume] = useState(0);

  const narrating = store.use.narrating();

  useDailyEvent(
    "remote-participants-audio-level",
    useCallback(
      (ev) => {
        if (id in ev.participantsAudioLevel && !local) {
          setVolume(ev.participantsAudioLevel[id]);
          if (ev.participantsAudioLevel[id] > 0.04) {
            setVolume(ev.participantsAudioLevel[id]);
          } else {
            setVolume(0);
          }
        }
      },
      [id, local, setVolume],
    ),
  );

  return audioTrack.persistentTrack ? (
    <AudioLevelInner position={position} volume={volume > 0.0002 ? volume : 0} hasAudio={hasAudio && !narrating} />
  ) : null;
});
