import React, { FC } from "react";

import { DailyProvider } from "@daily-co/daily-react";
import { DailyCall } from "@daily-co/daily-js";
import { ThemeProvider } from "styled-components";
import { IntlShape, RawIntlProvider } from "react-intl";
import { darkTheme } from "../../lib/theme";

export const Providers: FC<{
  children: React.ReactElement | null;
  callObject?: DailyCall;
  intl: IntlShape;
}> = ({ children, callObject, intl }) => {
  return (
    <RawIntlProvider value={intl}>
      <style>
        {`
          ${Object.entries(darkTheme.warmthTitles).map(([key, value]) => {
            return `
              .warmth-title-${key} {
                color: ${value} !important;
              }
            `;
          })}
        `}
      </style>

      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DailyProvider callObject={callObject}>
          <ThemeProvider theme={darkTheme}>{children}</ThemeProvider>
        </DailyProvider>
      </div>
    </RawIntlProvider>
  );
};
