import React from "react";
import { BreakoutRoomIcon } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { FlowsModal } from "../FlowsModal/FlowsModal";
import { modalToggle } from "../Modal/Modals";
import { store, useStore } from "../../../session/store";
import { NotificationDot } from "./ChatButton";

export const FlowsButton = () => {
  const showFlowsModal = store.use.showModals().includes("FlowsModal");
  const flowsOpen = store.use.showModals().includes("FlowsModal");
  const showNotificationDot = store.use.newlyUnlockedFlowHashIds();

  return (
    <>
      <MenuButton
        buttonIcon={
          <span style={{ position: "relative" }}>
            <BreakoutRoomIcon stroke={showFlowsModal ? "#FFA100" : "white"} />
            {!flowsOpen && showNotificationDot && (
              <NotificationDot
                style={{ display: "none", position: "absolute", left: "-4px", width: "10px", top: "-13px" }}
              />
            )}
          </span>
        }
        onClick={() => {
          modalToggle("FlowsModal");
          useStore.setState({ showExternalContent: false, showChat: false });
        }}
        className={showFlowsModal ? "selected" : ""}
      >
        <FormattedMessage id="breakout_button" defaultMessage="Flows" />
      </MenuButton>
      <FlowsModal />
    </>
  );
};
