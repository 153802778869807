import React, { FC, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { LoadingButton } from "../UI/ButtonV2/Button";
import styled from "styled-components";
import logo from "../../assets/warmspace_logo_color.svg";
import { store } from "../../session/store";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  background-color: #ffeecd;
  color: #000;
  max-width: calc(100% - 100px);
  gap: 30px;
  margin: auto;
  border-radius: 10px;
  border: 1px solid #f0f0f0;
`;

export const PrejoinInPerson: FC = () => {
  const invitation = store.use.invitation();
  const core = store.use.core();

  const [joining, setJoining] = useState(false);

  useEffect(() => {
    core.subscription?.perform("update_location", { location: "ready-room" });
  }, []);

  const join = async () => {
    setJoining(true);

    core.subscription?.perform("switch_in_person_mode", {
      in_person: true,
    });

    await core.joinCall();
    setJoining(false);
  };

  return (
    <Wrapper>
      <img src={logo} width="225px" />

      {invitation.title}

      <LoadingButton id="joinButton" disabled={joining} loading={joining} onClick={join}>
        <FormattedMessage id="prejoin.join_digital_button" defaultMessage="Join" />
      </LoadingButton>
    </Wrapper>
  );
};
