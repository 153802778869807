export const groupBy = <T, K extends keyof any>(array: T[], groupingKeyFunc: (i: T) => K) =>
  array.reduce(
    (groups, item) => {
      (groups[groupingKeyFunc(item)] ||= []).push(item);
      return groups;
    },
    {} as Record<K, T[]>,
  );

export const deepIsEquals = (obj1: any, obj2: any) => {
  // Check if both are the same reference
  if (obj1 === obj2) {
    return true;
  }

  // Check if either is null or not an object
  if (obj1 == null || typeof obj1 !== "object" || obj2 == null || typeof obj2 !== "object") {
    return false;
  }

  // Get keys of both objects
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  // Check if both have the same number of keys
  if (keys1.length !== keys2.length) {
    return false;
  }

  // Recursively compare each key and value
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepIsEquals(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
};
