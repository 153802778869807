import React, { useState } from "react";

import { FormattedMessage } from "react-intl";
import digital from "../../../assets/icons/digital.svg";
import physical from "../../../assets/icons/physical.svg";
import MenuButton from "./MenuButton";
import SwitchDigitalPhysicalModal from "./SwitchDigitalPhysicalModal";

const SwitchDigitalPhysicalButton = ({ inPerson }: { inPerson: boolean }) => {
  const [showSwitchDigitalPhysicalModal, setSwitchDigitalPhysicalModal] = useState(false);

  return (
    <>
      <MenuButton
        buttonIcon={<img height="18px" src={inPerson ? digital : physical} />}
        onClick={() => setSwitchDigitalPhysicalModal(true)}
      >
        {inPerson ? (
          <FormattedMessage id="switch_digital_physical_button.video" defaultMessage={"Video"} />
        ) : (
          <FormattedMessage id="switch_digital_physical_button.in_person" defaultMessage={"In person"} />
        )}
      </MenuButton>
      {showSwitchDigitalPhysicalModal && (
        <SwitchDigitalPhysicalModal
          visible={!!showSwitchDigitalPhysicalModal}
          setShow={setSwitchDigitalPhysicalModal}
          inPerson={inPerson}
        />
      )}
    </>
  );
};

export default SwitchDigitalPhysicalButton;
