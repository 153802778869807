// Branding - A simple component that renders a bit of our branding.

import * as React from "react";
import { CSSProperties } from "styled-components";

export const Branding = ({ style, fill = "white" }: { style: CSSProperties; fill: string }) => (
  <div style={style}>
    <svg width="100%" viewBox="0 0 1428 332" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M286.226 64.5208C294.995 62.0152 302.51 67.0265 303.763 75.7963C310.026 119.645 300.005 167.879 272.447 210.476C245.516 253.072 205.431 281.887 163.468 293.789C154.7 296.294 147.184 291.283 145.931 282.513C139.668 238.664 149.689 190.43 177.247 147.834C204.805 105.238 244.263 76.4227 286.226 64.5208ZM166.6 144.076C182.884 120.898 201.673 100.226 223.595 84.5661C219.21 67.6529 213.573 51.9925 206.058 37.585C201.673 29.4416 192.905 27.5623 185.389 33.2001C150.316 58.8831 123.384 100.226 112.11 149.713C100.837 200.453 108.979 258.083 135.91 293.789C135.91 294.415 136.537 293.789 136.537 293.162C133.405 280.634 119.626 211.102 166.6 144.076ZM100.837 147.834C105.221 127.789 112.11 108.996 121.505 91.4567C121.505 91.4567 121.505 91.4567 121.505 90.8303C107.1 67.0265 88.3103 47.6076 67.0156 33.2001C59.4998 28.8152 50.7314 31.3208 46.3472 39.4642C27.5577 78.9284 25.6788 127.162 34.4472 176.649C48.2261 251.819 110.858 289.404 120.252 294.415C120.879 294.415 120.879 294.415 120.879 293.789C96.4524 251.193 89.563 199.2 100.837 147.834Z"
        fill={"url(#paint0_radial_2631_191)"}
      />
      <path
        className="letter"
        d="M419.631 258.71L385.81 152.219C380.174 135.306 375.163 123.404 368.9 117.14V116.513H428.4V117.14C420.258 125.91 419.631 139.064 425.268 155.977L446.563 222.377L469.737 157.857L467.858 152.219C464.726 139.691 458.463 127.789 450.947 117.14V116.513H510.447V117.14C502.305 125.91 501.053 139.064 506.689 155.977L527.984 220.498L547.4 159.11C554.289 139.064 551.784 124.657 539.258 115.887V115.26H574.331V115.887C564.937 126.536 558.674 139.064 554.916 152.219L521.095 257.457H502.931L472.242 164.121L438.421 257.457L419.631 258.71Z"
        fill={fill}
      />
      <path
        className="letter"
        d="M600.01 243.676C602.516 243.676 605.647 243.049 608.153 241.796V210.476C602.516 211.728 596.879 214.234 592.495 218.619C589.363 221.751 586.858 226.136 586.858 230.521C586.858 233.653 588.11 236.785 589.989 239.291C592.495 242.423 596.253 244.302 600.01 243.676ZM630.074 259.962C625.063 259.962 620.053 258.71 616.295 255.577C612.537 253.072 610.032 249.313 609.405 244.928C603.768 254.325 593.121 260.589 581.847 259.962C576.21 259.962 570.574 258.083 566.189 254.951C561.805 251.193 559.926 246.181 559.926 240.544C559.926 224.257 576.21 212.981 608.779 206.091V194.189C609.405 189.804 607.526 184.793 604.395 181.66C600.01 178.528 595 176.649 589.989 177.276C581.221 177.276 572.453 179.155 564.31 182.913L563.684 181.66C574.332 167.253 591.242 159.11 609.405 159.736C617.547 159.11 625.689 161.615 631.953 166.627C636.963 171.638 639.468 178.528 639.468 185.419V234.906C638.842 238.664 641.347 242.423 645.732 243.676C646.358 243.676 647.61 243.676 648.237 243.676C650.742 243.676 653.247 243.049 655.753 241.17L656.379 241.796C652.621 253.072 641.974 260.589 630.074 259.962Z"
        fill={fill}
      />
      <path
        className="letter"
        d="M663.268 258.083C666.4 246.808 668.279 235.532 668.279 224.257V202.332C668.279 196.695 667.653 190.43 665.774 184.793C664.521 179.781 662.016 175.396 658.258 171.638V171.012L698.342 159.736V183.54C698.968 182.913 701.474 180.408 704.605 176.649C707.11 174.144 709.616 171.012 712.747 168.506C715.253 166 718.384 164.747 721.516 162.868C725.274 160.989 729.032 160.363 732.789 160.363L729.032 192.936H727.779C725.274 189.804 722.142 186.672 717.758 184.793C714.626 182.913 710.868 181.661 707.737 181.034C703.979 181.661 700.847 183.54 698.342 186.672V223.004C698.342 234.279 699.595 246.181 703.353 256.83V257.457H663.268V258.083Z"
        fill={fill}
      />
      <path
        className="letter"
        d="M784.147 258.083H743.437V257.457C746.568 246.808 748.447 234.906 748.447 223.63V202.332C748.447 196.695 747.821 190.43 745.942 184.793C744.689 179.781 742.184 175.396 738.426 171.638V171.012L778.51 159.736V179.155H779.137C782.268 174.144 786.653 169.759 791.037 166.627C796.674 162.868 802.937 160.989 809.826 160.989C816.089 160.989 822.353 162.242 827.363 166C832.374 169.132 835.505 173.517 836.758 179.155H837.384C843.647 167.879 855.547 160.363 868.7 160.363C876.216 159.736 883.105 162.868 888.742 167.879C893.753 172.891 896.258 179.781 896.258 187.298V224.257C896.258 235.532 897.51 247.434 901.268 258.083V258.71H860.558V258.083C863.689 247.434 865.568 236.159 865.568 224.883V188.551C865.568 179.781 861.81 176.023 854.295 176.023C847.405 176.023 841.142 180.408 838.01 186.046V224.883C838.01 236.159 839.263 248.061 843.021 258.71V259.336H801.684V258.71C804.816 248.061 806.695 236.785 806.695 225.51V189.178C806.695 180.408 802.937 176.649 795.421 176.649C788.532 176.649 782.268 181.034 779.137 186.672V225.51C778.51 236.159 780.389 247.434 784.147 258.083Z"
        fill={fill}
      />
      <path
        className="letter"
        d="M979.558 187.925L977.679 188.551C973.294 181.66 967.658 175.396 961.394 169.759C957.637 166 952.626 164.121 947.615 164.121C943.858 164.121 940.1 165.374 937.594 167.879C935.089 170.385 933.21 173.517 933.21 176.649C933.21 179.781 934.463 182.913 936.968 185.419C938.847 187.298 941.352 189.177 944.484 191.057C948.242 192.936 952.626 194.815 956.384 196.068C957.637 196.694 958.263 196.694 958.889 197.321C962.647 199.2 965.779 200.453 968.91 202.959C972.042 204.838 974.547 207.343 977.679 209.849C980.81 212.355 983.315 215.487 984.568 219.245C986.447 223.004 987.073 227.389 987.073 231.774C987.7 241.17 983.315 249.94 975.8 254.951C967.031 259.962 957.01 262.468 946.363 262.468C935.716 262.468 924.442 260.589 915.047 256.204L912.542 229.268L914.421 228.642C919.431 236.785 925.694 244.928 933.21 251.193C938.221 255.577 944.484 258.083 951.373 258.71C955.131 258.71 958.889 257.457 961.394 254.951C965.152 250.566 966.405 244.302 963.273 239.291C962.021 237.411 960.142 235.532 957.636 233.653C955.131 231.774 952.626 230.521 950.747 229.268C947.615 227.389 945.11 226.136 941.352 224.883C937.594 223.63 934.463 221.751 931.331 219.245C928.2 217.366 925.694 215.487 922.563 212.981C919.431 210.476 916.926 206.717 915.047 203.585C913.168 199.826 912.542 195.442 912.542 191.057C911.915 182.287 916.3 173.517 923.189 168.506C931.958 163.494 941.979 160.989 952 160.989C960.142 160.989 968.284 161.615 976.426 164.121L979.558 187.925Z"
        fill={fill}
      />
      <path
        className="letter"
        d="M1050.33 257.457C1056.59 257.457 1060.98 253.698 1064.74 246.181C1068.49 236.159 1070.37 225.51 1069.75 214.861C1070.37 204.838 1068.49 194.815 1064.11 185.419C1060.35 179.155 1054.09 176.023 1047.2 176.649C1042.19 176.649 1037.18 177.902 1033.42 181.034V227.389C1032.79 235.532 1034.67 243.049 1038.43 250.566C1040.94 254.325 1045.32 256.83 1050.33 257.457ZM997.721 301.306C1000.85 290.03 1002.73 278.755 1002.73 267.479V202.332C1002.73 196.695 1002.11 190.43 1000.23 184.793C998.974 179.781 996.468 175.396 992.71 171.638V171.012L1032.79 159.736V175.396H1034.05C1040.94 165.374 1052.84 159.736 1064.74 160.363C1075.38 159.736 1086.03 164.747 1092.29 173.517C1099.81 183.54 1103.57 196.068 1102.32 208.596C1102.94 222.378 1098.56 235.532 1089.79 246.181C1081.02 255.578 1068.49 260.589 1051.58 260.589C1045.95 260.589 1039.68 259.963 1034.05 258.083V266.853C1033.42 278.129 1035.3 290.03 1038.43 300.679V301.306H997.721Z"
        fill={fill}
      />
      <path
        className="letter"
        d="M1151.17 243.676C1153.67 243.676 1156.81 243.049 1159.31 241.796V210.476C1153.67 211.728 1148.04 214.234 1143.65 218.619C1140.52 221.751 1138.02 226.136 1138.02 230.521C1138.02 233.653 1139.27 236.785 1141.15 239.291C1143.65 242.423 1147.41 244.302 1151.17 243.676ZM1181.23 259.962C1176.22 259.962 1171.21 258.71 1167.45 255.577C1163.69 253.072 1161.19 249.313 1160.56 244.928C1154.93 254.325 1144.28 260.589 1133.01 259.962C1127.37 259.962 1121.73 258.083 1117.35 254.951C1112.96 251.193 1111.08 246.181 1111.08 240.544C1111.08 224.257 1127.37 212.981 1159.94 206.091V194.189C1160.56 189.804 1158.68 184.793 1155.55 181.66C1151.17 178.528 1146.16 176.649 1141.15 177.276C1132.38 177.276 1123.61 179.155 1115.47 182.913L1114.84 181.66C1125.49 167.253 1142.4 159.11 1160.56 159.736C1168.71 159.11 1176.85 161.615 1183.11 166.627C1188.12 171.638 1190.63 178.528 1190.63 185.419V234.906C1190 238.664 1192.51 242.423 1196.89 243.676C1197.52 243.676 1198.77 243.676 1199.39 243.676C1201.9 243.676 1204.41 243.049 1206.91 241.17L1207.54 241.796C1203.78 253.072 1193.13 260.589 1181.23 259.962Z"
        fill={fill}
      />
      <path
        className="letter"
        d="M1298.98 231.147C1297.1 239.917 1291.46 247.434 1284.57 253.072C1277.06 258.71 1267.66 261.215 1258.27 261.215C1245.74 261.842 1233.84 257.457 1225.07 248.687C1216.31 239.291 1211.92 226.762 1212.55 213.608C1211.92 199.2 1217.56 186.045 1227.58 176.023C1238.23 166 1252 160.989 1266.41 161.615C1275.8 161.615 1284.57 163.495 1293.34 166.627L1296.47 194.189L1294.59 194.815C1282.69 175.396 1272.05 166 1264.53 166C1258.27 166.627 1252.63 169.759 1250.13 175.396C1246.37 183.54 1244.49 192.31 1244.49 201.706C1243.86 212.355 1246.37 222.378 1253.26 230.521C1259.52 236.785 1267.66 240.544 1276.43 239.917C1284.57 240.544 1292.72 237.412 1298.98 231.774V231.147Z"
        fill={fill}
      />
      <path
        className="letter"
        d="M1355.97 164.121C1350.96 164.121 1347.21 167.253 1344.7 173.517C1342.19 181.034 1340.94 189.177 1340.94 197.321L1368.5 196.694V189.804C1367.87 172.264 1364.74 164.121 1355.97 164.121ZM1398.56 229.894C1396.06 238.664 1391.05 246.808 1383.53 252.445C1375.39 258.083 1365.99 260.589 1355.97 260.589C1342.82 261.215 1330.29 256.204 1321.53 246.808C1312.76 237.411 1308.37 224.883 1309 212.355C1308.37 198.574 1313.38 185.419 1322.78 175.396C1331.55 166 1344.07 160.362 1356.6 160.989C1379.15 160.362 1397.31 177.902 1397.94 200.453C1397.94 201.079 1397.94 201.706 1397.94 202.332H1340.32C1340.94 227.389 1351.59 239.917 1371.63 239.917C1381.03 240.543 1389.79 237.411 1396.68 231.147H1398.56V229.894Z"
        fill={fill}
      />
      <defs>
        <radialGradient
          id="paint0_radial_2631_191"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(137.274 285.517) rotate(-50.9017) scale(530.185 660.085)"
        >
          <stop stopColor="#FF602E" />
          <stop offset="0.3385" stopColor="#FF8D24" />
          <stop offset="1" stopColor="#FF8D24" />
        </radialGradient>
      </defs>
    </svg>
  </div>
);
