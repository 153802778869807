import styled from "styled-components";

import React, { FC } from "react";
import { Button } from "../ButtonV2/Button";
import {
  DEFAULT_CHOICE_LIST_WIDTH,
  DEFAULT_CHOICE_COLUMNS,
  DEFAULT_CHOICE_BUTTON_HEIGHT,
  DEFAULT_CHOICE_ACTION,
} from "../../../lib/defaults";

import { store } from "../../../session/store";
import { doAction } from "../../../session/SessionChannel/doAction";

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: none;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  justify-content: center;
  align-items: center;

  &.inPerson {
    height: calc(100% - 60px);
    p {
      color: #2a2a2a;
    }
  }

  p {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.35;
    color: white;
    max-width: 800px;
    margin: 0 auto;
    white-space: pre-line;
    font-family: "ITC Clearface", sans-serif;
    z-index: 10;
    max-width: 90%;
  }
`;
const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: #000;
  opacity: 1;
  display: flex;
  text-align: center;
  justify-content: center;
  z-index: 7;
  opacity: 0.3;
`;
const QuestionCardsWrapper = styled.div`
  z-index: 8;
  width: 90%;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  #question-cards {
    display: grid;
    grid-template-columns: 200px 200px;
    grid-row: auto auto;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    align-items: center;
    justify-content: center;
  }
  button {
    width: 100%;
    display: flex;

    justify-content: center;
    font-size: 18px;
    font-weight: 500;

    border: 0px;
    padding: 10px 18px;
    border-radius: 8px;
    margin: auto;
    text-align: center;
    font-size: 16px;

    @media (max-width: 320px) {
      padding: 4px !important;
      font-size: 14px;
    }

    &:hover {
      background-color: #f19903;
    }

    &.disabled {
      background-color: #191919cc;
      &:hover {
        background-color: #191919cc;
      }

      cursor: default;
    }
  }
`;

interface ChoicesListProps {
  width: number;
  columns: number;
}

export const ChoicesList = styled.div<ChoicesListProps>`
  margin: auto;
  width: ${({ width }: { width: number }) => width}%;
  display: grid;
  grid-template-columns: ${({ columns }: { columns: number }) =>
    Array(columns + 1)
      .fill("")
      .join("1fr ")};

  grid-gap: 10px;
`;

interface Props {
  stepData: StepIndexData;
  roleData: RoleData;
  yourID: number;
  step: ChoicesStep;
  text?: string;
  choices?: {
    text: string;
    onClick: ActionConsequence;
  }[];
}

const ChoiceStep: FC<Props> = ({ stepData, roleData, yourID, step, text, choices }) => {
  const inPerson = store.use.inPerson();
  const core = store.use.core();

  const choiceAvailable = (step: ChoicesStep, choice: string, userID: number) => {
    if (!stepData) return true;
    if (Object.values(stepData).find((c) => c.done === choice) && step.uniquePicks) return false;
    if (stepData[userID]?.done === choice) return false;
    return true;
  };

  let role = roleData[yourID].role;

  if (step?.shareContent) {
    role = "EVERYONE_ELSE";
  }

  const centerElements: React.ReactNode[] = [];

  const content = step.content[role];

  if (choices && choices.length > 0) {
    centerElements.push(
      <QuestionCardsWrapper id="question-cards-wrapper" key="choiceslist">
        <ChoicesList
          width={content.choiceListWidth || DEFAULT_CHOICE_LIST_WIDTH}
          columns={content.columns || DEFAULT_CHOICE_COLUMNS}
        >
          {choices.map((choice) => {
            return (
              <Button
                key={choice.text}
                className={"choices " + (!choiceAvailable(step, choice.text, yourID) ? "disabled" : "")}
                disabled={!choiceAvailable(step, choice.text, yourID)}
                style={{
                  height: content.buttonHeight || DEFAULT_CHOICE_BUTTON_HEIGHT,
                }}
                onClick={() => doAction(core, choice.onClick || DEFAULT_CHOICE_ACTION, step.id, yourID, choice.text)}
              >
                {choice.text}
              </Button>
            );
          })}
        </ChoicesList>
      </QuestionCardsWrapper>,
    );
  }

  return (
    <Wrapper className={inPerson ? "inPerson" : ""}>
      {choices && choices.length > 0 && !inPerson && <Overlay />}
      {text && <p>{text}</p>}
      {centerElements.length > 0 && centerElements}
    </Wrapper>
  );
};

export default ChoiceStep;
