import React, { FC } from "react";

import { Branding } from "../Branding/Branding";

interface ErrorMessageProps {
  heading: string;
  children: React.ReactNode;
  lowerArea?: React.ReactNode;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ heading, children, lowerArea }) => {
  return (
    <div
      style={{
        height: "100%",
        maxWidth: "100%",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: "20px",
        marginRight: "20px",
        animation: "slidein 1.5s",
        position: "relative",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div id="error-message">
          <b>{heading}</b>

          <br />
          <p color="#fff">{children}</p>
        </div>
        <Branding fill={"white"} style={{ marginTop: "20px", width: "225px" }} />
      </div>
      {lowerArea && (
        <div
          style={{
            position: "absolute",
            bottom: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {lowerArea}
        </div>
      )}
    </div>
  );
};

export default ErrorMessage;
