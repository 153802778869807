import React, { ReactNode } from "react";

import { Spinner } from "../../../assets/icons/Icons";
import styled from "styled-components";

export const ButtonContainer = styled.div`
  display: inline-block;
  position: relative;
  top: 7px;
  left: 15px;
`;

export const Button = styled.button`
  background: linear-gradient(90deg, #ff622e 27.21%, #ff8d24 122.48%);
  box-shadow: 0px 1px 20px #0000000c;
  color: #fff;

  display: flex;
  padding: 7px 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  font-size: 20px;
  font-weight: 500;
  border: none;
  border-radius: 20px;

  transition: transform ease 0.1s;

  &:hover {
    transform: scale(1.05);
  }

  &.small {
    font-size: 16px;
    padding: 5px 21px;
  }

  &.link {
    font-size: 16px;
    width: auto;
    background: none;
    border: none;
    text-decoration: underline;
    padding: 0px;
    text-shadow: none;
    &:hover {
      background-color: none;
      transform: none;
    }
    &:active {
      background-color: none;
    }
  }

  &.choices {
    background-color: ${({ theme }) => theme.button.colors.choices};
    padding: 10px 12px;
    &:hover {
      transform: scale(1.03);
    }
  }
  &.secondary {
    background: none;
    opacity: 0.7;
    background-color: ${({ theme }) => theme.button.colors.secondary};
  }

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  &.pulsing {
    animation-name: pulsing;
    animation-duration: 2.5s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
    transition: none !important;
  }

  @keyframes pulsing {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(1.3);
    }
    20% {
      transform: scale(0.9);
    }
    30% {
      transform: scale(1);
    }

    100% {
      transform: scale(1);
    }
  }
  &.translucent {
    opacity: 0.5;
    transition: opacity 2.5s ease;
    :hover {
      opacity: 1;
    }
  }
  &.emoji {
    width: auto;
    background: none;
    font-size: 23px;
    background: none;
    border: none;
    padding: 0px;
    &:hover {
      background-color: none;
      transform: none;
    }
    &:active {
      background-color: none;
    }
  }
  &.breakout-modal {
    width: fit-content;
    text-shadow: none;
    font-size: 16px;
    font-weight: 700;
    border-radius: 20px;
  }
`;

type ButtonProps = React.ComponentProps<typeof Button> & {
  loading?: boolean | null;
  children: ReactNode;
};

export const LoadingButton = ({ loading, children, ...props }: ButtonProps) => {
  if (loading) {
    return (
      <div
        className="spinner"
        style={{
          minWidth: props.style?.minWidth,
          marginLeft: props.style?.marginLeft,
          marginRight: props.style?.marginRight,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner fill="#Ff9900" />
      </div>
    );
  } else {
    return <Button {...props}>{children}</Button>;
  }
};
