import React, { FC, useEffect } from "react";
import styled from "styled-components";
import { FlowItem } from "./FlowItem";
import { groupBy } from "../../../session/util";
import { store } from "../../../session/store";

const FlowsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;
  border-radius: 9px;
  padding: 10px;
  overflow-y: auto;
  height: 100%;
`;

interface Props {
  flows: LobbyInvitation[];
}

export const FlowsList: FC<Props> = ({ flows }) => {
  const selectedFlow = store.use.selectedFlow();

  const storeScrollPosition = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    sessionStorage.setItem("flowsScrollPosition", target.scrollTop.toString());
  };

  // Restore scroll position
  useEffect(() => {
    const target = document.getElementById("flows-list") as HTMLDivElement;
    target.scrollTop = Number(sessionStorage.getItem("flowsScrollPosition"));
  }, []);

  let flowsList = flows;

  // Group the flows by the first word
  if (!selectedFlow) {
    const flowGroups = groupBy(flows, (f) => f.name.split(" ")[0]);

    // Create a LobbyInvitation placeholder for each group
    flowsList = Object.keys(flowGroups).map((flowGroupName) => {
      const flowGroup = flowGroups[flowGroupName];
      if (flowGroup.length == 1) return flowGroup[0];

      const groupFlow: LobbyInvitation = {
        hashId: `*${flowGroupName}*`,
        name: flowGroupName,
        min: Math.min(...flowGroup.map((f) => f.min)),
        max: Math.max(...flowGroup.map((f) => f.max)),
        preferred: Math.min(...flowGroup.map((f) => f.preferred)),
        description: flowGroup[0].description,
        duration: Math.min(...flowGroup.map((f) => f.duration)),
        minDuration: Math.min(...flowGroup.map((f) => f.duration)),
        maxDuration: Math.max(...flowGroup.map((f) => f.duration)),
        heatType: flowGroup[0].heatType,
        heat: 0,
        minHeat: Math.min(...flowGroup.map((f) => f.minHeat)),
        flowGroup: flowGroup,
        isGather: false,
        meetingLocation: "online",
        minGroupSize: undefined,
        maxGroupSize: undefined,
        numGroups: undefined,
        numUnmatchedParticipants: undefined,
        prompts: [],
        durationPreference: "Short",
        durationPreferenceSelected: undefined,
        flowGroupParent: undefined,
        partnerCodeJoin: false,
      };

      for (const flow of flowGroup) {
        flow.flowGroupParent = groupFlow;
      }

      return groupFlow;
    });
  }

  return (
    <FlowsWrapper onScroll={storeScrollPosition} id="flows-list">
      {selectedFlow?.flowGroup && (
        <>
          <FlowItem
            key={selectedFlow.hashId}
            flow={selectedFlow}
            data-testid={`flow-${selectedFlow.hashId}`}
            mode="back"
          />
          <hr />
        </>
      )}
      {flowsList.map((flow) => {
        return <FlowItem key={flow.hashId} flow={flow} data-testid={`flow-${flow.hashId}`} mode="forward" />;
      })}
    </FlowsWrapper>
  );
};
