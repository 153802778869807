import React from "react";
import { Record, Spinner, Stop } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { store } from "../../../session/store";
import { can } from "../../../helpers/can";
import styled from "styled-components";
import { toggleRecording } from "../../../session/SessionChannel/toggleRecording";
import { ClearOverlay } from "../Modal/ModalStyle";
import { modalClear, modalClearAll, modalShow } from "../Modal/Modals";

const RecordingWrapper = styled.div`
  line-height: 19px;
  display: flex;
  text-align: center;
  position: relative;
  justify-content: center;
  height: 60px;
  overflow: visible;

  white-space: nowrap;

  button.recording-loading {
    cursor: auto;
    svg {
      height: 24px;
    }

    & div {
      position: relative;
      top: -2px;
    }
  }
`;

const RecordingMenu = styled.span`
  z-index: 6;
  display: flex;
  flex: 1;
  flex-direction: column;
  position: absolute;
  top: -140px;
  left: -45px;
  text-align: left;
  width: 192px;
  gap: 10px;
  background: #333;
  border-radius: 15px;
  padding: 5px;
`;

const RecordingButton = styled.span`
  width: 170px;
  padding: 6px 11px;
  border-radius: 10px;
  &:hover {
    cursor: pointer;
    background: #444;
  }
`;

export const RecordButton = () => {
  const recording = store.use.recording();
  const transcribing = store.use.transcribing();
  const recordingLoading = store.use.recordingLoading();
  const showRecordingMenu = store.use.showModals().includes("RecordingMenu");
  const currentUser = store.use.currentUser();
  const invitation = store.use.invitation();
  const isLobby = invitation.lobby;

  const active = recording || transcribing;

  // Check if recording is allowed
  if (!isLobby || !can("record", currentUser, invitation)) {
    return;
  }

  return (
    <RecordingWrapper className="menuButton">
      <MenuButton
        className={recordingLoading ? "recording-loading" : ""}
        buttonIcon={
          recordingLoading ? <Spinner fill="white" /> : active ? <Stop stroke="white" /> : <Record stroke="white" />
        }
        onClick={() => {
          if (showRecordingMenu) modalClear("RecordingMenu");
          else if (active) toggleRecording("stop_recording");
          else modalShow("RecordingMenu", false);
        }}
        disabled={recordingLoading}
      >
        {recordingLoading &&
          (active ? (
            <FormattedMessage id="recording_button.label_stop" defaultMessage="Stoping..." />
          ) : (
            <FormattedMessage id="recording_button.label_start" defaultMessage="Starting..." />
          ))}
        {!recordingLoading &&
          (active ? (
            <FormattedMessage id="recording_button.label_stop" defaultMessage="Stop" />
          ) : (
            <FormattedMessage id="recording_button.label_start" defaultMessage="Record" />
          ))}
      </MenuButton>
      {showRecordingMenu && (
        <>
          <ClearOverlay
            onClick={() => {
              modalClearAll();
            }}
          />
          <RecordingMenu>
            <RecordingButton onClick={() => toggleRecording("start_recording")}>
              <FormattedMessage id="recording_button.start_recording" defaultMessage="Record" />
            </RecordingButton>
            <RecordingButton onClick={() => toggleRecording("start_transcription")}>
              <FormattedMessage id="recording_button.start_transcribing" defaultMessage="Transcribe (cc)" />
            </RecordingButton>
            <RecordingButton onClick={() => toggleRecording("start_recording_and_transcription")}>
              <FormattedMessage
                id="recording_button.start_recording_and_transcribing"
                defaultMessage="Record & Transcribe"
              />
            </RecordingButton>
          </RecordingMenu>
        </>
      )}
    </RecordingWrapper>
  );
};
