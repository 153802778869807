import styled from "styled-components";
import React, { useEffect } from "react";
import { WifiNone } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import { Overlay } from "../Modal/ModalStyle";

const Wrapper = styled.div`
  display: flex;

  margin-bottom: 5px;
  position: absolute;
  top: -50px;
  left: 10px;
  width: calc(100% - 20px);

  align-items: center;
  justify-content: center;
  z-index: 999;
  top: -200px;
  transition: top 0.5s ease-in-out;

  &.visible {
    top: 45px;
  }
`;
const Status = styled.div`
  text-align: left;
  color: #eee;
  font-weight: bold;
  font-size: 16px;
  line-height: normal;
  background: #722;
  display: inline-flex;
  align-items: center;
  padding: 10px 16px;
  gap: 5px;
  border-radius: 10px;
  flex-direction: column !important;

  svg {
    margin-right: 5px;

    width: 25px;
  }

  div {
    flex-direction: column !important;
  }

  span {
    font-weight: normal;
  }

  .blink {
    animation: blink 1.4s infinite;
  }

  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const ConnectionIssueBadge = ({ isConnected }: { isConnected: boolean }) => {
  const [animateIn, setAnimateIn] = React.useState(false);

  // A bit of a convoluted way to animate the badge in and out
  // We need the element to be on the page, and then to add a class to it
  // that will trigger the animation. And on disconnect, we need to remove
  // the class, and then remove the element from the page.
  useEffect(() => {
    setTimeout(() => {
      setAnimateIn(!isConnected);
    }, 300);
  }, [isConnected]);

  if (isConnected && !animateIn) return null;

  return (
    <>
      <Wrapper className={animateIn && !isConnected ? "visible" : ""}>
        <Status>
          <WifiNone />
          <div>
            <FormattedMessage
              id="connection_issue_badge.heading"
              description={"Heading of the connection issue indicator"}
              defaultMessage="Disconnected"
            />
            <br />

            <span>
              <FormattedMessage
                id="connection_issue_badge.text"
                description={"Body text of the connection issue indicator"}
                defaultMessage="Attempting to reconnect. Refresh page if this persists."
              />
            </span>
          </div>
        </Status>
      </Wrapper>
      <Overlay $blur={false} />
    </>
  );
};
