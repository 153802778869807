{
  "actions.content_waiting_for_partner": "Warten auf Partner",
  "analytics_button.label": "Statistik",
  "audio_level_inner.indicator": "Audiopegelanzeige",
  "blur_background.blur_background": "Hintergrund",
  "blur_background.unblur_background": "Unschärfe",
  "breakout_button": "Flows",
  "breakout_modal.rooms": "Räume",
  "breakout_modal.start_new": "Neuer Flow",
  "breakout_modal.title": "Flow",
  "breakout_room.roomName": "Raum {Nummer}: {name} ({currentStep}/{totalSteps})",
  "breakout_rooms.close": "Schließen",
  "breakout_rooms.closeAll": "Alle schließen",
  "breakout_rooms.lobby": "Lobby",
  "breakout_rooms.noParticipants": "Alle Teilnehmer befinden sich derzeit in einer Sitzung",
  "breakout_rooms.onGoingRooms": "Laufend ({quantity} räume)",
  "camera_area.error_message": "Fehlermeldung: \"{error}\"",
  "camera_area.permission_camera_error": "Bitte überprüfen Sie, ob Ihr Browser die Berechtigung hat.",
  "camera_area.refresh_camera_error": "Und versuchen Sie, Ihren Browser zu aktualisieren oder neu zu starten.",
  "camera_button.camera_off": "Ausschalten",
  "camera_button.camera_on": "Einschalten",
  "chat_button": "Chat",
  "chat.heading": "Chat",
  "chat.offline": "Offline (Chat deaktiviert)",
  "chat.online": "Online",
  "chat.partner_status": "Ihr Partner ist: {status}",
  "chat.username_you": "Du",
  "confirm_breakout_modal.cancel": "Abbrechen",
  "confirm_breakout_modal.confirm_button": "Flow Starten",
  "confirm_breakout_modal.description": "Schicke {number} Teilnehmer zum flow",
  "confirm_breakout_modal.heading": "Flow Starten",
  "connection_issue_badge.heading": "Getrennt",
  "connection_issue_badge.text": "Wiederverbindung wird versucht. Aktualisieren Sie die Seite, wenn das Problem weiterhin besteht.",
  "content.and": "und",
  "content.your_partners": "ihre partner",
  "content.yourself": "sich selbst",
  "countdown_timer.time_left": "Übrige Zeit",
  "determine_content.and": "und",
  "determine_content.end_call": "Beenden Sie den Anruf und geben Sie Feedback",
  "determine_content.your_partners": "ihre partner",
  "determine_content.yourself": "sich selbst",
  "drop_participant_modal.cancel": "Abbrechen",
  "drop_participant_modal.drop_user": "Benutzer löschen",
  "drop_participant_modal.header": "Benutzer aus Sitzung entfernen",
  "drop_participant_modal.this_will_rewind": "Dadurch wird der Ablauf ein paar Schritte zum Anfang des aktuellen Abschnitts zurückgespult.",
  "drop_participant_modal.you_can_drop_a_user": "Sie können einen Benutzer löschen, von dem Sie glauben, dass er nicht zurückkommt, damit der Flow fortgesetzt werden kann.",
  "entities.slash": "/",
  "feedback_score_step.content_waiting_for_partner": "Warten auf Partner",
  "feedback_words_step.content_waiting_for_partner": "Warten auf Partner",
  "feeling_survey.happy_label": "Glücklich, erhoben",
  "feeling_survey.intro_question": "Bevor wir anfangen, wie fühlst du dich jetzt?",
  "feeling_survey.sad_label": "Traurig, niedergeschlagen",
  "flows_list.button_start": "Starten",
  "flows_list.groups_gather_all": "alle versammeln",
  "flows_list.min_groups": "in Gruppen von {min}",
  "flows_list.min_max_groups": "in Gruppen von {min} – {max} (bevorzugt: {perferred})",
  "leave_button": "Verlassen",
  "lobby_status_badge.breakout": "Flow",
  "lobby_status_badge.leave_room": "Raum verlassen",
  "lobby_status_badge.lobby_title": "Lobby",
  "lobby_status_badge.re_join": "Wieder beitreten",
  "lobby_status_badge.room_closing_in": "Raum schließt in {actualSecondsRemaining} Sekunden",
  "lost_connection": "Wiederverbindung wird versucht",
  "manage_participant_modal.drop_confirmation": "Sind Sie sicher, dass Sie {participant_name} aus der Sitzung fallen lassen möchten?",
  "manage_participant_modal.drop_from_the_session": "Lassen Sie {participant_name} aus der Sitzung fallen",
  "manage_participant_modal.drop": "Tropfen",
  "manage_participant_modal.header": "Teilnehmer",
  "manage_participant_modal.mute_all": "Stumm schalten",
  "manage_participant_modal.mute": "Stumm",
  "manage_participant_modal.offline": "Offline",
  "manage_participant_modal.online_participants": "Online-Teilnehmer",
  "manage_participant_modal.participant_name": "{participant_name}",
  "manage_participant_modal.tile.you": "(du)",
  "manage_participant_modal.total_participants": "Gesamtzahl der Teilnehmer",
  "manage_participants_modal.cancel": "Abbrechen",
  "manage_participants_modal.drop_participant": "Tropfen {participant_name}",
  "matching_check_box.noParticipants": "Es sind keine passenden Teilnehmer verfügbar",
  "matching_check_box.return_to_room": "Kehre in den Raum zurück",
  "mic_button.mute": "Stumm",
  "mic_button.unmute": "Aktivieren",
  "more_button.menu": "Menü",
  "more_button.more": "Mehr",
  "more_menu.enter-fullscreen": "Vollbild",
  "more_menu.exit-fullscreen": "Vollbild beenden",
  "participants_button.label": "Teilnehmer",
  "prejoin.join_digital_button": "Beitreten",
  "prejoin.setup_camera_off": "Kamera aus",
  "prejoin.setup_camera_started": "Kamera gestartet",
  "prejoin.setup_starting_camera": "Starten der Kamera",
  "prejoin.setup_state_off_starting_camera": "Starten der Kamera",
  "screenshare_button": "Bildschirmfreigabe",
  "smart_button_fixture.content.Done": "Erledigt",
  "start_new_breakout.clearAll": "Alle deselectieren",
  "start_new_breakout.flows": "Flows",
  "start_new_breakout.includeInMatching": "In der Auswahl einschließen",
  "start_new_breakout.selectAll": "Alle auswählen",
  "switch_digital_physical_button.in_person": "in Person",
  "switch_digital_physical_button.video": "Video",
  "switch_digital_physical_modal.cancel": "Abbrechen",
  "switch_physical_digital_modal.digital_switch_description": "Der Videomodus aktiviert Kamera und Mikrofon und ist für die Durchführung eines Warmspace über einen Video-Chat optimiert.",
  "switch_physical_digital_modal.heading": "Schaltmodus",
  "switch_physical_digital_modal.join_digital": "Modus wechseln",
  "switch_physical_digital_modal.join_physical": "Modus wechseln",
  "switch_physical_digital_modal.physical_switch_description": "Dies deaktiviert die Kamera und das Mikrofon und ist für eine in Person Verbindung optimiert.",
  "test": "test",
  "tile_no_video.disconnected": "Getrennt",
  "tile_no_video.drop_user": "Benutzer löschen",
  "tile_no_video.getting_ready": "Machen Sie sich bereit",
  "tile_no_video.location": "{locationMessage}",
  "tile_no_video.username_you": "Du",
  "username.has_completed_step": "{name} hat diesen Schritt abgeschlossen",
  "username.has_not_completed_step": "{name} hat diesen Schritt noch nicht abgeschlossen",
  "username.you": "Du",
  "vU4qZm": "Einreichen",
  "waiting_for_partner.more_than_10_minutes": "Es sind mehr als 10 Minuten vergangen und höchstwahrscheinlich wird Ihr Partner nicht auftauchen.",
  "waiting_for_partner.partner_getting_ready": "Ihr Partner macht sich bereit.",
  "waiting_for_partner.partner_might_not_show": "Ihr Partner wird möglicherweise nicht angezeigt.",
  "waiting_for_partner.schedule_new": "Plane einen neuen Warmspace",
  "waiting_for_partner.session_id": "Sitzungs-ID: {sessionId}",
  "waiting_for_partner.sorry": "Es tut uns leid, dass Sie jetzt keine Sitzung haben. Bitte planen Sie eine neue Sitzung.",
  "waiting_for_partner.waiting_for_partner": "Warte darauf, dass Ihr Partner sich Ihnen anschließt.",
  "waiting_for_partner.waiting_for_partners": "warten darauf, dass Ihre Partner sich Ihnen anschließen."
}
