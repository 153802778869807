import { textForLocale } from "../lib/textForLocale";
import {
  isChoicesStep,
  isContentStep,
  isIframeStep,
  isFeedbackScoreStep,
  isFeedbackWordsStep,
} from "../types/type_guards";
import shuffle from "../lib/shuffle";
import { Liquid, TagToken } from "liquidjs";
import { Locales } from "./intl";
import { IntlShape } from "react-intl";
import { saveStepContentVariables } from "./SessionChannel/saveStepContentVariables";

const liquidEngine = new Liquid({ strictFilters: true, strictVariables: true });
liquidEngine.registerFilter("shuffle", (a, seed = 1) => shuffle(a, seed));
let currentSavedVariables: SavedStepVariables = {};
liquidEngine.registerFilter("save", (value: string, key: string) => {
  currentSavedVariables[key] = value;
  return value;
});

liquidEngine.registerTag("quote", {
  parse(tagToken, remainTokens) {
    this.tpls = [];
    let closed = false;
    while (remainTokens.length) {
      const token = remainTokens.shift();
      if (!token) return;

      // we got the end tag! stop taking tokens
      if ((token as TagToken).name === "endquote") {
        closed = true;
        break;
      }

      const tpl = this.liquid.parser.parseToken(token, remainTokens);
      this.tpls.push(tpl);
    }
    if (!closed) throw new Error(`tag ${tagToken.getText()} not closed`);
  },
  *render(context, emitter) {
    emitter.write("<div class='quote'>");
    yield this.liquid.renderer.renderTemplates(this.tpls, context, emitter);
    emitter.write("</div>");
  },
});

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatNames = (names: string[], intl: IntlShape): string => {
  switch (names.length) {
    case 0:
      return intl.formatMessage({
        id: "determine_content.yourself",
        defaultMessage: "yourself",
      });
    case 1:
      return names[0];
    case 2:
      return `${names[0]} ${intl.formatMessage({
        id: "determine_content.and",
        defaultMessage: "and",
      })} ${names[1]}`;
    case 3:
    case 4:
    case 5:
      return `${names.slice(0, names.length - 1).join(", ")} ${intl.formatMessage({
        id: "determine_content.and",
        defaultMessage: "and",
      })} ${names[names.length - 1]}`;
    default:
      return intl.formatMessage({
        id: "determine_content.your_partners",
        defaultMessage: "your partners",
      });
  }
};

export const determineContent = (
  step: AnyStep,
  userResponses: UserResponses,
  savedStepVariables: SavedStepVariables,
  roleData: RoleData,
  currentUserId: number,
  intl: IntlShape,
  locale: Locales,
  flowData: InvitationResponse["flowData"],
  hashedID: string,
): StepContent | undefined => {
  if (
    !(
      isContentStep(step) ||
      isChoicesStep(step) ||
      isIframeStep(step) ||
      isFeedbackScoreStep(step) ||
      isFeedbackWordsStep(step)
    )
  )
    return;

  const role = step.shareContent ? "EVERYONE_ELSE" : roleData[currentUserId]?.role;

  const stepContent = step.content[role];

  const allRoles = Object.values(roleData);
  const partnerNames = allRoles.filter((u) => u.id !== currentUserId).map((u) => u.name);
  const participantNames = allRoles.map((u) => u.name);
  participantNames.sort();
  shuffle(participantNames, hashedID);

  const formattedNames = formatNames(partnerNames, intl);

  // Merge any new variables into the existing ones
  Object.assign(currentSavedVariables, savedStepVariables);

  const data = Object.assign(
    {},
    (flowData && (flowData[locale] || flowData["en"])) || {},
    { userResponses },
    {
      aName: allRoles.find((u) => u.role === "A")?.name,
      bName: allRoles.find((u) => u.role === "B")?.name,
      allPartners: partnerNames.join(", "),
      allParticipants: participantNames.join(", "),
      yourPartners: formattedNames,
      YourPartners: capitalizeFirstLetter(formattedNames),
      yourName: roleData[currentUserId]?.name,
      gatheringID: hashedID,
      saved: savedStepVariables,
    },
  );

  // Text
  const textTemplate = textForLocale(stepContent?.text, locale);
  let text = "";
  try {
    if (textTemplate) {
      text = liquidEngine.parseAndRenderSync(textTemplate, { data });

      // Persist any variables produced by this step
      if (Object.keys(currentSavedVariables).length > 0) {
        saveStepContentVariables(currentSavedVariables);
        currentSavedVariables = {};
      }
    }
  } catch (error) {
    // Show the original text if there is an error parsing the liquid template
    // but log the liquid parse error to the console.
    text = textTemplate;

    if (process.env.NODE_ENV === "development") {
      console.log(error);
    }
  }

  // Button Text
  const actions = stepContent?.actions?.map((action) => {
    const actionText = textForLocale(action.buttonText, locale);
    const actionTextResult = liquidEngine.parseAndRenderSync(actionText, { data });

    return {
      onClick: action.onClick,
      text: actionTextResult,
    };
  });

  // Choices
  let choices: { text: string; onClick: ActionConsequence }[] = [];
  if (isChoicesStep(step)) {
    choices = step.content[role].choices?.map((choice) => {
      const choiceText = textForLocale(choice.text, locale);
      const choiceTextResult = liquidEngine.parseAndRenderSync(choiceText || "", { data });

      return {
        text: choiceTextResult,
        onClick: choice.onClick,
      };
    });
  }

  // Feedback Score
  let descriptionLow = "";
  let descriptionHigh = "";
  if (isFeedbackScoreStep(step)) {
    const template = textForLocale(step.content[role].descriptionLow, locale);
    const highTemplate = textForLocale(step.content[role].descriptionHigh, locale);
    descriptionLow = liquidEngine.parseAndRenderSync(template, { data });
    descriptionHigh = liquidEngine.parseAndRenderSync(highTemplate, { data });
  }

  // Feedback Words
  let placeholder = "";
  if (isFeedbackWordsStep(step)) {
    const placeholderTemplate = textForLocale(step.content[role].placeholder, locale);
    placeholder = liquidEngine.parseAndRenderSync(placeholderTemplate, { data });
  }

  return {
    text,
    actions,
    choices,
    actionTiming: stepContent?.actionTiming,
    descriptionLow,
    descriptionHigh,
    placeholder,
  };
};
