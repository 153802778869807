import styled from "styled-components";
import React, { FC, useEffect, useMemo } from "react";
import FlipNumbers from "react-flip-numbers";
import { Close, Flame } from "../../../assets/icons/Icons";
import { store } from "../../../session/store";
import { ParticipantList } from "./ParticipantList";

const Wrapper = styled.button`
  border: none;
  font-weight: bold;
  display: flex;

  height: 30px;
  flex-shrink: 0;
  border-radius: 8px;
  padding: 10px;
  position: relative;
  align-items: center;
  gap: 5px;
  overflow: hidden;
  background-color: #282828;

  &:hover {
    background-color: #323232;
  }

  border-top: 1px solid #444;

  box-sizing: border-box;
  color: #ff8c1a;

  > svg {
    position: relative;
    top: -1px !important;
  }

  transition: width 0.5s;

  cursor: pointer;

  .glow {
    position: absolute;
    width: 100%;
    height: 200%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 0;
    pointer-events: none;

    background: radial-gradient(circle at center 165%, #282828 56%, #ff8c21 60%, #28282800 77%, #28282800 100%);

    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center 6px;

    &.animate {
      animation: glow 1.5s ease-in-out;
    }
  }

  @keyframes glow {
    0% {
      background-position: center 5px;
    }
    50% {
      background-position: center -1px;
    }
    100% {
      background-position: center 7px;
    }
  }
`;

export const HeatBox = styled.div`
  background-color: #1d1d1d;
  inset: unset;
  border: 0px;
  font-size: 19px;
  font-weight: 900;
  border-radius: 9px;
  border-top: 1px solid #333;
  box-shadow: 0px 4px 10px 0px #00000033;
  background-color: #282828e5;
  backdrop-filter: blur(10px);
  padding: 12px 0px 0px;
  color: #fff4f4;
  text-align: left;

  .popoverGlow {
    position: absolute;
    width: 100%;
    height: 200%;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: -1;
    pointer-events: none;

    background-size: 100%;
    background: radial-gradient(circle at center 165%, #282828 56%, #ff8c21 60%, #28282800 77%, #28282800 100%)
      no-repeat center 65px;
  }
`;

const HeatPopover = styled(HeatBox)`
  position: absolute;
  top: 42px;
  left: 7px;

  width: 340px;
  overflow: hidden;

  animation: heatPopover 0.3s ease-in-out;

  @keyframes heatPopover {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

  .content {
    z-index: 1;
    gap: 5px;
    display: flex;
    flex-direction: column;
  }

  .title {
    padding: 0px 15px;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    button.close {
      background: none;
      border: none;
      position: relative;
      left: 10px;
    }
  }

  .subtitle {
    font-size: 16px;
    padding: 0px 15px;
    color: #c8b2b2;
    font-weight: 300;
  }

  .participantList {
    overflow-y: auto;
    padding: 0px 0px;

    box-sizing: border-box;
    padding-bottom: 20px;
    max-height: 400px;

    div {
      scrollbar-color: #444 #222;

      /* WebKit and Chromiums */

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #222;
      }

      &::-webkit-scrollbar-thumb {
        background: #444;
        border-radius: 5px;
      }
    }

    .inner {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
  }

  &::backdrop {
    background: rgb(0 0 0 / 25%);
  }
`;

export const HeatBadge: FC<{ value: number }> = ({ value }) => {
  const [currentValue, setCurrentValue] = React.useState(0);
  const [targetValue, setTargetValue] = React.useState(0);
  const [animatingGlow, setAnimatingGlow] = React.useState(false);

  const participants = store.use.participants();

  useEffect(() => {
    setTargetValue(value);
  }, [value]);

  // Lerp the value
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentValue((currentValue) => {
        if (Math.abs(currentValue - targetValue) < 0.8) {
          clearInterval(interval);
          setAnimatingGlow(true);
          setTimeout(() => {
            setAnimatingGlow(false);
          }, 1500);
          return targetValue;
        }
        return currentValue + 0.3 * (targetValue - currentValue);
      });
    }, 200);

    return () => {
      clearInterval(interval);
      setCurrentValue(targetValue);
    };
  }, [targetValue]);

  const participantValues = useMemo(() => Object.values(participants), [participants]) as Participant[];

  return (
    <>
      {/* @ts-ignore */}
      <Wrapper popoverTarget="heatPopover" title="Open Recent Warmth Modal">
        <div className={animatingGlow ? "glow animate" : "glow"} />

        <Flame fill={"#ff8c1a"} />

        <FlipNumbers
          height={18}
          width={10}
          color="#ff8c1a"
          background="transparent"
          play
          perspective={1000}
          numbers={String(Math.floor(currentValue))}
        />
      </Wrapper>
      {/* @ts-ignore */}
      <HeatPopover id="heatPopover" popover="auto">
        <div className="content">
          <div className="title">
            <div>
              <Flame fill={"#ff8c1a"} width="15px" height="22px" />
              Recent Warmth
            </div>

            <button
              className="close"
              title="Close Recent Warmth Modal"
              // @ts-ignore
              popoverTarget="heatPopover"
              popoverTargetAction="toggle"
            >
              <Close stroke="#fff" />
            </button>
          </div>
          <div className="subtitle">A higher group average unlocks more advanced flows</div>

          <div className="participantList">
            <div className="inner">
              <ParticipantList participants={participantValues} />
            </div>
          </div>
        </div>
        <div className="popoverGlow" />
      </HeatPopover>
    </>
  );
};
