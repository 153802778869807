import { useStore } from "../store";

const heatToastTimeouts: { [participantID: number]: NodeJS.Timeout } = {};

export const heatToast = function (participantID: number, amount: number) {
  if (heatToastTimeouts[participantID]) {
    clearTimeout(heatToastTimeouts[participantID]);
  }

  setTimeout(() => {
    useStore.setState({ heatToasts: Object.assign({}, useStore.getState().heatToasts, { [participantID]: 0 }) });
  }, 0);

  setTimeout(() => {
    useStore.setState({ heatToasts: Object.assign({}, useStore.getState().heatToasts, { [participantID]: amount }) });
  }, 100);

  heatToastTimeouts[participantID] = setTimeout(() => {
    useStore.setState({ heatToasts: Object.assign({}, useStore.getState().heatToasts, { [participantID]: 0 }) });
  }, 3000);
};
