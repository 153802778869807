import React from "react";
import { FormattedMessage } from "react-intl";
import { ConfigHeader } from "../../Modal/ModalStyle";
import { ClockIcon } from "../../../../assets/icons/Icons";
import styled from "styled-components";
import { store, useStore } from "../../../../session/store";
import { TextInput } from "../../TextInput/TextInput";

const Prompts = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #fff;

  height: auto;
  width: 100%;

  input {
    background-color: #fff;
    color: #000;
  }
`;

export const PromptsConfig = () => {
  const currentFlowConfig =
    (store.use.currentFlowConfig() as LobbyInvitation) || (store.use.selectedFlow() as LobbyInvitation);

  if (currentFlowConfig.prompts.length == 0) return;

  const changePrompt = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const newPrompts = [...currentFlowConfig.prompts];
    newPrompts[idx] = e.target.value;
    const newFlowConfig = Object.assign({}, currentFlowConfig);
    newFlowConfig.prompts = newPrompts;
    useStore.setState({ currentFlowConfig: newFlowConfig });

    return true;
  };

  return (
    <>
      <ConfigHeader>
        <ClockIcon stroke="#000" />
        <FormattedMessage id="breakout.prompts" defaultMessage="Customize Prompts" />
      </ConfigHeader>
      <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        <Prompts>
          {currentFlowConfig.prompts.map((prompt, index) => {
            return (
              <TextInput
                key={`input-${index}`}
                value={prompt}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => changePrompt(e, index)}
              />
            );
          })}
        </Prompts>
      </div>
    </>
  );
};
