import React from "react";
import { ReportPartnerIcon } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { FlowsModal } from "../FlowsModal/FlowsModal";
import { modalToggle } from "../Modal/Modals";
import { store, useStore } from "../../../session/store";

export const ReportPartnerButton = () => {
  const showModal = store.use.showModals().includes("ReportPartner");

  return (
    <>
      <MenuButton
        buttonIcon={<ReportPartnerIcon fill={showModal ? "#FFA100" : "white"} />}
        onClick={() => {
          modalToggle("ReportPartner");
          useStore.setState({ showExternalContent: false, showChat: false });
        }}
        className={showModal ? "selected" : ""}
      >
        <FormattedMessage id="breakout_button" defaultMessage="Report Partner" />
      </MenuButton>
      <FlowsModal />
    </>
  );
};
