import styled from "styled-components";
import React, { FC, useEffect, useRef } from "react";
import { Flame } from "../../../assets/icons/Icons";
import { store } from "../../../session/store";

const Wrapper = styled.div`
  display: flex;

  height: 30px;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
  color: #ff8c1a;
  font-size: 25px;
  position: absolute;
  font-weight: bold;
  top: -40px;
  opacity: 0;

  background: linear-gradient(90deg, #ff8c1a 0%, #ff8c1a 40%, #fff4e9 57%, #ff8c1a 80%, #ff8c1a 90%, #ff8c1a 100%);

  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  > svg {
    position: relative;
    top: 2px !important;
  }

  &.animate {
    animation:
      heatGainSlideIn 3s ease,
      heatGainPulse 3s ease,
      shine 1s linear;
  }

  @keyframes shine {
    to {
      background-position: -200% center;
    }
  }

  cursor: pointer;

  @keyframes heatGainSlideIn {
    0% {
      opacity: 0;
      top: -40px;
    }
    20% {
      opacity: 1;
      top: -45px;
    }
    30% {
      opacity: 1;
      top: -45px;
    }
    75% {
      opacity: 0;
      top: -80px;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes heatGainPulse {
    0% {
      transform: scale(0.1);
    }
    20% {
      transform: scale(1.2);
    }

    100% {
      transform: scale(0.8);
    }
  }
`;

export const HeatGainIndicator: FC<{ userId: number }> = ({ userId }) => {
  const element = useRef<HTMLDivElement>(null);
  const heatValue = store.use.heatToasts()[userId];

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    element.current?.classList.remove("animate");

    if (heatValue > 0) {
      element.current?.classList.add("animate");
      timeout = setTimeout(() => {
        if (element) {
          element.current?.classList.remove("animate");
        }
      }, 3000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [heatValue, element]);

  return (
    <Wrapper ref={element}>
      +{heatValue}
      <Flame fill={"#ff8c1a"} width="18px" height="18px" />
    </Wrapper>
  );
};
