import { Core } from "../core";

export const updateAutoLaunchFlow = function (delaySeconds: number, flowHashId?: string) {
  const core = Core.GetInstance();
  const data = {
    autoLaunchFlowHashId: flowHashId ? flowHashId : undefined,
    autoLaunchDelaySec: delaySeconds,
  };
  core.subscription?.perform("update_autolaunch_flow", data);
};
