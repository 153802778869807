import { useState, useEffect, useRef } from "react";

/**
 This hook will be used to find out seconds remianing between current date and passed expiration date.
 As soon as seconds remianing reaches to 0, this count down stops.
 */
const useCountDown = (expirationDate?: Date) => {
  const countDownTime = new Date(expirationDate || 0).getTime();
  const [countDown, setCountDown] = useState(Math.round((countDownTime - new Date().getTime()) / 1000));
  useEffect(() => {
    const interval = setInterval(() => {
      const seconds = Math.round((countDownTime - new Date().getTime()) / 1000);
      setCountDown(seconds);
      if (seconds < 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownTime]);
  return countDown < 0 ? 0 : countDown;
};

export const useCountDownArray = (expirationDates: (Date | undefined)[]) => {
  const curTime = new Date().getTime();
  const initialCountDowns = expirationDates.map((d) =>
    d ? Math.max(0, Math.round((d.getTime() - curTime) / 1000)) : 0,
  );
  const [countDowns, setCountDowns] = useState(initialCountDowns);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const newCountDowns = expirationDates.map((d) =>
      d ? Math.max(0, Math.round((d.getTime() - new Date().getTime()) / 1000)) : 0,
    );
    setCountDowns(newCountDowns);
    if (newCountDowns.every((v) => v <= 0)) {
      clearInterval(intervalRef.current || undefined);
      return;
    }
    intervalRef.current = setInterval(() => {
      const newCountDownSeconds = expirationDates.map((d) =>
        d ? Math.max(0, Math.round((d.getTime() - new Date().getTime()) / 1000)) : 0,
      );
      setCountDowns(newCountDownSeconds);
      if (newCountDownSeconds.every((v) => v <= 0)) {
        clearInterval(intervalRef.current || undefined);
      }
    }, 1000);

    return () => clearInterval(intervalRef.current || undefined);
  }, [expirationDates]);

  return countDowns;
};

export default useCountDown;
