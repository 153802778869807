import React from "react";
import { FormattedMessage } from "react-intl";
import { ConfigHeader } from "../../Modal/ModalStyle";
import { store, useStore } from "../../../../session/store";
import { AutoJoinIcon } from "../../../../assets/icons/Icons";
import { Checkbox } from "../../Checkbox/Checkbox";

export const PartnerCodeJoinConfig: React.FC = () => {
  const currentFlowConfig = store.use.currentFlowConfig() as LobbyInvitation;
  const selectedFlow = store.use.selectedFlow() as LobbyInvitation;

  const togglePartnerCodeJoin = () => {
    const updatedFlowConfig = Object.assign({}, currentFlowConfig);
    if (currentFlowConfig.partnerCodeJoin) {
      updatedFlowConfig.min = selectedFlow.min;
      updatedFlowConfig.max = selectedFlow.max;
      updatedFlowConfig.preferred = selectedFlow.preferred;
      updatedFlowConfig.partnerCodeJoin = false;
    } else {
      updatedFlowConfig.min = 1;
      updatedFlowConfig.max = 1;
      updatedFlowConfig.preferred = 1;
      updatedFlowConfig.partnerCodeJoin = true;
    }

    useStore.setState({ currentFlowConfig: updatedFlowConfig });
  };

  return (
    <>
      <ConfigHeader>
        <AutoJoinIcon stroke="#000" />
        <FormattedMessage id="breakout.toggle_partner_code_join" defaultMessage="Self Organize Groups" />
      </ConfigHeader>
      <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        <Checkbox>
          <FormattedMessage
            id="breakout.toggle_auto_message"
            defaultMessage="Let people form and join groups by entering their personal codes"
          />
          <input
            type="checkbox"
            checked={currentFlowConfig.partnerCodeJoin}
            onChange={togglePartnerCodeJoin}
            onClick={togglePartnerCodeJoin}
          />
          <span className="checkmark"></span>
        </Checkbox>
      </div>
    </>
  );
};
