import React from "react";
import { FormattedMessage } from "react-intl";
import { ConfigHeader } from "../../Modal/ModalStyle";
import { store, useStore } from "../../../../session/store";
import { AutoJoinIcon } from "../../../../assets/icons/Icons";
import { Checkbox } from "../../Checkbox/Checkbox";

export const AutoJoinConfig: React.FC = () => {
  const autoJoin = store.use.autoJoin();

  const toggleAutoJoin = () => {
    useStore.setState({ autoJoin: !autoJoin });
  };

  return (
    <>
      <ConfigHeader>
        <AutoJoinIcon stroke="#000" />
        <FormattedMessage id="breakout.toggle_auto_join" defaultMessage="Auto Join" />
      </ConfigHeader>
      <div style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        <Checkbox>
          <FormattedMessage
            id="breakout.toggle_auto_message"
            defaultMessage="Let late participants auto-join sessions"
          />
          <input type="checkbox" checked={autoJoin} onChange={toggleAutoJoin} onClick={toggleAutoJoin} />
          <span className="checkmark"></span>
        </Checkbox>
      </div>
    </>
  );
};
