import React, { FC } from "react";
import { store } from "../../../session/store";
import { FlowsConfig } from "./FlowsConfig";

export const FlowsModal: FC = () => {
  const showFlowsModal = store.use.showModals().includes("FlowsModal");
  const rooms = store.use.rooms();
  const invitation = store.use.invitation();

  const lobbyHashId = invitation.lobbyHashId || invitation.hashedID;
  const nonLobbyRooms = rooms.filter((room) => room.hashId !== lobbyHashId);

  if (!showFlowsModal) return null;

  return <FlowsConfig rooms={rooms} nonLobbyRooms={nonLobbyRooms} invitation={invitation} />;
};
