import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { DailyEventObjectInputSettingsUpdated, DailyEventObjectNonFatalError } from "@daily-co/daily-js";
import { useInputSettings } from "@daily-co/daily-react";
import toast from "react-simple-toasts";
import MenuButton from "./MenuButton";
import { Noise } from "../../../assets/icons/Icons";
import DailyIframe from "@daily-co/daily-js";
import { modalClear } from "../Modal/Modals";

const NoiseCancellation = () => {
  const intl = useIntl();
  const browserInfo = DailyIframe.supportedBrowser();
  const toggleNoiseCancelation = () => {
    const newNoiseCancellationEnabled = !noiseCancellationEnabled;
    updateInputSettings({
      audio: {
        processor: {
          type: newNoiseCancellationEnabled ? "noise-cancellation" : "none",
        },
      },
    });
    setNoiseCancellationEnabled(newNoiseCancellationEnabled);
    modalClear("MoreMenu");
    if (newNoiseCancellationEnabled) {
      localStorage.setItem("warmspace.cancelNoise", "1");
    } else {
      localStorage.removeItem("warmspace.cancelNoise");
    }
  };

  const { updateInputSettings, inputSettings } = useInputSettings({
    onError: useCallback((event: DailyEventObjectNonFatalError) => {
      toast(`Error setting up noise cancellation`, 2000);
      console.debug("NoiseCancellation " + event.errorMsg);
    }, []),

    onInputSettingsUpdated: useCallback((event: DailyEventObjectInputSettingsUpdated) => {
      if (event.inputSettings?.audio?.processor?.type === "noise-cancellation") {
        setNoiseCancellationEnabled(true);
      } else {
        setNoiseCancellationEnabled(false);
      }
    }, []),
  });
  const [noiseCancellationEnabled, setNoiseCancellationEnabled] = useState<boolean>(
    inputSettings?.audio?.processor?.type === "noise-cancellation",
  );

  if (!browserInfo.supportsAudioProcessing) {
    return <></>;
  }
  return (
    <MenuButton buttonIcon={<Noise stroke="white" />} onClick={() => toggleNoiseCancelation()}>
      {!noiseCancellationEnabled
        ? intl.formatMessage({
            id: "noise_cancellation.enable",
            defaultMessage: "Reduce Noise",
          })
        : intl.formatMessage({
            id: "noise_cancellation.disable",
            defaultMessage: "Original Audio",
          })}
    </MenuButton>
  );
};

export default NoiseCancellation;
