import { Core } from "../core";
import { ClearLoaderFunc } from "../../lib/requests/postAction";

export const saveStepContentVariables = function (
  stepVariables: { [key: string]: string },
  clearLoader?: ClearLoaderFunc,
) {
  console.debug("stepVariables", stepVariables);
  const core = Core.GetInstance();
  core.subscription?.perform("save_variables", { variables: stepVariables }, clearLoader);
};
