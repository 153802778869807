import styled from "styled-components";
import React from "react";
import { FormattedMessage } from "react-intl";
import { CheckMarkIcon, ExpandIcon, ContractIcon, MicrophoneSlash, VideoSlash } from "../../../assets/icons/Icons";
import { store, useStore } from "../../../session/store";
import { Tooltip } from "react-tooltip";
import { useIntl } from "react-intl";
import AudioLevel from "../AudioLevel/AudioLevel";
import { ElementPosition } from "../AudioLevel/AudioLevelInner";
import { useParticipantProperty } from "@daily-co/daily-react";
import { SelfTile } from "../VideoGrid/SelfTile";
import { RaisedHand } from "../../../assets/icons/Icons";
import { HeatGainIndicator } from "./HeatGainIndicator";
import LocalAudioLevel from "../AudioLevel/LocalAudioLevel";
import { HeatLevelImage } from "./HeatLevelImage";

const Pill = styled.div`
  display: flex;
  gap: 10px;
  background: #00000099;
  box-sizing: border-box;
  z-index: 10;

  align-items: center;
  min-height: 34px;
  width: auto;
  padding: 0px 8px;
  border-radius: 0px 8px 0px 8px;
`;

export const UsernameWrapper = styled.div`
  display: flex;
  z-index: 5;

  position: absolute;
  bottom: 0px;
  left: 0px;
  cursor: pointer;

  &:hover {
    ${Pill} {
      background: #3c3c3c99;
    }
  }

  &.raisedHand {
    cursor: pointer;
    ${Pill} {
      background: #c51;
    }
  }

  .raisedHandIcon {
    position: relative;
    height: 100%;
    top: -1px;
  }

  &.isLocal {
    right: 5px;
    left: auto;
    bottom: 5px;

    ${Pill} {
      border-radius: 8px;
    }

    @media (max-width: 740px) {
      bottom: 70px;
      &.multipleParticipants {
        bottom: 5px;
        right: 5px;
      }
    }
  }
`;

const Name = styled.div`
  font-size: 14px;
  z-index: 100;
  flex-align: center;

  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.editable {
    cursor: pointer;
  }
`;

const CheckMark = styled.div`
  display: flex;
`;

const Expand = styled.button`
  display: flex;
  align-items: center;
  position: relative;

  background: #00000000;
  border: none;
  padding: 0px;
`;

const Contract = styled.button`
  display: flex;
  align-items: center;
  position: relative;
  background: #00000000;
  border: none;
  padding: 0px;
`;

// Note: This is a bit bigger than the actual expand/contract icons because
//  they're rather small and this makes the toggle easier to tap on mobile
const ExpandContractClickArea = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  left: -8px;
  top: -13px;
`;

function setShowSelf(showSelf: boolean) {
  useStore.setState({ showSelf: showSelf });
  if (showSelf) {
    localStorage.setItem("warmspace.showSelf", "1");
  } else {
    localStorage.removeItem("warmspace.showSelf");
  }
}

const Username = ({
  isLocal,
  participantLocation,
  roleData,
  dailyID,
  solo,
  userId,
  style,
}: {
  isLocal: boolean;
  participantLocation: Participant["location"];
  roleData: RoleDataItem | undefined;
  dailyID: string;
  solo: boolean;
  userId: number;
  style?: React.CSSProperties;
}) => {
  const showSelf = store.use.showSelf();
  const participants = store.use.participants();

  const raisedHands = store.use.raisedHands();
  const currentUser = store.use.currentUser();

  const intl = useIntl();

  const trackState = useParticipantProperty(dailyID, "tracks");
  const hasVideo = trackState && trackState.video.state === "playable";
  const hasAudio = trackState && trackState.audio.state === "playable";

  const raisedHand = raisedHands.includes(userId);

  const classNames = [];
  if (isLocal) classNames.push("isLocal");
  if (raisedHand) classNames.push("raisedHand");
  if (Object.values(participants).length > 2) classNames.push("multipleParticipants");

  const participant = participants[userId];

  if (!participant) return null;

  const name = participant.name;

  const participantNameClicked = () => {
    useStore.setState({ showParticipantModal: userId });
  };

  return (
    <UsernameWrapper className={classNames.join(" ")} onClick={participantNameClicked} style={style}>
      <HeatGainIndicator userId={userId} />
      {showSelf && isLocal && !solo && (
        <SelfTile
          userName={currentUser.name}
          warmspaceID={currentUser.id}
          participantLocation={"session"}
          participantCount={1}
          key={"video-self"}
        />
      )}
      <Pill>
        {raisedHand && <RaisedHand stroke="#fff" width={16} />}
        {!raisedHand && <HeatLevelImage participant={participant} />}
        <Name className="editable">{isLocal ? <FormattedMessage id="username.you" defaultMessage="You" /> : name}</Name>

        {!hasAudio && participantLocation === "session" ? (
          <MicrophoneSlash />
        ) : dailyID ? (
          isLocal ? (
            <LocalAudioLevel id={dailyID} local={isLocal} position={ElementPosition.TileViewV2} />
          ) : (
            <AudioLevel id={dailyID} local={isLocal} position={ElementPosition.TileViewV2} />
          )
        ) : null}

        {!hasVideo && participantLocation === "session" ? <VideoSlash /> : ""}

        {roleData && roleData.actionStatus !== "NONE" && participantLocation === "session" && (
          <>
            <CheckMark
              data-tooltip-id={"my-tooltip-" + name}
              data-tooltip-content={
                roleData.actionStatus == "COMPLETED"
                  ? intl.formatMessage(
                      {
                        id: "username.has_completed_step",
                        defaultMessage: "{name} has completed this step",
                      },
                      { name: name },
                    )
                  : intl.formatMessage(
                      {
                        id: "username.has_not_completed_step",
                        defaultMessage: "{name} has not completed this step yet",
                      },
                      { name: name },
                    )
              }
            >
              <CheckMarkIcon stroke={roleData.actionStatus == "COMPLETED" ? "#E7E7E7" : "grey"} />
            </CheckMark>
            <Tooltip id={"my-tooltip-" + name} />
          </>
        )}

        {isLocal &&
          !solo &&
          (showSelf ? (
            <Contract
              onClick={(e) => {
                e.stopPropagation();
                setShowSelf(false);
              }}
              data-testid="contract-icon"
            >
              <ContractIcon fill="white" />
              <ExpandContractClickArea />
            </Contract>
          ) : (
            <Expand
              onClick={(e) => {
                e.stopPropagation();
                setShowSelf(true);
              }}
              data-testid="expand-icon"
            >
              <ExpandIcon />
              <ExpandContractClickArea />
            </Expand>
          ))}
      </Pill>
    </UsernameWrapper>
  );
};

export default Username;
