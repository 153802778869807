import React, { FC, useEffect, useState } from "react";
import { store } from "../../../session/store";
import { isContentStep, isFeedbackScoreStep, isFeedbackWordsStep } from "../../../types/type_guards";
import { SFX2, SoundEffect } from "../../../assets/SFX2";

function playChime(
  setChimePlayed: (value: ((prevState: boolean) => boolean) | boolean) => void,
  timeChime: SoundEffect = "timeChime",
  volume: number = 0.2,
): void {
  setChimePlayed(true);
  SFX2.playAudio(timeChime, volume);
}

export const Chime: FC = () => {
  const currentStep = store.use.currentStep();
  const invitation = store.use.invitation();
  const roleData = store.use.roleData();
  const currentUser = store.use.currentUser();
  const timeOnStep = store.use.timeOnStep();
  const stepTiming = store.use.stepTiming();

  const isChimedStep =
    isContentStep(currentStep) || isFeedbackScoreStep(currentStep) || isFeedbackWordsStep(currentStep);

  let role = roleData[currentUser.id]?.role;
  if (isContentStep(currentStep) && currentStep.shareContent) {
    role = "EVERYONE_ELSE";
  }

  const [chimePlayed, setChimePlayed] = useState(false);
  const [chimePlayedAgain, setChimePlayedAgain] = useState(false);

  useEffect(() => {
    playChime(() => {});
  }, [invitation]);

  // If a video session, then play chimes at the start of every step so people notice they need to switch roles
  useEffect(() => {
    setChimePlayed(false);
    setChimePlayedAgain(false);
    if (isChimedStep && invitation.playChimes && !invitation.inPerson) {
      playChime(setChimePlayed);
    }
  }, [currentStep?.id, role]);

  // If an in-person session, then play chimes before the end of each step so people notice they're out of time
  useEffect(() => {
    // if (
    //   invitation.inPerson &&
    //   !chimePlayed &&
    //   stepTiming &&
    //   invitation.playChimes &&
    //   timeOnStep >= stepTiming - 12 && // play the chime 10 sec before the end
    //   stepTiming > 20 // don't play the first chime for steps shorter than 20 sec
    // ) {
    //   playChime(setChimePlayed);
    // }
    if (
      invitation.inPerson &&
      !chimePlayedAgain &&
      stepTiming &&
      invitation.playChimes &&
      timeOnStep >= stepTiming - 4
    ) {
      playChime(setChimePlayedAgain, "doubleTimeChime" as SoundEffect, 0.5);
    }
  }, [currentStep?.id, role, timeOnStep]);

  return <></>;
};
