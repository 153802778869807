// @ts-nocheck
import React, { memo } from "react";
import { MemoizedParticipantHeat } from "../Participants/ParticipantHeat";
import { areEqual, FixedSizeList } from "react-window";

const Row = memo(function rowBuilder({ index, data, style }: { index: number; data: any; style: any }) {
  return (
    <MemoizedParticipantHeat
      index={index}
      participant={data[index]}
      clickable={true}
      key={`p-heat-${data[index].id}`}
      style={style}
    />
  );
}, areEqual);

export const ParticipantList = ({ participants }: { participants: Participant[] }) => {
  return (
    <FixedSizeList
      height={Math.min(participants.length * 56, 250)}
      itemCount={participants.length}
      itemData={participants}
      itemSize={55}
      width={340}
    >
      {Row}
    </FixedSizeList>
  );
};
