export const getLevel = (cumulativeHeat: number, heatLevelToHeat: { [level: number]: number } = {}) => {
  if (cumulativeHeat === undefined || cumulativeHeat === null || isNaN(cumulativeHeat)) {
    cumulativeHeat = 0;
  }

  const heatLevels = Object.keys(heatLevelToHeat).map((s) => parseInt(s));

  heatLevels.sort((a, b) => {
    return a - b;
  });

  let totalHeat = 0;
  let minHeatLevel = 1;

  for (const heatLevel of heatLevels) {
    const heat = heatLevelToHeat[heatLevel];
    totalHeat += heat;
    if (totalHeat <= cumulativeHeat) {
      minHeatLevel = heatLevel;
    } else {
      const level = minHeatLevel;
      const pointsForNextLevel = totalHeat - cumulativeHeat;
      return { level, pointsForNextLevel };
    }
  }

  // Beyond the last heat level
  const level = heatLevels.length > 0 ? heatLevels[heatLevels.length - 1] : 1;
  const pointsForNextLevel = 0;

  return { level, pointsForNextLevel };
};
