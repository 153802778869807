import React, { useEffect, useRef, useState, memo, useMemo, useCallback } from "react";
import { Text } from "@visx/text";
import { scaleLog } from "@visx/scale";
import { WordCloudWord } from "./AnalyticsWidget";
import styled from "styled-components";
import Wordcloud from "./VizxCopy/Wordcloud";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  user-select: none;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  max-height: 100%;

  svg {
    cursor: pointer;
  }

  label {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    margin-right: 8px;
  }
  textarea {
    min-height: 100px;
  }

  @keyframes leaves {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  text {
    position: relative;
    animation: leaves 0.4s linear;
  }
`;

interface Props {
  words: WordCloudWord[];
}

export interface WordData {
  text: string;
  value: number;
}

const colors = ["#ff622e", "#ff8d24", "#eee", "#fee16b", "#feb401"];

const fixedValueGenerator = () => 0.5;

function WarmspaceWordCloud({ words }: Props) {
  const minFontSize = 12;
  const wordCloudContainer = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  const [baseFontSize, setBaseFontSize] = useState(60);

  const [wordsPlaced, setWordsPlaced] = useState(0);

  // Track word cloud container size
  useEffect(() => {
    const updateSize = () => {
      if (wordCloudContainer.current) {
        const oldSize = size;
        const newSize = { width: 0, height: 0 };
        newSize.width = wordCloudContainer.current.offsetWidth;
        newSize.height = wordCloudContainer.current.offsetHeight;

        if (oldSize.width !== newSize.width || oldSize.height !== newSize.height) {
          setSize(newSize);
        }

        // Reset the font size if the window size grew
        if (oldSize.width < newSize.width || oldSize.height < newSize.height) {
          setBaseFontSize(60);
        }
      }
    };

    const onResize = () => {
      setTimeout(updateSize, 1);
    };

    window.addEventListener("resize", onResize);

    updateSize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [size]);

  useEffect(() => {
    if (wordsPlaced > 0 && wordsPlaced < words.length && baseFontSize > minFontSize) {
      const newFontSize = baseFontSize * 0.8;
      setBaseFontSize(newFontSize);
    }
  }, [wordsPlaced, size]);

  const halfFontSize = baseFontSize / 2;
  let characterCount = 0;
  for (const word of words) {
    characterCount += word.text.length;
  }
  const approximateMaxFont = 50000 / characterCount;
  const maxFont =
    approximateMaxFont > baseFontSize
      ? baseFontSize
      : approximateMaxFont < halfFontSize
        ? halfFontSize
        : approximateMaxFont;
  const minFont = maxFont > halfFontSize ? maxFont / 2 : baseFontSize / 4;

  const mappedWords = useMemo(() => {
    return words.map((w) => ({ text: w.text, value: w.count }));
  }, [words]);

  const fontScale = scaleLog({
    domain: [Math.min(...words.map((w) => w.count)), Math.max(...words.map((w) => w.count))],
    range: [Math.max(minFont, minFontSize), Math.max(maxFont, minFontSize)],
  });

  const fontSizeSetter = useCallback(
    (datum: WordData) => {
      if (datum.text.length > 20) {
        if (size.width < 500) return 12;
        return 16;
      }
      return fontScale(datum.value);
    },
    [fontScale],
  );

  return (
    <Wrapper ref={wordCloudContainer}>
      <Wordcloud
        words={mappedWords}
        width={size.width}
        height={size.height - 30}
        // @ts-ignore
        fontSize={fontSizeSetter}
        font={"Arial"}
        padding={4}
        spiral={"rectangular"}
        rotate={0}
        random={fixedValueGenerator}
        setWordsPlaced={setWordsPlaced}
      >
        {(cloudWords) =>
          cloudWords.map((w, i) => (
            <Text
              key={w.text}
              fill={colors[i % colors.length]}
              textAnchor={"middle"}
              transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
              fontSize={w.size}
              fontFamily={w.font}
            >
              {w.text}
            </Text>
          ))
        }
      </Wordcloud>
    </Wrapper>
  );
}

export default memo(WarmspaceWordCloud);
