import { MediaContent, loadMedia } from "../assets/SFX2";

export interface MediaFiles {
  [url: string]: MediaContent;
}

export const loadFlowAudioVideo = async function (audioPaths: string[]) {
  console.debug("[session-ui]: Loading narration.");
  const response: MediaFiles = {};
  const loadingPromises: Promise<boolean | MediaContent>[] = [];

  audioPaths.forEach((mediaPath) => {
    loadingPromises.push(loadMedia(mediaPath));
  });

  try {
    const results = await Promise.all(loadingPromises);
    for (const result of results) {
      const mediaContent = result as MediaContent;
      response[mediaContent.path] = mediaContent;
    }
  } catch (e) {
    console.log(e);
    // throw new Error(`Unable to load narration audio files.`);
  }

  return response;
};
