import { store } from "../session/store";

const AUDIO_PATH_PREFIX = "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com/session-app/session-app";

let audioContext: AudioContext;
const audios: Audios = {};
const silentAudio = document.createElement("audio");
silentAudio.src =
  "https://warmspace-cdn.sgp1.cdn.digitaloceanspaces.com/session-app/session-app/2024-12-16/Warmspace-silence.mp3"; // media file with tiny bit of silence

type AudioEventType = "play" | "pause" | "timeupdate";

async function decodeAudio(arrayBuffer: ArrayBuffer, soundFileUrl: string, key: string): Promise<MediaContent> {
  const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
  console.debug(`loaded audio web audio buffer:`, soundFileUrl);
  const eventListeners: Record<string, Set<EventListener>> = {
    play: new Set(),
    pause: new Set(),
    timeupdate: new Set(),
  };

  function dispatchEvent(eventName: AudioEventType): void {
    eventListeners[eventName]?.forEach((callback) => callback({} as Event));
  }

  function startUpdatingTime(mediaContent: MediaContent): void {
    const update = () => {
      if (mediaContent.isPlaying && mediaContent.currentTime() < mediaContent.duration) {
        dispatchEvent("timeupdate");
        setTimeout(update, 100); // Update every 100ms
      } else {
        mediaContent.sourceNode!.onended = null;
        mediaContent.sourceNode!.stop();
        mediaContent.isPlaying = false;
        dispatchEvent("pause");
      }
    };
    update();
  }

  const mediaContent: MediaContent = {
    buffer: audioBuffer,
    path: soundFileUrl,
    isPlaying: false,
    pausedAt: 0,
    startTime: 0,
    play: (volume: number) => {
      const isPlaying = mediaContent.isPlaying;
      if (!isPlaying) {
        playMediaContent(mediaContent, volume, key);
        console.debug("play audio buffer");
        dispatchEvent("play");
        startUpdatingTime(mediaContent);
      }
    },
    pause: () => {
      pauseMediaContent(mediaContent, key);
      dispatchEvent("pause");
    },
    duration: audioBuffer.duration,
    setVolume: (volume: number) => {
      if (mediaContent.gainNode) {
        mediaContent.gainNode!.gain!.value = volume;
      }
    },
    currentTime: () =>
      Math.min(
        mediaContent.duration,
        mediaContent.startTime == 0 ? 0 : audioContext.currentTime - mediaContent.startTime!,
      ),
    setCurrentTime: (position: number, volume: number) => {
      if (mediaContent.isPlaying) {
        mediaContent.sourceNode!.onended = null;
        mediaContent.sourceNode!.stop();
        mediaContent.isPlaying = false;
      }
      mediaContent.pausedAt = position; // Set new playback position
      mediaContent.play(volume);
      console.debug("Seeking to:", position, "seconds");
    },
    addEventListener: (eventName, listener) => {
      if (eventListeners[eventName]) {
        eventListeners[eventName].add(listener);
      } else {
        console.log("Unhandled event type:", eventName, "key:", key);
      }
    },
    removeEventListener: (eventName, listener) => {
      eventListeners[eventName].delete(listener);
    },
    dispatchEnd: () => dispatchEvent("pause"),
  };
  audios[key] = mediaContent;
  return mediaContent;
}

function loadFileAsHtmlMediaElement(soundEffect: SoundEffect, filePath: string): Promise<MediaContent> {
  const mediaElement = document.createElement(filePath.includes(".mp4") ? "video" : "audio");
  mediaElement.src = getPath(filePath);
  mediaElement.crossOrigin = "anonymous";
  const mediaContent: MediaContent = {
    element: mediaElement,
    path: filePath,
    play: (volume: number) => playMediaContent(mediaContent, volume, soundEffect),
    pause: () => pauseMediaContent(mediaContent, soundEffect),
    duration: 1, // placeholder until the duration is loaded
    currentTime: () => mediaElement.currentTime,
    setCurrentTime: (position, volume) => {
      mediaElement.currentTime = position;
      mediaElement.volume = volume;
    },
    setVolume: (volume: number) => (mediaElement.volume = volume),
    addEventListener: (eventName, listener) => mediaElement.addEventListener(eventName, listener, true),
    removeEventListener: (eventName, listener) => mediaElement.removeEventListener(eventName, listener, true),
  };
  audios[soundEffect] = mediaContent;
  console.debug("loaded audio as HTMLMediaElement", filePath);

  return new Promise<MediaContent>((resolve, reject) => {
    mediaElement.addEventListener("canplaythrough", () => {
      mediaContent.duration = mediaElement.duration;
      console.debug("[session-ui] mediaElement element loaded", mediaElement);
      resolve(mediaContent);
    });

    mediaElement.addEventListener("error", function (e) {
      console.log(e);
      reject(e);
    });
  });
}

function getPath(mediaFileUrl: string) {
  return mediaFileUrl.startsWith("http") ? mediaFileUrl : AUDIO_PATH_PREFIX + mediaFileUrl;
}

async function loadFileAsWebAudio(soundEffect: SoundEffect, soundFileUrl: string): Promise<MediaContent> {
  return fetch(getPath(soundFileUrl)).then(async (response) => {
    const arrayBuffer = await response.arrayBuffer();
    if (!audioContext) {
      // Play a silent audio file to work around an issue causing web audio API playback to be silent if the phone is in silent mode
      return silentAudio.play().then(() => {
        if (!audioContext) {
          // @ts-ignore
          audioContext = new (window.AudioContext || window.webkitAudioContext)();
        }
        return decodeAudio(arrayBuffer, soundFileUrl, soundEffect);
      });
    } else {
      return decodeAudio(arrayBuffer, soundFileUrl, soundEffect);
    }
  });
}

export type SoundEffect =
  | "timeChime"
  | "doubleTimeChime"
  | "newChatMessage"
  | "partnerDisconnected"
  | "partnerArrived"
  | "recordingStarted"
  | "waitingRoomArrived"
  | "infinity";

export interface MediaContent {
  path: string;
  buffer?: AudioBuffer;
  element?: HTMLAudioElement | HTMLVideoElement;
  sourceNode?: AudioBufferSourceNode;
  gainNode?: GainNode;
  startTime?: number; // When playback started
  pausedAt?: number; // When paused
  isPlaying?: boolean;

  play: (volume: number) => void;
  pause: () => void;
  duration: number;
  currentTime: () => number;
  setCurrentTime: (position: number, volume: number) => void;
  setVolume: (volume: number) => void;
  addEventListener: (eventName: string, listener: EventListener) => void;
  removeEventListener: (eventName: string, listener: EventListener) => void;
  dispatchEnd?: () => void;
}
interface Audios {
  [soundEffect: string]: MediaContent;
}

function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export function loadMedia(key: string, filePath: string = key): Promise<MediaContent> {
  const invitation = store.getState().invitation;
  // For iOS audios, load it as web audio to bypass security restrictions/bugs.
  // if (!filePath.includes(".mp4") && (true || (!invitation.inPerson && isIOS()))) {
  if (!filePath.includes(".mp4") && !invitation.inPerson && isIOS()) {
    return loadFileAsWebAudio(key as SoundEffect, filePath);
  } else {
    return loadFileAsHtmlMediaElement(key as SoundEffect, filePath);
  }
}

function playMediaContent(mediaContent: MediaContent, volume: number, key: string) {
  function playAudioBuffer() {
    try {
      if (mediaContent.isPlaying) {
        mediaContent.sourceNode!.onended = null;
        mediaContent.sourceNode!.stop();
        mediaContent.pausedAt = 0;
      }

      const source = audioContext.createBufferSource();
      source.buffer = mediaContent.buffer!;
      source.loop = false;

      const gainNode = audioContext.createGain(); // Create the GainNode
      source.connect(gainNode);
      gainNode.connect(audioContext.destination);
      mediaContent.gainNode = gainNode;
      mediaContent.sourceNode = source;

      // Stop when the track ends
      source.onended = () => {
        console.debug("playing audio d2 (onended)");
        mediaContent.isPlaying = false;
        mediaContent.pausedAt = 0; // Reset when the track naturally finishes
        source.onended = null;
        mediaContent.dispatchEnd!();
      };

      mediaContent.gainNode!.gain.value = volume;
      mediaContent.startTime = audioContext.currentTime - mediaContent.pausedAt!;
      mediaContent.sourceNode.start(0, mediaContent.pausedAt);
      mediaContent.isPlaying = true;
    } catch (e) {
      console.log(`Error playing audio as AudioBuffer: ${key}`, e);
    }
  }

  if (mediaContent.buffer) {
    console.debug("Playing audio as AudioBuffer", key);

    // Resume the AudioContext if it is suspended for any reason
    if (audioContext.state === "suspended") {
      audioContext
        .resume()
        .then(playAudioBuffer)
        .catch((e) => {
          console.log(`Unable to resume AudioContext when playing audio as AudioBuffer: ${key}`, e);
        });
    } else {
      playAudioBuffer();
    }
  } else {
    console.debug("Playing media as HTMLMediaElement", key);
    mediaContent.element!.volume = volume;
    mediaContent.element!.play().catch((e) => {
      console.log(`Error playing media as HTMLMediaElement: ${key}`, e);
    });
  }
}

function pauseMediaContent(mediaContent: MediaContent, key: string) {
  if (mediaContent.buffer) {
    if (!mediaContent.isPlaying) return;

    mediaContent.sourceNode!.onended = null;
    mediaContent.sourceNode!.stop();
    mediaContent.pausedAt = audioContext.currentTime - mediaContent.startTime!; // Save the current playback position
    mediaContent.isPlaying = false;

    console.debug("Audio paused at:", mediaContent.pausedAt, "seconds for: ", key);
  } else {
    mediaContent.element?.pause();
  }
}

export const SFX2: {
  loadAudios: (retry: number) => void;
  playAudio: (soundEffect: SoundEffect, volume: number) => void;
  timeChime?: AudioBuffer;
  tripleTimeChime?: AudioBuffer;
  newChatMessage?: AudioBuffer;
  partnerDisconnected?: AudioBuffer;
  partnerArrived?: AudioBuffer;
  recordingStarted?: AudioBuffer;
  waitingRoomArrived?: AudioBuffer;
} = {
  loadAudios: (retry: number) => {
    if (Object.keys(audios).length == 0) {
      const soundEffectFiles: {
        [soundEffect: string]: string;
      } = {
        timeChime: "/2023-30-07/sfx/sfx-time-chime.mp3",
        doubleTimeChime: "/2025-01-18/sfx/double-chime.mp3",
        newChatMessage: "/2023-30-07/sfx/sfx-chat-ping.mp3",
        partnerDisconnected: "/2023-11-23/sfx/sfx-disconnect.mp3",
        partnerArrived: "/2023-11-23/sfx/sfx-arrive.mp3",
        recordingStarted: "/2024-09-08/sfx/sfx-recording-started.mp3",
        waitingRoomArrived: "/2024-11-24/sfx/sfx-waiting-room-arrive.mp3",
        infinity: "/2024-12-20/sfx/sfx-infinity2.mp3",
      };

      const promises = Object.keys(soundEffectFiles).map((soundEffect) => {
        const filePath = soundEffectFiles[soundEffect]!;
        return loadMedia(soundEffect, filePath);
      });

      Promise.all(promises).catch((e) => {
        const nextRetry = retry + 1;

        const secondsUntilRetry = Math.pow(nextRetry, 2);
        console.log(`Problem loading sound effects. Attempting to reload them in ${secondsUntilRetry} seconds`, e);

        setTimeout(() => SFX2.loadAudios(nextRetry), 1000 * secondsUntilRetry);
      });
    }
  },

  playAudio: (soundEffect: SoundEffect, volume: number = 1) => {
    const mediaContent = audios[soundEffect];

    if (!mediaContent) {
      console.log(`No loaded audio to play for soundEffect: ${soundEffect}`);
      return;
    }
    playMediaContent(mediaContent, volume, soundEffect);
  },
};
