function integerToRoman(num: number) {
  // Define a map of Roman numeral symbols and their values
  const romanMap = [
    { value: 1000, symbol: "M" },
    { value: 900, symbol: "CM" },
    { value: 500, symbol: "D" },
    { value: 400, symbol: "CD" },
    { value: 100, symbol: "C" },
    { value: 90, symbol: "XC" },
    { value: 50, symbol: "L" },
    { value: 40, symbol: "XL" },
    { value: 10, symbol: "X" },
    { value: 9, symbol: "IX" },
    { value: 5, symbol: "V" },
    { value: 4, symbol: "IV" },
    { value: 1, symbol: "I" },
  ];

  let result = "";

  // Loop through the Roman numeral map
  for (const { value, symbol } of romanMap) {
    while (num >= value) {
      result += symbol; // Append the symbol
      num -= value; // Subtract the value
    }
  }

  return result;
}

export const getTitle = (level: number, heatToTitles: HeatLevelTitles) => {
  const missingTitleResponse = {
    level,
    heatLevelTitle: { imageUrl: "missing.jpg", title: "Missing" },
    titleString: "Missing",
    romanNumeral: "I",
  };

  if (!heatToTitles) {
    return missingTitleResponse;
  }

  const heatLevelKeys = Object.keys(heatToTitles);
  const heatLevels = heatLevelKeys.map((s) => parseInt(s));

  heatLevels.sort((a, b) => {
    return a - b;
  });

  let minHeatLevelTitle: HeatLevelTitle | null = null;

  for (const heatLevel of heatLevels) {
    if (heatLevel <= level) {
      minHeatLevelTitle = heatToTitles[heatLevel];
    }
  }

  if (!minHeatLevelTitle) {
    return missingTitleResponse;
  }

  const heatLevelTitle = minHeatLevelTitle!;
  const romanNumeral = integerToRoman(level - minHeatLevelTitle!.level + 1);
  const titleString = minHeatLevelTitle?.title + " " + romanNumeral;
  return { level, heatLevelTitle, titleString, romanNumeral };
};
