import { useEffect } from "react";
import { useStore } from "../session/store";
import { classifyDevice } from "./classifyDevice";

function getDeviceClassification() {
  const { innerWidth: width, innerHeight: height } = window;

  return classifyDevice(width, height);
}

export function useSetDeviceClassification() {
  useEffect(() => {
    useStore.setState({ deviceClassification: getDeviceClassification() });

    function handleResize() {
      // Update the device classification when the window is resized
      // but only if its different
      if (
        useStore.getState().deviceClassification.orientation === getDeviceClassification().orientation &&
        useStore.getState().deviceClassification.size === getDeviceClassification().size
      ) {
        return;
      }

      useStore.setState({ deviceClassification: getDeviceClassification() });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
}
