import { Core } from "../core";
import { useStore } from "../store";
import { determineContent } from "../determineContent";
import CableMessage, {
  BreakoutRoomMessage,
  isAudioCommand,
  isBreakoutRoomMessage,
  isBreakoutRoomsMessage,
  isChatMessage,
  isDropUserCommand,
  isForceLeaveMessage,
  isGoToUrlMessage,
  isHeatUpdates,
  isInvitationResponse,
  isInvitationUpdate,
  isMuteAllMessage,
  isMuteParticipantMessage,
  isPresencesMessage,
  isReloadCommand,
  isRoomClosingMessage,
  isToastMessage,
  isToggleHandMessage,
  isToggleRecordingMessage,
  isUpdateMessage,
} from "../../types/CableMessage";
import toast, { createToast, Toast } from "react-simple-toasts";
import { replacePlaceholder } from "../../lib/getFeedbackURL";
import { Rooms } from "../../types/Rooms";
import { onAudioMessage } from "./onAudioMessage";
import { isContentStep } from "../../types/type_guards";
import { Locales } from "../intl";
import React from "react";
import { admitParticipant } from "./admitParticipant";
import { can } from "../../helpers/can";
import { SFX2 } from "../../assets/SFX2";
import { setMicEnabledTo } from "../Daily/setupCallObject";
import { heatToast } from "../Heat/heatToast";
import { admitAll } from "./admitAll";
import { levelUpToast } from "../Heat/levelUpToast";
import { endCall } from "../../components/UI/ConfirmLeaveModal/ConfirmLeaveModal";
import { getExternalMeetingLink } from "../../components/UI/Badge/LobbyStatusBar";

const SYSTEM_USER_ID = -1;

export const onMessage = async function (this: Core, u: CableMessage) {
  console.debug(`[session-ui]: received websocket message for invitationId:${this.invitation.hashedID}`, u);
  const core = Core.GetInstance();

  // If the server is sending us somewhere, then go there
  if (isGoToUrlMessage(u)) {
    const url = getExternalMeetingLink(u.go_to_url);
    window.location.href = replacePlaceholder(url, {
      hashedInvitationID: this.invitation.hashedID,
      yourID: this.currentUser.id,
    });
    return;
  }

  // If we're transitioning, then save the presences message for later processing
  if (useStore.getState().transitioning && isPresencesMessage(u)) {
    if (u.delta) {
      const newPresencesMessages = [...useStore.getState().unprocessedPresencesMessages];
      newPresencesMessages.push(u);
      useStore.setState({ unprocessedPresencesMessages: newPresencesMessages });
    } else {
      useStore.setState({ unprocessedPresencesMessages: [u] });
    }
  } else if (isPresencesMessage(u)) {
    const oldRooms = useStore.getState().rooms;
    const roomMap: Rooms = {};

    // Create a map of the rooms and re-add all the participants to create new underlying arrays
    oldRooms.forEach((oldRoom) => {
      const oldRoomCopy = Object.assign({}, oldRoom);
      oldRoomCopy.participantIds = [...oldRoom.participantIds];
      roomMap[oldRoom.hashId] = oldRoomCopy;
    });

    const allParticipants: Participants = Object.assign({}, useStore.getState().allParticipants);
    const curParticipants: Participants = u.delta ? Object.assign({}, useStore.getState().participants) : {};
    let roomsUpdated = false;

    Object.keys(u.presences).forEach((userIdString) => {
      const userID = parseInt(userIdString);
      const presence = u.presences[userID];

      const participant = {
        dailySessionID: presence.dailySessionID,
        id: userID,
        authorizations: presence.authorizations,
        joined: true,
        kicked: false,
        location: presence.location,
        name: presence.name,
        breakoutRoomHashId: presence.breakoutRoomHashId,
        curRoomHashId: presence.hashedInvitationID,
        personalCode: presence.personalCode,
        cumulativeHeat: presence.cumulativeHeat,
        recentHeat: presence.recentHeat,
      };

      allParticipants[userID] = participant;

      // If the user is not in this invitation or have been dropped, then delete them from the curParticipants
      if (presence.hashedInvitationID != this.invitation.hashedID || presence.location === "dropped") {
        delete curParticipants[participant.id];
      }
      // Otherwise add them to the curParticipants
      else {
        curParticipants[participant.id] = participant;
      }

      // If the presence update indicates additional people in a breakout room, then add them now
      const curRoom = roomMap[participant.curRoomHashId];
      if (curRoom && curRoom.participantIds.indexOf(participant.id) == -1) {
        curRoom.participantIds.push(participant.id);
        roomsUpdated = true;
      }
    });

    // Check if the current user is in a breakout room
    const breakoutRoomHashId = allParticipants[core.currentUser.id]?.breakoutRoomHashId;
    const isBreakoutRoom = breakoutRoomHashId === core.invitation.hashedID;

    const curParticipantsList = Object.values(curParticipants);
    core.isSomeonePresent = curParticipantsList
      .filter((x) => x.id !== core.currentUser?.id)
      .some((x) => x.location === "session" || (x.dailySessionID && !useStore.getState().inPerson));

    playSoundJoinedOrLeft(core, curParticipants);
    notifyParticipantsInWaitingRoom(core, curParticipants);

    const newRooms = Object.values(roomMap);
    if (roomsUpdated) {
      newRooms.sort((roomA, roomB) => roomB.updatedAt.localeCompare(roomA.updatedAt));
    }

    const totalRecentHeat: Heat = {};
    curParticipantsList.forEach((p) => {
      Object.keys(p.recentHeat).forEach((heatType) => {
        const recentHeat = p.recentHeat[heatType];
        const oldTotalHeat = totalRecentHeat[heatType] || 0;
        totalRecentHeat[heatType] = oldTotalHeat + recentHeat;
      });
    });
    const avgRecentHeat: Heat = {};
    Object.keys(totalRecentHeat).forEach((heatType) => {
      avgRecentHeat[heatType] = Math.floor(totalRecentHeat[heatType] / curParticipantsList.length);
    });

    useStore.setState({
      allParticipants: allParticipants,
      participants: curParticipants,
      isBreakoutRoom: isBreakoutRoom,
      rooms: roomsUpdated ? newRooms : oldRooms,
      recentHeat: avgRecentHeat,
    });

    // Update the server or local mode/location if one is out of sync from the other
    const curUserServerLocation = allParticipants[core.currentUser.id].location;
    const curUserLocalMode = useStore.getState().mode;
    if (curUserLocalMode !== "prejoin" && curUserServerLocation === "waitingRoom") {
      useStore.setState({ mode: "waitingRoom" });
    } else if (curUserLocalMode === "session" && curUserServerLocation !== "session") {
      this.subscription?.perform("update_location", { location: "session" });
    } else if (curUserLocalMode === "waitingRoom" && curUserServerLocation === "session") {
      useStore.setState({ mode: "session" });
    }

    // Update the current user if their name or authorizations changed
    const currentUser = useStore.getState().currentUser;
    const oldAuthorizations = currentUser.authorizations;
    const currentParticipant = allParticipants[currentUser.id];
    const newAuthorizations = currentParticipant.authorizations;
    oldAuthorizations.sort();
    newAuthorizations.sort();
    let updated = oldAuthorizations.length != newAuthorizations.length || currentUser.name != currentParticipant.name;
    if (!updated) {
      oldAuthorizations.forEach((oldAuthorization, idx) => {
        if (oldAuthorization != newAuthorizations[idx]) updated = true;
      });
    }
    if (updated) {
      const updatedUser = Object.assign({}, currentUser);
      updatedUser.authorizations = newAuthorizations;
      updatedUser.name = currentParticipant.name;
      useStore.setState({ currentUser: updatedUser });
    }
  }

  // Find the latest room that completed their session and use them as the basis for showing the analytics
  function updateBreakoutRooms(rooms: BreakoutRoomMessage[]) {
    rooms.sort((roomA, roomB) => roomB.updatedAt.localeCompare(roomA.updatedAt));

    const analyticsRooms = rooms.filter((room) => room.showAnalytics && room.status === "completed");

    useStore.setState((state) => {
      const reportHashId = core.invitation.lobbyReportHashId;
      const latestAnalyticsRoom = analyticsRooms[0];
      const analyticsInstanceHashId = latestAnalyticsRoom?.sequenceInstanceHashId;
      const cacheKey = latestAnalyticsRoom?.updatedAt;
      const isLobby = state.invitation.lobby;
      const newExternalContentUrl =
        !isLobby || !analyticsInstanceHashId
          ? null
          : core.buildExternalContentUrl(reportHashId, analyticsInstanceHashId, cacheKey);

      let roomsUpdated = false;

      if (state.rooms?.length == rooms.length) {
        for (let i = 0; i < rooms.length; i++) {
          const newRoom = rooms[i];
          const oldRoom = state.rooms[i];
          if (newRoom.hashId != oldRoom.hashId || newRoom.updatedAt != oldRoom.updatedAt) {
            roomsUpdated = true;
            break;
          }
        }
      } else {
        roomsUpdated = true;
      }

      const existingExternalContentUrl = state.externalContentUrl;
      const showExternalContent = state.showExternalContent;

      const newShowExternalContent: boolean =
        isLobby &&
        // Either keep showing analytics or show it now that a new url is available
        (showExternalContent ||
          (existingExternalContentUrl != newExternalContentUrl &&
            analyticsInstanceHashId !== undefined &&
            // Only start showing analytics if the current user participated in the latest session
            rooms.find(
              (r) =>
                r.sequenceInstanceHashId == analyticsInstanceHashId && r.participantIds.includes(core.currentUser.id),
            ) != null));

      // ----------- START: Find the newly unlocked flows -----------

      // Check if there were active rooms that have now completed
      let allRoomsNowCompleted = false;
      const newestInstanceId = rooms.find((r) => r.status == "completed")?.sequenceInstanceHashId;
      if (roomsUpdated && newestInstanceId) {
        const allParticipants = state.allParticipants;
        const activeRooms = rooms.filter((r) =>
          r.participantIds.find((p) => {
            const participant = allParticipants[p];

            if (!participant) {
              console.log(`[RoomsPage] Expected a room participant record. participantId: ${p}`, r);
            }
            return (
              r.hashId != state.invitation.hashedID &&
              participant &&
              participant.location == "session" &&
              participant.curRoomHashId == r.hashId
            );
          }),
        );

        allRoomsNowCompleted = activeRooms.length === 0;
      }

      let newlyUnlockedFlowHashIds: string[] | undefined;
      if (allRoomsNowCompleted && state.mode == "session" && state.invitation.lobby) {
        const newlyCompletedRooms = rooms.filter(
          (r) => r.sequenceInstanceHashId == newestInstanceId && r.status == "completed",
        );
        // Find the total heat received by the group members
        let totalHeatReceived = 0;
        const heatType = state.invitation.heatType;
        for (const r of newlyCompletedRooms) {
          totalHeatReceived += r.heatReceived[heatType] * r.participantIds.length;
        }

        // Calculate the previous heat before these sessions
        const numLobbyParticipants = Object.keys(state.participants).length;
        const curRecentHeat = state.recentHeat[heatType];
        const currentTotalRecentHeat = curRecentHeat * numLobbyParticipants;
        const prevTotalRecentHeat = currentTotalRecentHeat - totalHeatReceived;
        const prevRecentHeat = prevTotalRecentHeat / numLobbyParticipants;

        // Find the flows that were locked and are now unlocked
        const prevLockedFlows = state.invitation.lobbyInvitations.filter(
          (f) => f.minHeat > 0 && f.minHeat > prevRecentHeat,
        );

        const newlyUnlockedFlows = prevLockedFlows.filter((f) => f.minHeat <= curRecentHeat);
        const numUnlockedFlows = newlyUnlockedFlows.length;

        if (numUnlockedFlows > 0) {
          const unlockedFlowNames = newlyUnlockedFlows.map((f) => f.name).join(", ");

          toast(`Flows unlocked: ${unlockedFlowNames}`);
          newlyUnlockedFlowHashIds = newlyUnlockedFlows.map((f) => f.hashId);
        }
      }

      // ----------- END: Find the newly unlocked flows -----------

      return {
        // If no room was updated, then don't update the rooms state to avoid reloading analytics.
        rooms: roomsUpdated ? rooms : state.rooms,
        externalContentUrl: newExternalContentUrl || existingExternalContentUrl,
        showExternalContent: newShowExternalContent,
        newlyUnlockedFlowHashIds: newlyUnlockedFlowHashIds ? newlyUnlockedFlowHashIds : state.newlyUnlockedFlowHashIds,
      };
    });
  }

  if (isBreakoutRoomsMessage(u)) {
    updateBreakoutRooms(u.rooms);
  } else if (isBreakoutRoomMessage(u)) {
    const oldRooms = useStore.getState().rooms;
    const newRooms = oldRooms.filter((room) => room.hashId != u.hashId);
    newRooms.push(u);
    updateBreakoutRooms(newRooms);
  }

  if (isChatMessage(u)) {
    if (u.chat.user_id === this.currentUser.id || useStore.getState().showChat) {
      useStore.getState().addChatMessage({ ...u.chat, read: true });
    } else {
      useStore.getState().addChatMessage(u.chat);

      const chatMessage = u.chat.message;
      const toastMessage = chatMessage.length < 50 ? chatMessage : chatMessage.substring(0, 50) + "...";
      toast(`${u.chat.name}: ${toastMessage}`);
    }
  }

  if (isUpdateMessage(u)) {
    // If we're on a new step, reset the iframeVisible state.
    const currentStep = useStore.getState().currentStep;
    if (
      (currentStep && currentStep.id !== u.current_step.id) ||
      useStore.getState().roleData[core.currentUser.id]?.role !== u.role_data[core.currentUser.id]?.role
    ) {
      // Keep showing the iframe unless the step changed, or keep hiding it if it was already hidden
      const iframeVisible = useStore.getState().iframeVisible && currentStep?.id === u?.current_step?.id;
      useStore.setState({ iframeVisible: iframeVisible });
    }

    const invitationLocale = core.invitation.locales.find((locale) => locale == core.currentUser.locale);

    const locale = (invitationLocale || core.locale) as Locales;
    const stepContent = determineContent(
      u.current_step,
      u.user_responses,
      u.step_variables,
      u.role_data,
      core.currentUser.id,
      core.intl,
      locale,
      core.invitation.flowData,
      core.invitation.hashedID,
    );

    const narrating = u.current_step_data[SYSTEM_USER_ID]?.narrating === true;

    let mediaObject = useStore.getState().currentAudioVideoObject;

    if (isContentStep(u.current_step) && u.current_step.audioFile) {
      console.debug(`found step content for locale: ${locale}, content: ${stepContent?.text}`, stepContent);

      const audioFileA = u.current_step.audioFile[this.locale]?.url || u.current_step.audioFile["en"]?.url || "";
      const audioFileB = audioFileA + "?type=.mp4";
      const flowAudioVideo = useStore.getState().flowAudioVideo;

      const flowMediaFile = flowAudioVideo[audioFileA] || flowAudioVideo[audioFileB];
      if (!flowMediaFile) {
        if (mediaObject) {
          mediaObject.pause();
          mediaObject = undefined;
        }
      } else {
        mediaObject = flowMediaFile;
      }
    } else {
      if (mediaObject) {
        mediaObject.pause();
        mediaObject = undefined;
      }
    }

    // Calculate how long we've been on this step already
    const systemStepData = u.current_step_data["-1"];

    // If the local time is more than 5 seconds off from the server time, then use the server
    // time because the local machine time is probably out of sync with the actual time
    const serverNow = new Date(u.now + "+00:00").getTime();
    const localNow = new Date().getTime();
    const now = Math.abs(serverNow - localNow) > 5000 ? serverNow : localNow;

    const startTime = new Date(systemStepData.startTime + "+00:00").getTime();
    const endTime = new Date(systemStepData.endTime + "+00:00").getTime();
    const timeOnStep = systemStepData ? (now - startTime) / 1000 : useStore.getState().timeOnStep;
    const stepTiming = Math.round((endTime - startTime) / 1000);

    useStore.setState((state) => ({
      audioProgress: u.audio_progress,
      currentStep: u.current_step,
      currentAudioVideoObject: mediaObject,
      roleData: u.role_data,
      stepContent: stepContent,
      stepData: u.current_step_data,
      lastStep: u.last_step,
      showAddPartner: state.showAddPartner || (u.step_index == 0 && u.flow_index == 0),
      personalCode: u.personal_code,
      narrating: narrating,
      stepTiming: stepTiming,

      // Only update timeOnStep if the difference between the derived step time and the counted time is more than 1 sec
      timeOnStep: Math.abs(state.timeOnStep - timeOnStep) < 1 ? state.timeOnStep : Math.round(timeOnStep),
    }));

    // If this user has been assigned a new invitation, send them there now
    const newHashedInvitationID = u.invitation_assignment && u.invitation_assignment[this.currentUser.id];
    if (newHashedInvitationID != this.invitation.hashedID) {
      console.debug("[session-ui]: invitationAssignment: ", u.invitation_assignment);

      const joinPromise = this.joinNewInvitation(newHashedInvitationID, u.invitation);

      if (u.presencesMessage) {
        onMessage.bind(core)(u.presencesMessage);
      } else {
        console.log("Expected a presencesMessage with new invitation assignments! state update message:", u);
      }

      await joinPromise;
    }

    // If we just arrived in the lobby, handle any unprocessed heat update messages
    const unprocessedHeatUpdateMessage = useStore.getState().unprocessedHeatUpdates;
    if (u.invitation?.lobby && Object.keys(unprocessedHeatUpdateMessage.participantHeatUpdates).length > 0) {
      useStore.setState({ unprocessedHeatUpdates: { participantHeatUpdates: {} } });
      onMessage.bind(core)(unprocessedHeatUpdateMessage);
    }
  }

  if (isDropUserCommand(u)) {
    // The actual removing of the user is handled by the presence message
    toast(`${u.user_name} dropped ${u.dropped_user_name}`);
  }

  if (isReloadCommand(u)) {
    if (u.in_person && !useStore.getState().inPerson) {
      // Switch from digital to physical
      await this.callObject?.leave();
      useStore.setState({ inPerson: true });
      this.renderApp();
    }

    // Switch from physical to digital
    if (!u.in_person && useStore.getState().inPerson) {
      // The invitation should already be set to digital on the backend, and reloading will take care of everything
      window.location.reload();
    }
  }

  if (isMuteAllMessage(u)) {
    if (u.muterId !== this.currentUser.id) {
      setMicEnabledTo(core.callObject, !u.mute);
    }
    const toggledStr = u.mute ? "muted" : "unmuted";

    toast(`${u.mutedBy} ${toggledStr} all participants.`);
  }

  if (isMuteParticipantMessage(u)) {
    const mutedParticipantName = useStore.getState().allParticipants[u.participantId]?.name;

    if (u.participantId === this.currentUser.id) {
      setMicEnabledTo(core.callObject, !u.mute);
    }

    const toggledStr = u.mute ? "muted" : "unmuted";

    if (mutedParticipantName) {
      toast(`${u.mutedBy} ${toggledStr} ${mutedParticipantName}.`);
    } else {
      toast(`${u.mutedBy} ${toggledStr} a participant.`);
    }
  }

  if (isAudioCommand(u)) {
    console.debug("[onMessage]: audio command received", u.audio_command, u.user_name, u.time, u.skip_toast);
    onAudioMessage.bind(this)(u);
  }

  if (isRoomClosingMessage(u)) {
    const state = useStore.getState();
    const oldEndDate = state.sessionEndDate;
    const oldClosingType = state.sessionClosingType;

    // Only show an update if
    // a) there is no old end time
    // b) the new and old end times are more than a second apart.
    // c) it's a different type of closing message
    if (
      !oldEndDate ||
      oldClosingType != u.closingType ||
      u.closingType == "estimate" ||
      new Date(u.closingTime + "+00:00").getTime() + 1000 < oldEndDate.getTime()
    ) {
      const newEndDate = new Date(u.closingTime + "+00:00");
      console.debug(
        `[onMessage] transitioning room at: ${newEndDate}, curTime: ${new Date()}, transitionType: ${u.closingType}`,
      );

      // Disable these messages for now since we're auto-advancing steps and people don't need to manage their own time anymore
      // const msUntilEnd = newEndDate.getTime() - new Date().getTime();
      // const secondsRemaining = Math.trunc(msUntilEnd / 1000);
      // const minutesRemaining = Math.trunc(secondsRemaining / 60);
      // let closingMessage: string;
      // switch (u.closingType) {
      //   case "feedback":
      //     // We're not doing extra time for feedback anymore
      //     // closingMessage = "Ending with feedback";
      //     closingMessage = "Session ending";
      //     break;
      //   case "lobby":
      //     closingMessage = "Returning to lobby";
      //     break;
      //   case "closing":
      //     closingMessage = "Session ending";
      //     break;
      // }
      //
      // if (minutesRemaining > 5) {
      //   toast(`${closingMessage} in ${minutesRemaining} minutes`);
      // } else if (secondsRemaining <= 90) {
      //   toast(`${closingMessage} in ${secondsRemaining} seconds`);
      // } else {
      //   const secondsPosition = secondsRemaining % 60;
      //   const secondsString = secondsPosition >= 10 ? secondsPosition : `0${secondsPosition}`;
      //   toast(`Session is ending in ${minutesRemaining}:${secondsString} minutes`);
      // }
      useStore.setState({
        sessionEndDate: newEndDate,
        sessionClosingType: u.closingType,
      });
    }
  }

  if (isToggleHandMessage(u)) {
    const state = useStore.getState();

    let newRaisedHands: number[] | null = null;
    let actionName: string | null = null;

    const toggledByParticipant = state.allParticipants[u.toggledByParticipantId];
    const toggledParticipant = state.allParticipants[u.participantId];

    const newParticipantsLastActiveDates = Object.assign({}, useStore.getState().participantsLastActiveDates);
    const newParticipantsLastActiveDatesSpeaker = Object.assign(
      {},
      useStore.getState().participantsLastActiveDatesSpeaker,
    );

    if (u.action === "raise_hand" && !state.raisedHands.includes(u.participantId)) {
      newRaisedHands = [...state.raisedHands];
      newRaisedHands.push(u.participantId);
      actionName = "raised";

      newParticipantsLastActiveDates[toggledParticipant.id] = new Date();

      // For speaker view, don't let raised hands go ahead of the speaker
      const times = Object.values(newParticipantsLastActiveDatesSpeaker).map((i) => i.getTime());
      const maxTime = Math.max(...times);
      const secondMaxTime = Math.max(...times.filter((t) => t != maxTime));
      const newMaxTime = secondMaxTime ? (maxTime + secondMaxTime) / 2 : maxTime - 1000;
      console.log(`raised hand maxTime:  ${maxTime}, newMaxTime: ${newMaxTime}`);
      newParticipantsLastActiveDatesSpeaker[toggledParticipant.id] = new Date(newMaxTime);
    } else if (u.action === "lower_hand" && state.raisedHands.includes(u.participantId)) {
      newRaisedHands = state.raisedHands.filter((participantId) => participantId != u.participantId);
      actionName = "lowered";
    }

    if (actionName) {
      if (u.toggledByParticipantId != u.participantId) {
        toast(`${toggledByParticipant.name} ${actionName} ${toggledParticipant.name}'s hand`);
      } else {
        toast(`${toggledParticipant.name} ${actionName}  their hand`);
      }

      useStore.setState({
        participantsLastActiveDates: newParticipantsLastActiveDates,
        participantsLastActiveDatesSpeaker: newParticipantsLastActiveDatesSpeaker,
        raisedHands: newRaisedHands!,
      });
    }
  }

  if (isToggleRecordingMessage(u)) {
    const state = useStore.getState();

    let currentActionName: string = "recording & transcribing";
    if (state.recording && state.transcribing) {
      currentActionName = "recording & transcribing";
    } else if (state.recording) {
      currentActionName = "recording";
    } else if (state.transcribing) {
      currentActionName = "transcribing";
    }

    let actionMessage: string | null = null;
    switch (u.action) {
      case "start_recording":
        actionMessage = "started recording";
        break;
      case "stop_recording":
        actionMessage = "stopped " + currentActionName;
        break;
      case "start_transcription":
        actionMessage = "started transcription (closed captions available)";
        break;
      case "start_recording_and_transcription":
        actionMessage = "started recording & transcription";
        break;
    }
    const toggledByName = u.toggledByParticipantId == core.currentUser.id ? "You" : u.toggledByParticipantName;

    if (u.errorMessage) {
      toast(u.errorMessage);
      useStore.setState({
        recordingLoading: false,
      });
    } else {
      toast(toggledByName + " " + actionMessage);
      useStore.setState({
        recording: u.action == "start_recording" || u.action == "start_recording_and_transcription",
        transcribing: u.action == "start_transcription" || u.action == "start_recording_and_transcription",
        recordingLoading: false,
      });

      SFX2.playAudio("recordingStarted", 0.1);
    }
  }

  if (isToastMessage(u)) {
    toast(u.toastMessage);
  }

  if (isInvitationUpdate(u)) {
    const updatedByName = u.updatingParticipantId == core.currentUser.id ? "You" : u.updatingParticipantName;

    const newInvitation = Object.assign({}, useStore.getState().invitation);
    Object.assign(newInvitation, u.update);
    useStore.setState({
      invitation: newInvitation,
    });

    toast(`${updatedByName} ${u.invitationUpdateMessage}`);
  }

  if (isInvitationResponse(u)) {
    const invitationResolver = core.invitationResolver;
    if (invitationResolver) invitationResolver(u);
  }

  const invitation = useStore.getState().invitation;
  if (isHeatUpdates(u)) {
    if (invitation.lobby) {
      Object.values(u.participantHeatUpdates).forEach((heatUpdate) => {
        heatToast(heatUpdate.participantId, heatUpdate.heatGained);
        if (this.currentUser.id === heatUpdate.participantId && heatUpdate.level > heatUpdate.previousLevel) {
          levelUpToast(heatUpdate);
        }
      });
    }
    // We can only show the heat updates in the lobby. So hold onto this until we get there
    else {
      console.debug("received heat update but can't process it yet", u);
      const allParticipantHeatUpdates = Object.assign(
        {},
        useStore.getState().unprocessedHeatUpdates.participantHeatUpdates,
      );

      for (const heatUpdate of Object.values(u.participantHeatUpdates)) {
        allParticipantHeatUpdates[heatUpdate.participantId] = heatUpdate;
      }

      useStore.setState({ unprocessedHeatUpdates: { participantHeatUpdates: allParticipantHeatUpdates } });
    }
  }

  if (isForceLeaveMessage(u)) {
    toast(`${u.droppedByFacilitator} has removed you from the session`);
    endCall();
  }
};

function playSoundJoinedOrLeft(core: Core, newParticipants: Participants) {
  // Play a partnerArrived/partnerDisconnected if a participant joined or left
  const oldParticipants = useStore.getState().participants;
  const oldParticipantsList: Participant[] = Object.values(oldParticipants).filter(
    (p) => p.location == "session" && p.id != core.currentUser.id,
  );

  // Only play a sound if there are at least 3 participants present and if we're over video (not in person)
  if (oldParticipantsList.length <= 3 && !core.invitation.inPerson) {
    console.debug("participant sound: checking if someone joined or left");
    const newParticipantsList: Participant[] = Object.values(newParticipants).filter(
      (p) => p.location == "session" && p.id != core.currentUser.id,
    );
    let participantJoined = false;
    for (const newParticipant of newParticipantsList) {
      const oldParticipant = oldParticipants[newParticipant.id];
      if (!oldParticipant || oldParticipant.location != "session") {
        participantJoined = true;
      }
    }
    if (participantJoined) {
      SFX2.playAudio("partnerArrived", 1);
    } else {
      for (const oldParticipant of oldParticipantsList) {
        const newParticipant = newParticipants[oldParticipant.id];
        if (!newParticipant || newParticipant.location != "session") {
          SFX2.playAudio("partnerDisconnected", 1);
          break;
        }
      }
    }
  }
}

function notifyParticipantsInWaitingRoom(core: Core, newParticipants: Participants) {
  const oldParticipants = useStore.getState().participants;
  const currentUser = core.currentUser;

  const currentUserJustArrived =
    oldParticipants[currentUser.id]?.location != "session" && newParticipants[currentUser.id]?.location == "session";

  // Don't continue if any
  // 1. we're in person and not over video
  // 2. The waiting room isn't enabled
  // 3. We're not in the session
  // 4. We're not allowed to manage the waiting room
  if (
    core.invitation.inPerson ||
    !core.invitation.waitingRoomEnabled ||
    (useStore.getState().mode != "session" && !currentUserJustArrived) ||
    !can("manageParticipants", currentUser, core.invitation)
  ) {
    return;
  }

  console.debug(`currentUserJustArrived: ${currentUserJustArrived}`);

  const newParticipantsList = Object.values(newParticipants);
  const newWaitingRoomParticipants: Participant[] = newParticipantsList.filter(
    (p) =>
      p.location == "waitingRoom" &&
      p.id != currentUser.id &&
      (currentUserJustArrived || oldParticipants[p.id]?.location != "waitingRoom"),
  );

  const newReadyRoomParticipants: Participant[] = newParticipantsList.filter(
    (p) => p.location == "ready-room" && p.id != currentUser.id && oldParticipants[p.id]?.location != "ready-room",
  );

  function sendWaitingRoomToast() {
    const p = newWaitingRoomParticipants[0];
    const numOthers = newWaitingRoomParticipants.length - 1;
    const andOthers = numOthers > 0 ? ` and ${numOthers} others` : "";
    const are = numOthers > 0 ? "are" : "is";
    const message = currentUserJustArrived ? `${are} in the waiting room` : "entered the waiting room";

    const toast: Toast = createToast({
      duration: 8000,
      clickClosable: true,
      maxVisibleToasts: 3,
      render: () => (
        <div>
          {`${p.name}${andOthers} ${message}`}
          <a
            style={{
              margin: "10px",
              borderRadius: "10px",
              background: "linear-gradient(90deg, #ff622e 27.21%, #ff8d24 122.48%)",
              padding: "4px 9px 3px",
              fontSize: "13px",
              color: "#fff",
              fontWeight: "bold",
            }}
            onClick={() => (andOthers ? admitAll() : admitParticipant(p.id, () => toast.close()))}
          >
            {andOthers ? "Admit All" : "Admit"}
          </a>
        </div>
      ),
    })("");
  }

  if (newWaitingRoomParticipants.length >= 1) {
    if (currentUserJustArrived) {
      setTimeout(sendWaitingRoomToast, 2000);
    } else {
      sendWaitingRoomToast();
    }

    // Play the waiting room sound
    SFX2.playAudio("waitingRoomArrived", 0.2);
  }

  if (newReadyRoomParticipants.length >= 1) {
    const p = newReadyRoomParticipants[0];
    const numOthers = newReadyRoomParticipants.length - 1;
    const andOthers = numOthers > 0 ? ` and ${numOthers} others` : "";
    toast(`${p.name}${andOthers} getting ready`);
  }
}
