import DailyIframe, {
  DailyEventObjectParticipant,
  DailyEventObjectParticipantLeft,
  DailyEventObjectActiveSpeakerChange,
  DailyEventObjectAppMessage,
  DailyCall,
} from "@daily-co/daily-js";
import getToken from "../../lib/requests/getToken";
import toast, { createToast } from "react-simple-toasts";
import React from "react";
import { useStore } from "../store";
import { isMobile } from "../../components/UI/Music/Music";
import { BLUR_LEVEL } from "../../lib/defaults";

export const setupCallObject = async (
  invitation: InvitationResponse,
  clientID: string,
  userName: string,
  onParticipantJoined: (event?: DailyEventObjectParticipant) => void,
  onParticipantLeft: (event?: DailyEventObjectParticipantLeft) => void,
  onActiveSpeakerChange: (event?: DailyEventObjectActiveSpeakerChange) => void,
  transitioning: boolean,
) => {
  console.debug("[session-ui]: Setting up call object");

  const dailyTokenResponse = await getToken(invitation.hashedID, clientID);
  const url = `https://warmspace.daily.co/${invitation.roomName}`;

  let callObject = DailyIframe.getCallInstance();

  if (callObject) {
    console.debug("[session-ui]: Daily iframe already exists, leaving meeting.");
    await callObject.leave();
  }

  if (!callObject) {
    callObject = DailyIframe.createCallObject({ strictMode: true });

    callObject.on("participant-joined", onParticipantJoined);
    callObject.on("participant-left", onParticipantLeft);
    callObject.on("active-speaker-change", onActiveSpeakerChange);

    callObject.on("call-instance-destroyed", (e) => {
      console.log("callInstanceDestroyed Event", e);
    });

    callObject.on("error", (e) => {
      console.log("callObjectError Event", e);
    });

    // Enable closed captioning
    callObject.on("app-message", (msg: DailyEventObjectAppMessage | undefined) => {
      const data = msg?.data;
      if (msg?.fromId === "transcription" && data?.is_final && useStore.getState().closedCaptionsEnabled) {
        const local = callObject!.participants().local;
        const name: string =
          local.session_id === data.session_id
            ? local.user_name
            : callObject!.participants()[data.session_id].user_name;
        const text: string = data.text;
        const timestamp: string = data.timestamp;

        if (name.length && text.length && timestamp.length) {
          createToast({
            duration: 4000,
            clickClosable: true,
            maxVisibleToasts: 3,
            render: () => <>{`${name}: ${text}`}</>,
          })("");
        }
      }
    });
  }

  await callObject.preAuth({
    token: dailyTokenResponse.token,
    url: url,
    dailyConfig: {
      enableIndependentDevicePermissionPrompts: invitation.muteAll,
    },
    userName: userName.toString() || "",
  });

  callObject.on("camera-error", (e) => {
    console.log("cameraError Event", e);
    toast("Unable to start camera: " + e.errorMsg.errorMsg, {
      duration: 1500,
    });
  });

  if (!transitioning) {
    setMicEnabledTo(callObject, false);
    setCameraEnabledTo(callObject, false);
  }

  const devices = await navigator.mediaDevices.enumerateDevices();

  const previousCamera = localStorage.getItem("warmspace.cameraDeviceId");
  const previousMic = localStorage.getItem("warmspace.microphoneDeviceId");
  const previousSpeaker = localStorage.getItem("warmspace.speakerDeviceId");
  const blurEnabled = localStorage.getItem("warmspace.blurEnabled");
  const showSelf = localStorage.getItem("warmspace.showSelf");
  const cancelNoise = localStorage.getItem("warmspace.cancelNoise");

  // Set the previous output/input devices. Wait a second or two in case the call object isn't ready yet at this point
  const waitTimeForCallObject = (callObject ? 1000 : 2000) * (isMobile() ? 2 : 1);

  if (showSelf) useStore.setState({ showSelf: true });
  setTimeout(() => {
    if (previousCamera) callObject.setInputDevicesAsync({ videoDeviceId: previousCamera });
    if (previousMic) callObject.setInputDevicesAsync({ audioDeviceId: previousMic });
    if (previousSpeaker) callObject.setOutputDeviceAsync({ outputDeviceId: previousSpeaker });
    if (blurEnabled) {
      callObject.updateInputSettings({
        video: {
          processor: {
            type: "background-blur",
            config: { strength: BLUR_LEVEL },
          },
        },
      });
    }
    if (cancelNoise) {
      callObject.updateInputSettings({
        audio: {
          processor: {
            type: "noise-cancellation",
          },
        },
      });
    }
  }, waitTimeForCallObject);

  if (!transitioning) {
    devices.forEach((device) => {
      if (device.kind === "videoinput" && device.deviceId) {
        setMicEnabledTo(callObject, true);
        setCameraEnabledTo(callObject, true);
      }
    });
  }

  await callObject.startLocalAudioLevelObserver(500);
  await callObject.startRemoteParticipantsAudioLevelObserver(500);

  return callObject;
};

export const setMicEnabledTo = (callObject: DailyCall | null | undefined, enabled: boolean) => {
  try {
    callObject?.setLocalAudio(enabled);
  } catch (e) {
    console.log(`Problem setting mic to: ${enabled}`, e);
  }
};

export const setCameraEnabledTo = (callObject: DailyCall | null, enabled: boolean) => {
  try {
    callObject?.setLocalVideo(enabled);
  } catch (e) {
    console.log(`Problem setting camera to: ${enabled}`, e);
  }
};
