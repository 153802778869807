import styled from "styled-components";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Spinner } from "../../../assets/icons/Icons";

export const TileNoVideoWrapper = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;

  button {
    font-size: 14px;
    padding: 5px 15px;
  }

  span {
    max-width: 260px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const locationToMessage = (location: Participant["location"]) => {
  switch (location) {
    case "offline":
      return <FormattedMessage id="tile_no_video.disconnected" defaultMessage="Disconnected" />;

    case "ready-room":
      return <FormattedMessage id="tile_no_video.getting_ready" defaultMessage="Getting ready" />;

    case "session":
      return "";

    default:
      return "";
  }
};
const TileNoVideo = ({
  participantLocation,
  name,
  isLocal,
}: {
  participantLocation: Participant["location"];
  name: string;
  isLocal: boolean;
}) => {
  return (
    <TileNoVideoWrapper>
      {isLocal ? <FormattedMessage id="tile_no_video.username_you" defaultMessage="You" /> : <span>{name}</span>}
      {participantLocation !== "session" && (
        <>
          <div>
            <FormattedMessage
              id="tile_no_video.location"
              defaultMessage="{locationMessage}"
              values={{
                locationMessage: locationToMessage(participantLocation),
              }}
            />
          </div>

          {participantLocation === "ready-room" && (
            <div className="spinner">
              &#32;
              <Spinner />
            </div>
          )}
        </>
      )}
    </TileNoVideoWrapper>
  );
};

export default TileNoVideo;
