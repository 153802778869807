import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../ButtonV2/Button";
import { Overlay, Card, CardFooter, CardBody } from "../Modal/ModalStyle";
import { store } from "../../../session/store";
import { modalClear } from "../Modal/Modals";
import styled from "styled-components";
import { doAction } from "../../../session/SessionChannel/doAction";
import { Leave } from "../../../assets/icons/Icons";

const ExitCard = styled(Card)`
  top: 40px;
  left: unset;
  right: unset;
  right: 6px;
  padding: 0px;
  width: 200px;
  transform: translate(0%, 0%);
  z-index: 1000;
  color: #fff;
  border-top: 1px solid #fff;
  background-color: #ddd;

  animation: slideIn 0.3s;

  @keyframes slideIn {
    0% {
      opacity: 0;
      top: 30px;
    }
    100% {
      opacity: 1;
      top: 40px;
    }
  }

  ${CardBody} {
    padding: 0px;
  }

  ${CardFooter} {
    padding: 0px;
  }

  ${Button} {
    border-radius: 4px;
    margin: 0px;
    font-size: 18px;
    text-align: left !important;
    justify-content: space-between;
    display: flex;
    padding: 10px 17px;

    &:hover {
      transform: scale(1);
      background-color: #eee;
    }

    &.secondary {
      background-color: transparent;
      opacity: unset;
      box-shadow: none;
      color: #222;
      width: 100%;
      z-index: 100;

      &:hover {
        background-color: #eee;
      }
    }
  }
`;

export function endCall() {
  store.setState((state) => {
    state.core.callObject?.leave();
    state.core.subscription?.unsubscribe();
    return {
      mode: "end",
    };
  });
}

export const ConfirmLeaveModal: FC<{ inRoom: boolean }> = ({ inRoom }) => {
  const core = store.use.core();

  const handleCloseModal = () => {
    modalClear("ConfirmLeave");
  };

  return (
    <>
      <ExitCard>
        {inRoom ? (
          <Button
            className="secondary small"
            onClick={() => {
              doAction(core, "end_session", -1, -1);
              modalClear("ConfirmLeave");
            }}
          >
            <FormattedMessage id="confirm_leave_modal.return_to_lobby" defaultMessage="Return to Lobby" />
          </Button>
        ) : null}

        <Button
          className="secondary small"
          onClick={() => {
            endCall();
            modalClear("ConfirmLeave");
          }}
        >
          <FormattedMessage id="confirm_leave_modal.leave_call" defaultMessage="Leave Call" />
          <Leave fill="black" />
        </Button>
      </ExitCard>
      <Overlay onClick={handleCloseModal} $blur={true} />
    </>
  );
};
