import styled from "styled-components";

import React, { useEffect } from "react";
import { More } from "../../../assets/icons/Icons";
import MoreMenu from "./MoreMenu";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { store } from "../../../session/store";
import { modalClear, modalShow } from "../Modal/Modals";
const MoreModal = styled.div`
  position: fixed;
  bottom: 30px;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0 0 4px 0px #00000026;
  left: 0;
  padding: 0 12px 12px;
  transition: all 0.4s ease;
  opacity: 0;
  z-index: 10;
  pointer-events: none;

  &.show {
    position: fixed;
    bottom: 50px;
    z-index: 10;
    opacity: 1;
    pointer-events: all;
  }

  .overlay {
    background-color: #000000b2;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: fixed;
    display: none;
    z-index: 5;
    width: 100%;
  }

  .overlay.show {
    display: block;
  }
`;

export const MoreButton = () => {
  const inPerson = store.use.inPerson();
  const showMoreMenu = store.use.showModals().includes("MoreMenu");
  const [delayedShowMoreMenu, setDelayedShowMoreMenu] = React.useState(false);

  // Delay showMoreMenu so it can complete it's animation
  useEffect(() => {
    if (!showMoreMenu) {
      setTimeout(() => {
        setDelayedShowMoreMenu(false);
      }, 400);
    } else {
      setDelayedShowMoreMenu(true);
    }
  }, [showMoreMenu]);

  return (
    <>
      <MenuButton
        buttonIcon={<More fill="white" />}
        onClick={() => {
          modalShow("MoreMenu");
        }}
      >
        {!inPerson && <FormattedMessage id="more_button.more" defaultMessage={"More"} />}
        {inPerson && <FormattedMessage id="more_button.menu" defaultMessage={"Menu"} />}
      </MenuButton>
      <MoreModal className={`modal ${showMoreMenu ? "show" : ""}`}>
        {/*
          We need to actually remove it from the page so
          keyboard tabbing does not reach it. For better accessibility.
         */}
        {delayedShowMoreMenu && <MoreMenu />}{" "}
        <div
          className={`overlay ${showMoreMenu ? "show" : ""}`}
          onClick={() => {
            modalClear("MoreMenu");
          }}
        />
      </MoreModal>
    </>
  );
};
