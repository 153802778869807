import React from "react";
import { AnalyticsButtonIcon } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { store, useStore } from "../../../session/store";
import { modalClearAll } from "../Modal/Modals";

export const AnalyticsButton = () => {
  const externalContentUrl = store.use.externalContentUrl();
  const showExternalContent = store.use.showExternalContent();

  return externalContentUrl ? (
    <MenuButton
      buttonIcon={<AnalyticsButtonIcon stroke={showExternalContent ? "#FFA100" : "white"} />}
      onClick={() => {
        modalClearAll();
        useStore.setState({ showExternalContent: !showExternalContent, showChat: false });
      }}
      className={showExternalContent ? "selected" : ""}
    >
      <FormattedMessage id="analytics_button.label" defaultMessage={"Analytics"} />
    </MenuButton>
  ) : (
    <></>
  );
};
