import React, { FC, useEffect, useState } from "react";
import { NumberWidget } from "./NumberWidget";
import styled from "styled-components";
import { BarsWidget } from "./BarsWidget";
import WarmspaceWordCloud from "./WarmspaceWordCloud";
import { store } from "../../../session/store";
import getAnalytics from "../../../lib/requests/getAnalytics";

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  z-index: 2;
  width: 100%;
  position: relative;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 7px;
`;

const WordcloudWrapper = styled.div`
  border-radius: 10px;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
`;

const WordcloudInner = styled.div`
  border-top: #444 1px solid;
  border-bottom: #292929 1px solid;
  background: linear-gradient(180deg, #313131 0%, #242424 100%);
  border-radius: 8px;
  height: 100%;
  width: calc(100% - 0px);
  flex: 1;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SideWidgets = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  flex-shrink: 0;
  gap: 7px;
`;

const SurveyResults = styled(SideWidgets)`
  height: calc(100% - 8px);
`;

export const Widget = styled.div`
  background: linear-gradient(180deg, #313131 0%, #242424 100%);
  border-top: #444 1px solid;
  border-bottom: #292929 1px solid;
  border-radius: 10px;
  padding: 10px;

  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    z-index: 1;

    width: 100%;
  }

  .stat {
    z-index: 2;
    font-size: 55px;

    background: linear-gradient(#ff8d24, #ff612e);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    align-self: center;
    display: flex;

    font-optical-sizing: auto;
    font-weight: 600;
    line-height: 1em;
    font-style: normal;
  }

  span {
    color: #ff8d24;
  }
`;

const ChangePage = styled.div`
  position: absolute;
  color: #fff;
  font-weight: bold;
  background: linear-gradient(180deg, #666 0%, #555 100%);
  border-top: #444 1px solid;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer;
  opacity: 0.9;
  bottom: 0;

  &.next {
    right: 5px;
  }

  &.prev {
    left: 5px;
  }
`;

export interface WordCloudWord {
  text: string;
  count: number;
}

export interface AnalyticsResponse {
  words: WordCloudWord[];
  energyBefore: number;
  energyAfter: number;
  surveyResults: { [key: string]: number };
  sessions: number;
  people: number;
  heat: number;
  countries: string[];
}

const alphabeticalSort = (a: WordCloudWord, b: WordCloudWord) => {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  return 0;
};

interface Props {
  initialData?: AnalyticsResponse;
}

const AnalyticsWidgetPure: FC<Props> = ({ initialData }) => {
  const [data, setData] = useState<AnalyticsResponse>(
    initialData || {
      words: [],
      energyBefore: 0,
      energyAfter: 0,
      surveyResults: {},
      sessions: 0,
      people: 0,
      countries: [],
      heat: 0,
    },
  );

  const analyticsUrl = store.use.externalContentUrl()!;

  const [page, setPage] = useState(1);

  // Set up polling
  useEffect(() => {
    getAnalytics(analyticsUrl)
      .then((analyticsResponse) => {
        if (!analyticsResponse || !analyticsResponse.words) {
          console.log("No or invalid analytics data received", analyticsResponse, analyticsUrl);
          return;
        }

        setData(analyticsResponse);
      })
      .catch(() => {
        console.log("No or invalid analytics data received", analyticsUrl);
        return;
      });
  }, [analyticsUrl]);

  const hasEnergyBefore = data.energyBefore && !isNaN(data.energyBefore);
  const hasEnergyAfter = data.energyAfter && !isNaN(data.energyAfter);
  const surveyCategories = Object.keys(data.surveyResults);
  surveyCategories.sort();
  const hasSurveyResults = data.surveyResults && surveyCategories.length > 0;

  const pages = (hasEnergyBefore || hasEnergyAfter) && hasSurveyResults ? 2 : 1;
  const surveyBars = surveyCategories.map((key) => ({
    label: key,
    value: data.surveyResults[key],
  }));
  const energyBars = [
    { label: "Before", value: hasEnergyBefore ? data.energyBefore : undefined },
    { label: "After", value: hasEnergyAfter ? data.energyAfter : undefined },
  ];

  return (
    <Wrapper>
      <Body>
        {hasSurveyResults && page == 1 ? (
          <>
            {" "}
            <SurveyResults>
              <BarsWidget max={5} bars={surveyBars} fullHeight={true} labelWidth={"120px"} />
            </SurveyResults>
          </>
        ) : (
          <>
            <SideWidgets>
              <BarsWidget max={10} bars={energyBars} />
              {data.heat > 0 && <NumberWidget label="Warmth" value={data.heat || 0} key="heat" />}
            </SideWidgets>
            <WordcloudWrapper>
              <WordcloudInner>
                {data.words.length == 0 && <span>No word cloud data yet</span>}
                {data.words.length > 0 && <WarmspaceWordCloud words={data.words.sort(alphabeticalSort)} />}
              </WordcloudInner>
            </WordcloudWrapper>
          </>
        )}
      </Body>
      {page < pages && (
        <ChangePage className="next" onClick={() => setPage(2)}>
          Next &gt;
        </ChangePage>
      )}
      {page > 1 && (
        <ChangePage className="prev" onClick={() => setPage(1)}>
          &lt; Previous
        </ChangePage>
      )}
    </Wrapper>
  );
};

export const AnalyticsWidget = React.memo(AnalyticsWidgetPure);
