import React from "react";
import { ScreenShare } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import MenuButton from "./MenuButton";
import { useScreenShare } from "@daily-co/daily-react";
import DailyIframe from "@daily-co/daily-js";
import { store } from "../../../session/store";
import { selectScreens } from "../../../session/selectors/selectScreens";

export const ScreenShareButton = () => {
  const { isSharingScreen, startScreenShare, stopScreenShare, screens: allScreens } = useScreenShare();
  const browserInfo = DailyIframe.supportedBrowser();
  const participants = store.use.participants();
  const toggleScreenShare = () => (isSharingScreen ? stopScreenShare() : startScreenShare());

  const screens = selectScreens({ participants, allScreens });

  return browserInfo.supportsScreenShare && (screens.length == 0 || isSharingScreen) ? (
    <MenuButton
      buttonIcon={<ScreenShare stroke={isSharingScreen ? "#FFA100" : "white"} />}
      onClick={() => {
        toggleScreenShare();
      }}
    >
      <FormattedMessage id="screenshare_button" defaultMessage="Share" />
    </MenuButton>
  ) : (
    <></>
  );
};
