import React, { FC, memo, useEffect } from "react";
import styled from "styled-components";
import { store } from "../../../session/store";
import { getLevel } from "./getLevel";
import { getTitle } from "./getTitle";

interface Props {
  participant: Participant;
  clickable: boolean;
  index: number;
  gainedHeat?: number;
  style: any;
}

export const ParticipantHeatWrapper = styled.div<{
  $staggerIndex: number;
  $previousHeatPercentage: number;
  $newHeatPercentage: number;
}>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0px 15px;
  box-sizing: border-box;

  opacity: 0;

  animation:
    ${({ $staggerIndex }) => ($staggerIndex > 5 ? "subtleFadeIn" : "fadeIn")} 0.4s ease-in-out forwards,
    slideInParticipant 0.4s cubic-bezier(0.65, -2.07, 0.06, 1.91) forwards;

  animation-delay: calc(${({ $staggerIndex }) => ($staggerIndex > 5 ? 0 : $staggerIndex)} * 75ms);

  @keyframes slideInParticipant {
    0% {
      transform: translateY(-6px);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes subtleFadeIn {
    0% {
      opacity: 0.8;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .name-icon {
    display: flex;
    align-items: center;

    gap: 7px;
    flex-direction: row;
    width: 100%;

    &.clickable {
      cursor: pointer;
    }

    &:hover {
      .name {
        text-decoration: underline;
      }
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 8px;
    }

    .name {
      font-size: 16px;
    }

    .subtitle {
      padding: 0px;
    }
  }

  .heat {
    flex-shrink: 0;
    width: 100px;
    text-align: right;
    display: flex;
    flex-direction: column;

    font-size: 18px;
    font-weight: bold;
    gap: 3px;

    .gainedHeat {
      font-size: 14px;
      font-weight: normal;
    }

    .heatProgress {
      width: 100%;
      height: 10px;
      background: #191919;
      border-bottom: 1px solid #555;
      border-radius: 5px;
      margin-top: 10px;
    }

    .heatProgressBar {
      height: 100%;
      border-radius: 5px;
      border-top: 1px solid hsl(36, 75.4%, 94.9%);
      border-bottom: 1px solid hsl(36, 40%, 59.8%);
      background: hsl(36, 100%, 75.5%);
      box-sizing: border-box;
      width: ${({ $previousHeatPercentage }) => $previousHeatPercentage}%;

      transition: width 0.5s;
      &.animate {
        width: ${({ $newHeatPercentage }) => $newHeatPercentage}%;
      }
    }
  }
`;

export const ParticipantHeat: FC<Props> = ({ participant, clickable, index, gainedHeat, style }) => {
  const invitation = store.use.invitation();
  const settings = store.use.settings();
  const [animateProgress, setAnimateProgress] = React.useState(false);

  const cumulativeHeat = participant.cumulativeHeat[invitation.heatType];
  const heatTypeToLevels = settings.heatTypeToLevels[invitation.heatType];
  const heatTypeToTitles = settings.heatTypeToTitles[invitation.heatType];

  const { level } = getLevel(cumulativeHeat, heatTypeToLevels);
  const { heatLevelTitle, titleString, romanNumeral } = getTitle(level, heatTypeToTitles);

  const showParticipantModal = () => {
    if (!clickable) {
      return;
    }

    return store.setState({ showParticipantModal: participant.id });
  };

  useEffect(() => {
    setTimeout(() => {
      setAnimateProgress(true);
    }, 100 * index);
  }, []);

  return (
    <ParticipantHeatWrapper
      key={"p-" + participant.id}
      onClick={showParticipantModal}
      $staggerIndex={index}
      $previousHeatPercentage={0}
      $newHeatPercentage={((gainedHeat || 0) / 1000) * 100}
      style={style}
    >
      <div className={"name-icon" + (clickable ? " clickable" : "")}>
        <img
          src={`${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}${heatLevelTitle.imageUrl}?w=256`}
          alt={heatLevelTitle.title}
        />
        <div>
          <div className="name">{participant.name}</div>
          <div className={["subtitle", romanNumeral].join(" ")}>{titleString}</div>
        </div>
      </div>
      <div className="heat">
        {gainedHeat && (
          <div className="gainedHeat">
            <div className="heatProgress">
              <div className={`heatProgressBar ${animateProgress && "animate"}`} />
            </div>
            {gainedHeat}/1000
          </div>
        )}
        <span>{!gainedHeat && (participant.recentHeat[invitation.heatType] || 0)}</span>
      </div>
    </ParticipantHeatWrapper>
  );
};

export const MemoizedParticipantHeat = memo(ParticipantHeat);
