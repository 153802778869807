import React, { FC } from "react";

import { CameraAreaWrapper, Camera, CameraBox, CameraState, Toolbox } from "./styles";
import { ElementPosition } from "../UI/AudioLevel/AudioLevelInner";
import { FormattedMessage } from "react-intl";
import { StatefulDevice } from "@daily-co/daily-react";
import { MediaTrackState } from "@daily-co/daily-react/dist/hooks/useMediaTrack";
import { CameraButton } from "../UI/Menu/CameraButton";
import { MicButton } from "../UI/Menu/MicButton";
import { MoreButton } from "../UI/Menu/MoreButton";
import { can } from "../../helpers/can";
import { store } from "../../session/store";
import LocalAudioLevel from "../UI/AudioLevel/LocalAudioLevel";
import { LoadingButton } from "../UI/ButtonV2/Button";

interface CameraAreaProps {
  cameraState: {
    state: string;
    message: React.JSX.Element;
  };
  selectedCamera?: StatefulDevice;
  cameraErrorMessage: string | false;
  cameraStarted: () => void;
  videoTrack: MediaTrackState;
  localSessionId: string;
  videoElement: React.RefObject<HTMLVideoElement | null>;
  joining: boolean;
  join: () => void;
}

export const CameraArea: FC<CameraAreaProps> = ({
  cameraState,
  selectedCamera,
  cameraErrorMessage,
  cameraStarted,
  videoTrack,
  localSessionId,
  videoElement,
  joining,
  join,
}) => {
  const currentUser = store.use.currentUser();
  const invitation = store.use.invitation();

  return (
    <CameraAreaWrapper>
      <CameraBox>
        <Camera data-testid="camera-area">
          {cameraState.state !== "camera_started" && (
            <CameraState data-testid="camera-state">
              {cameraState.message}
              {cameraState.state === "camera_error" ? (
                <div style={{ padding: "0px 10px" }}>
                  <small style={{ lineHeight: "1.6em" }}>
                    <FormattedMessage
                      id="camera_area.permission_camera_error"
                      defaultMessage="Please check if your browser has permission."
                    />
                    <br />
                    <FormattedMessage
                      id="camera_area.refresh_camera_error"
                      defaultMessage="And try refreshing or restarting your browser."
                    />
                  </small>
                  <br />
                  <small style={{ color: "#ccc", fontStyle: "italic" }}>
                    <FormattedMessage
                      id="camera_area.error_message"
                      defaultMessage='Error message: "{error}"'
                      values={{ error: cameraErrorMessage }}
                    />
                  </small>
                </div>
              ) : (
                <small> {selectedCamera?.device.label || " . . . "}</small>
              )}
            </CameraState>
          )}

          {videoTrack?.persistentTrack && (
            <video
              muted
              style={{ objectFit: "cover", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}
              width="100%"
              height="100%"
              autoPlay
              playsInline
              disablePictureInPicture={true}
              onPlaying={cameraStarted}
              ref={videoElement}
            />
          )}
          {localSessionId ? (
            <LocalAudioLevel id={localSessionId} local={true} position={ElementPosition.BottomRight} />
          ) : (
            ""
          )}
        </Camera>

        {cameraState.state !== "camera_error" && (
          <Toolbox>
            <CameraButton />
            {can("manageOwnMuteState", currentUser, invitation) && <MicButton />}
            <MoreButton />
            <LoadingButton
              id="joinButton"
              disabled={joining}
              loading={joining}
              onClick={join}
              style={{
                margin: "0px",
                marginLeft: "10px",
                marginRight: "11px",
                borderRadius: "8px",
                minWidth: "120px",
                width: "120px",
                fontSize: "18px",

                borderTop: "1px solid #ffaa00",
              }}
            >
              <FormattedMessage id="prejoin.join_digital_button" defaultMessage="Join" />
            </LoadingButton>
          </Toolbox>
        )}
      </CameraBox>
    </CameraAreaWrapper>
  );
};
