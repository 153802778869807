// WaitingForPartner - A component that shows a little spinner and a message
// to sit back and wait for your partner.
// If it's been 10 minutes since the starting time, a message will appear saying
// that your partner will mostly likely not show up.

import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { Ripple } from "../Ripple/Ripple";

const Wrapper = styled.div`
  z-index: 9;
  display: flex;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  position: fixed;
  align-items: center;
  justify-content: center;
  text-align: center;

  .session-id-info {
    font-size: 14px;
    color: #999;
  }
`;

const WaitTimeNotice = styled.div`
  i {
    font-size: 36px;
    margin-right: 24px;
  }

  button {
    margin-bottom: 18px;
  }

  display: flex;
  align-items: center;
  text-align: center;
`;

const TEN_MINUTES = 600000;

interface Props {
  acceptedTime: string;
  partnersGettingReady: number;
  partnersInSession: number;
  invitationAutoGroup: boolean;
  invitationHashedID: string;
  partnerCount: number; // Number of accepted participants, whether they are online or not.
}

const WaitingForPartner = ({
  acceptedTime,
  partnersGettingReady,
  partnersInSession,
  partnerCount,
  invitationHashedID,
}: Props) => {
  const [time, setTime] = React.useState(new Date());

  const [intervalID, setIntervalID] = React.useState<NodeJS.Timeout | undefined>(undefined);

  // Set a one second timer to refresh the date so we can check when to show
  // the late notification.
  const updateTime = () => {
    setTime(new Date());
  };

  React.useEffect(() => {
    const intervalID = setInterval(updateTime, 1000);
    setIntervalID(intervalID);

    return function cleanup() {
      clearInterval(intervalID);
    };
  }, []);

  const shouldShowLatePartnerNotification = (now: Date) => {
    if (acceptedTime !== "unknown" && acceptedTime !== "0") {
      const isLate = now.getTime() - parseInt(acceptedTime) > TEN_MINUTES;

      if (!isLate) return false;

      if (intervalID) clearInterval(intervalID);

      return true;
    }

    return false;
  };

  const showLateNotification = shouldShowLatePartnerNotification(time);

  const text = () => {
    if (!partnersInSession && showLateNotification) {
      return (
        <WaitTimeNotice>
          <div>
            <h3>
              <FormattedMessage
                id="waiting_for_partner.partner_might_not_show"
                defaultMessage="Your partner might not show up."
              />
            </h3>
            <p>
              <FormattedMessage
                id="waiting_for_partner.more_than_10_minutes"
                defaultMessage="It's been more than 10 minutes and most likely your partner will not show up."
              />
              <br />
              <FormattedMessage
                id="waiting_for_partner.sorry"
                defaultMessage="We're sorry you won't get to have a session right now. Please consider scheduling a new session."
              />
              <br />
              <br />
            </p>
            <a href="https://schedule.warmspace.io/" className="button">
              <FormattedMessage id="waiting_for_partner.schedule_new" defaultMessage="Schedule a New Warmspace" />
            </a>
          </div>
        </WaitTimeNotice>
      );
    }

    if (partnersGettingReady) {
      return (
        <FormattedMessage
          id="waiting_for_partner.partner_getting_ready"
          defaultMessage="Your partner is getting ready."
        />
      );
    }

    if (!partnersInSession) {
      if (partnerCount == 0)
        return (
          <FormattedMessage
            id="waiting_for_partner.waiting_for_partners"
            defaultMessage="Waiting for your partners to join you."
          />
        );
      if (partnerCount == 1)
        return (
          <FormattedMessage
            id="waiting_for_partner.waiting_for_partner"
            defaultMessage="Waiting for your partner to join you."
          />
        );

      if (partnerCount > 1)
        return (
          <FormattedMessage
            id="waiting_for_partner.waiting_for_partners"
            defaultMessage="Waiting for your partners to join you."
          />
        );
    }
  };

  return (
    <>
      {!partnersInSession && (
        <Wrapper>
          <div id="loader">
            <Ripple />
            <div>{text()}</div>
            <small className="session-id-info" style={{}}>
              <FormattedMessage
                id="waiting_for_partner.session_id"
                defaultMessage="Session ID: {sessionId}"
                values={{ sessionId: invitationHashedID }}
              />
            </small>
          </div>
        </Wrapper>
      )}
    </>
  );
};

export default WaitingForPartner;
