import React, { FC, useState } from "react";
import toast from "react-simple-toasts";

import { FormattedMessage } from "react-intl";
import { Button } from "../ButtonV2/Button";
import { Close, Spinner } from "../../../assets/icons/Icons";

import { Overlay, Card, CardHeader, CardFooter } from "../Modal/ModalStyle";
import { store, useStore } from "../../../session/store";
import postBreakoutClose from "../../../lib/requests/postBreakoutClose";
import { modalClear } from "../Modal/Modals";

export const ConfirmCloseRoomModal: FC = () => {
  const selectedRoom = store.use.selectedRoom();
  const invitation = store.use.invitation();

  const [loadingClose, setLoadingClose] = useState<boolean>(false);

  const handleCloseModal = () => {
    useStore.setState({
      selectedRoom: null,
    });
    modalClear("ConfirmCloseBreakout");
  };

  // Closes a room or all rooms depending on roomHashId.
  // If roomHashId is null, all rooms are closed.
  const handleCloseRoom = () => {
    return async () => {
      const roomToClose = selectedRoom == "allRooms" ? null : selectedRoom;
      setLoadingClose(true);

      try {
        await postBreakoutClose({
          lobbyInvitationHashId: invitation.hashedID,
          roomInvitationHashId: roomToClose,
        });
        handleCloseModal();
      } catch (e) {
        console.log(e);
        toast("Unable to close break out room. Please try again in a few minutes.");
      } finally {
        setLoadingClose(false);
      }
    };
  };

  return (
    <>
      <Card>
        <CardHeader>
          {selectedRoom == "allRooms" ? (
            <FormattedMessage id="confirm_breakout_close_all_modal.heading" defaultMessage={"Close All Rooms?"} />
          ) : (
            <FormattedMessage id="confirm_breakout_close_modal.heading" defaultMessage={"Close Room?"} />
          )}

          <Button className="link" onClick={handleCloseModal}>
            <Close />
          </Button>
        </CardHeader>

        <CardFooter>
          <Button onClick={handleCloseModal} className="link" style={{ color: "black" }}>
            <FormattedMessage id="confirm_breakout_close_modal.cancel" defaultMessage={"Cancel"} />
          </Button>

          {loadingClose ? (
            <Spinner />
          ) : (
            <Button style={{ width: "auto", fontSize: "16px", padding: "8px 15px" }} onClick={handleCloseRoom()}>
              {selectedRoom == "allRooms" ? (
                <FormattedMessage
                  id="confirm_breakout_close_all_modal.confirm_button"
                  defaultMessage={"Close All Rooms"}
                />
              ) : (
                <FormattedMessage id="confirm_breakout_close_modal.confirm_button" defaultMessage={"Close Room"} />
              )}
            </Button>
          )}
        </CardFooter>
      </Card>
      <Overlay onClick={handleCloseModal} $blur={false} />
    </>
  );
};
