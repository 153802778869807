import React, { FC } from "react";
import styled from "styled-components";
import { FlowsList } from "./FlowsList";

interface Props {
  flows: LobbyInvitation[];
  showGroupFlows: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: calc(100% - 50px);
  box-sizing: border-box;
  color: #000000;
`;

export const obtainFilteredParticipants = (lobbyParticipants: Participant[], excludedParticipantIds: number[]) => {
  const inSessionParticipants: Participant[] = lobbyParticipants.filter((p) => p.location === "session");
  const inSessionParticipantIds = new Set(inSessionParticipants.map((p) => p.id));

  // Explicitly exclude the ones who may still be in the ready room or waiting room
  const allExcludedParticipantIds = excludedParticipantIds.filter((pid) => inSessionParticipantIds.has(pid));

  const allParticipantIds = inSessionParticipants?.map((p) => p.id);
  return { inSessionParticipants, allExcludedParticipantIds, allInSessionParticipantIds: allParticipantIds };
};

export const StartNewPage: FC<Props> = ({ flows }) => {
  return (
    <Wrapper className="flows">
      <FlowsList flows={flows} />
    </Wrapper>
  );
};
