import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";

export const VideoTileContainer = styled.div`
  box-sizing: border-box;
  background-color: #000;
  height: 100%;
  width: 100%;

  overflow: hidden;
  position: relative;
  border-radius: 10px;

  transition: width 0.3s ease;

  video {
    object-fit: contain;
    object-position: center;
    height: 100%;
    width: 100%;
  }
`;

interface VideoPlayerTileProps {
  video: HTMLVideoElement;
}

export const VideoPlayerTile: FC<VideoPlayerTileProps> = ({ video: video }: VideoPlayerTileProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (video && containerRef.current) {
      // Clear previous children (if any)
      containerRef.current.innerHTML = "";
      // Append the video element to the container
      containerRef.current.appendChild(video);
    }
  }, [video]); // Re-run if the videoElement reference changes

  return <VideoTileContainer ref={containerRef}></VideoTileContainer>;
};
