import React from "react";
import { Widget } from "./AnalyticsWidget";
import styled from "styled-components";

const EnergyBars = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

const EnergyBarWrapper = styled.div`
  display: flex;
  gap: 0px;
  height: 100%;
  flex-direction: row;
  padding-bottom: 4px;
  width: 100%;

  div.label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    border-right: 1px solid #990;
    margin-right: 3px;

    span {
      font-size: 15px;
      font-weight: normal;
      flex-shrink: 0;
      flex: 0;
      line-height: 1em;
    }

    span:nth-child(1) {
      font-size: 20px;
      font-weight: bold;
    }

    span:nth-child(2) {
      font-size: 14px;
      color: #fff;
    }
  }

  .bar {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }
`;

const BarFill = styled.div`
  width: 100%;
  background: linear-gradient(#fee16b, #feb401);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: height 1s;
  transition-delay: 0.8s;
  flex-shrink: 0;

  &#energyAfter {
    background: linear-gradient(#ff963a, #ff6e2b);
  }
`;

const backgrounds = ["linear-gradient(#fee16b, #feb401)", "linear-gradient(#ff963a, #ff6e2b)"];

export function BarsWidget({
  max,
  bars,
  fullHeight,
  labelWidth,
}: {
  max: number;
  bars: { label: string; value: number | undefined; background?: string }[];
  fullHeight?: boolean;
  labelWidth?: string;
}) {
  const filteredBars = bars.filter((b) => b.value);
  if (filteredBars.length == 0)
    return (
      <Widget style={{ flex: 1, paddingBottom: "5px" }}>
        <EnergyBars style={{ color: "#eee", fontWeight: "bold" }}>No Scores</EnergyBars>
      </Widget>
    );

  return (
    <Widget style={{ flex: 1, paddingBottom: "2px", height: fullHeight ? "100%" : undefined }}>
      <EnergyBars>
        {filteredBars.map((bar, idx) => (
          <EnergyBarWrapper key={"bar-" + idx}>
            <div className="label" style={{ width: labelWidth || "56px" }}>
              <span>{bar.value!.toFixed(1)}</span>
              <span>{bar.label}</span>
            </div>
            <div className="bar">
              <BarFill
                style={{
                  width: `calc(${(bar.value! / max) * 100}% )`,
                  background: bar.background || backgrounds[idx % 2],
                }}
              />
            </div>
          </EnergyBarWrapper>
        ))}
      </EnergyBars>
    </Widget>
  );
}
