import { createSelector } from "@reduxjs/toolkit";
import { ScreenShare } from "@daily-co/daily-react";

interface selectScreensStateSlice {
  participants: Participants;
  allScreens: ScreenShare[];
}

export const selectScreens = createSelector(
  [(state: selectScreensStateSlice) => state.participants, (state: selectScreensStateSlice) => state.allScreens],
  (participants, allScreens) => {
    console.log("SCREEN SELECTOR RAN");
    return allScreens.filter((screen) => {
      return Object.values(participants).find((p: Participant) => {
        return p.dailySessionID === screen.session_id;
      });
    });
  },
);
