import React, { FC } from "react";
import { ClearOverlay, CardBody, Card } from "../Modal/ModalStyle";
import { store } from "../../../session/store";
import { modalClear } from "../Modal/Modals";
import styled from "styled-components";

const QrModalWrapper = styled.div`
  & .modal-card {
    width: 100%;
    padding: 0;

    & .fullscreen {
      padding: 0;
    }
  }
`;
export const QrCodeModal: FC = () => {
  const showQrCodeModal = store.use.showModals().includes("QrCode");
  const invitation = store.use.invitation();

  if (!showQrCodeModal) return null;

  const qrJoinUrl = `${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}/qr/session/${invitation.hashedID}.png`;
  return (
    <QrModalWrapper>
      <Card className="modal-card">
        <CardBody className="fullscreen">
          <img alt={qrJoinUrl} src={qrJoinUrl} />
        </CardBody>
      </Card>
      <ClearOverlay
        onClick={() => {
          modalClear("QrCode");
        }}
      />
    </QrModalWrapper>
  );
};
