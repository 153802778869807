{
  "actions.content_waiting_for_partner": "En attente d'un partenaire",
  "audio_level_inner.indicator": "Indicateur du niveau audio",
  "blur_background.blur_background": "Se brouiller",
  "blur_background.unblur_background": "Déballer",
  "camera_area.permission_camera_error": "Veuillez vérifier si votre navigateur est autorisé.",
  "camera_area.refresh_camera_error": "Et essayez d'actualiser ou de redémarrer votre navigateur.",
  "camera_button.camera_off": "Désactiver",
  "camera_button.camera_on": "Activer",
  "chat.heading": "Chat",
  "chat.offline": "Hors ligne (Chat désactivé)",
  "chat.online": "En ligne",
  "chat.partner_status": "Votre partenaire est: {status}",
  "chat.username_you": "Toi",
  "chat_button": "Chat",
  "connection_issue_badge.heading": "Déconnecté",
  "content.and": "et",
  "content.your_partners": "vos partenaires",
  "content.yourself": "vous-même",
  "countdown_timer.time_left": "Temps restant",
  "determine_content.and": "et",
  "determine_content.your_partners": "vos partenaires",
  "determine_content.yourself": "vous-même",
  "drop_participant_modal.cancel": "Annuler",
  "drop_participant_modal.drop_user": "Supprimer l'utilisateur",
  "drop_participant_modal.header": "Supprimer le participant de la session",
  "drop_participant_modal.this_will_rewind": "Cela fera revenir le flux quelques étapes au début de la section actuelle.",
  "drop_participant_modal.you_can_drop_a_user": "Vous pouvez supprimer un utilisateur qui, selon vous, ne revient pas afin que le flux puisse continuer.",
  "feedback_score_step.content_waiting_for_partner": "En attente d'un partenaire",
  "feedback_words_step.content_waiting_for_partner": "En attente d'un partenaire",
  "feeling_survey.happy_label": "Heureux, édifié",
  "feeling_survey.intro_question": "Avant de commencer, comment vous sentez-vous maintenant?",
  "feeling_survey.sad_label": "Triste, déprimé",
  "manage_participant_modal.drop": "Retirer",
  "manage_participant_modal.drop_confirmation": "Êtes-vous sûr de vouloir retirer",
  "manage_participant_modal.drop_from_the_session": "Déposez {participant_name} de la session",
  "manage_participant_modal.header": "Participants",
  "manage_participant_modal.offline": "Hors ligne",
  "manage_participant_modal.online_participants": "Participants en ligne",
  "manage_participant_modal.tile.you": "(vous)",
  "manage_participant_modal.total_participants": "Nombre total de participants",
  "manage_participants_modal.cancel": "Annuler",
  "manage_participants_modal.drop_participant": "Retirer {participant_name}",
  "mic_button.mute": "Muet",
  "mic_button.unmute": "Rétablir le son",
  "more_menu.enter-fullscreen": "Plein écran",
  "participants_button.label": "Participants",
  "prejoin.join_digital_button": "Rejoindre",
  "prejoin.setup_camera_started": "La caméra a démarré",
  "screenshare_button": "Partage d'écran",
  "switch_digital_physical_modal.cancel": "Annuler",
  "tile_no_video.disconnected": "Déconnecté",
  "tile_no_video.drop_user": "Supprimer l'utilisateur",
  "tile_no_video.getting_ready": "Se préparer",
  "tile_no_video.location": "{locationMessage}",
  "tile_no_video.username_you": "Toi",
  "username.has_not_completed_step": "{name} n'a pas encore terminé cette étape",
  "username.you": "Toi",
  "waiting_for_partner.more_than_10_minutes": "Cela fait plus de 10 minutes et votre partenaire ne se présentera probablement pas.",
  "waiting_for_partner.partner_getting_ready": "Votre partenaire se prépare.",
  "waiting_for_partner.partner_might_not_show": "Votre partenaire pourrait ne pas se présenter.",
  "waiting_for_partner.schedule_new": "Planifier un nouveau Warmspace",
  "waiting_for_partner.session_id": "ID de session: {sessionId}",
  "waiting_for_partner.sorry": "Nous sommes désolés que vous ne puissiez pas avoir de session pour le moment. Veuillez envisager de planifier une nouvelle session.",
  "waiting_for_partner.waiting_for_partner": "En attente que votre partenaire vous rejoigne.",
  "breakout_button": "Flows",
  "breakout_modal.title": "Flow",
  "breakout_room.roomName": "Salle {number} : {name} ({currentStep}/{totalSteps})",
  "breakout_rooms.close": "Fermer",
  "breakout_rooms.closeAll": "Fermer tout",
  "breakout_rooms.lobby": "Lobby",
  "breakout_rooms.noParticipants": "Tous les participants sont actuellement dans une session",
  "breakout_rooms.onGoingRooms": "En cours ({quantity} salles)",
  "camera_area.error_message": "Message d'erreur: \"{error}\"",
  "connection_issue_badge.text": "Tentative de reconnexion. Actualisez la page si le problème persiste",
  "determine_content.end_call": "Terminer l'appel et donner des commentaires",
  "entities.slash": "&#47;",
  "flows_list.button_start": "Commencer",
  "flows_list.groups_gather_all": "rassemblez tous",
  "flows_list.min_groups": "en groupes de {min}",
  "flows_list.min_max_groups": "en groupes de {min} - {max} (préférer: {preferred})",
  "leave_button": "Partir",
  "lobby_status_badge.breakout": "Salle de flow",
  "lobby_status_badge.leave_room": "Quitter la pièce",
  "lobby_status_badge.lobby_title": "Lobby",
  "lobby_status_badge.re_join": "Rejoindre",
  "lobby_status_badge.room_closing_in": "La salle ferme dans {actualSecondsRemaining} secondes",
  "lost_connection": "Tentative de reconnexion",
  "manage_participant_modal.participant_name": "{participant_name}",
  "matching_check_box.noParticipants": "Aucun participant disponible pour match",
  "matching_check_box.return_to_room": "Retour à la salle",
  "more_button.menu": "Menu",
  "more_button.more": "Plus",
  "more_menu.exit-fullscreen": "Quitter le mode plein écran",
  "prejoin.setup_camera_off": "Caméra désactivé",
  "prejoin.setup_starting_camera": "Démarrage de la caméra",
  "prejoin.setup_state_off_starting_camera": "Démarrage de la caméra",
  "smart_button_fixture.content.Done": "Fini",
  "start_new_breakout.clearAll": "Tout effacer",
  "start_new_breakout.flows": "Flows",
  "start_new_breakout.includeInMatching": "Inclure dans le matching",
  "start_new_breakout.selectAll": "Sélectionner tout",
  "switch_digital_physical_button.in_person": "En personne",
  "switch_digital_physical_button.video": "Vidéo",
  "switch_physical_digital_modal.digital_switch_description": "Le mode vidéo active la caméra et le microphone et est optimisé pour créer un Warmspace via un chat vidéo.",
  "switch_physical_digital_modal.heading": "Changement de mode",
  "switch_physical_digital_modal.join_digital": "Changement de mode",
  "switch_physical_digital_modal.join_physical": "Changement de mode",
  "switch_physical_digital_modal.physical_switch_description": "Cela désactive la caméra et le microphone et est optimisé pour une connexion en personne.",
  "test": "test",
  "username.has_completed_step": "{name} a complété cette étape",
  "vU4qZm": "Envoyer",
  "waiting_for_partner.waiting_for_partners": "En attente que vos partenaires vous rejoignent"
}
