import React from "react";
import { Microphone, MicrophoneSlash } from "../../../assets/icons/Icons";
import MenuButton from "./MenuButton";
import { useAudioTrack, useDaily } from "@daily-co/daily-react";
import { useIntl } from "react-intl";
import toast from "react-simple-toasts";
import { store } from "../../../session/store";
import { setMicEnabledTo } from "../../../session/Daily/setupCallObject";

export const MicButton = () => {
  const callObject = useDaily();
  const intl = useIntl();

  const currentUser = store.use.currentUser();
  const localAudio = useAudioTrack(currentUser.dailySessionID);
  const micOn = !localAudio.isOff && localAudio.state !== "blocked" && localAudio.state !== "interrupted";

  const isMicDisabled = !micOn;

  function toggleMic() {
    toast(`Microphone ${isMicDisabled ? "unmuted" : "muted"}`, 2000);
    setMicEnabledTo(callObject, !micOn);
  }

  return (
    <MenuButton
      buttonIcon={isMicDisabled ? <MicrophoneSlash fill="white" width={"21px"} /> : <Microphone fill="white" />}
      onClick={toggleMic}
      style={{
        minWidth: "67px", // Reserve space for the longest text (Unmute) to prevent the button from resizing
      }}
    >
      {isMicDisabled
        ? intl.formatMessage({
            id: "mic_button.unmute",
            defaultMessage: "Unmute",
          })
        : intl.formatMessage({
            id: "mic_button.mute",
            defaultMessage: "Mute",
          })}
    </MenuButton>
  );
};
