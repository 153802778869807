import React, { FC, useEffect, useRef, useState } from "react";
import { store, useStore } from "../../../session/store";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;

  position: absolute;

  margin-top: 10px;

  &.addPartner {
    color: #ff7729;
    font-size: 20px;

    &:hover {
      background-color: #00000016;
      cursor: pointer;
    }
  }

  i {
    font-size: 16px;
    color: #222;
    text-align: center;
    width: 240px;
  }

  form {
    background-color: #f5ecde;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 7px;
    padding: 10px;
    border-radius: 8px;

    border-right: 1px solid #ccc;
    border-top: 1px solid #fff;
    box-shadow: 0 3px 3px 0 #00000016;
    width: 200px;

    label {
      font-size: 16px;
      font-weight: bold;
      color: #000;
    }

    div {
      display: flex;
      gap: 7px;
    }

    input,
    button {
      border-radius: 8px;
      border: 0px;
      height: 40px;

      font-weight: bold;
      color: #fff;
      background-color: #ff7729;
      width: 100%;
      box-sizing: border-box;
    }

    input {
      font-size: 16px;
      padding: 3px 8px;
      background-color: #fff;
      color: #000;
    }

    button {
      padding: 7px 8px;
      font-size: 18px;
      width: 90px;
    }

    input::placeholder {
      color: #666;
      font-weight: normal;
    }

    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
`;

const Inner = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const OwnCode = styled.div`
  background-color: #232323;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  padding-bottom: 14px;
  border-radius: 8px;

  .number {
    font-size: 30px;
    font-weight: bold;
    display: block;
  }

  .label {
    font-size: 12px;
    text-transform: uppercase;
    display: block;
  }
`;

export const AddPartner: FC = () => {
  const participants = store.use.participants();
  const invitation = store.use.invitation();
  const currentUser = store.use.currentUser();
  const core = store.use.core();

  const curParticipant = participants[currentUser.id];
  const currentStep = store.use.currentStep();
  const showAddPartner = store.use.showAddPartner();

  const inputRef = useRef(null);
  const [buttonLoading, setButtonLoading] = useState<boolean | null>(null);

  useEffect(() => {
    if (useStore.getState().showAddPartner && useStore.getState().stepTiming > 0) {
      useStore.setState({ showAddPartner: false });
    }
  }, [currentStep]);

  if (!showAddPartner || !curParticipant?.personalCode || invitation.lobby || !invitation.partnerJoinable) {
    return;
  }

  function handleSubmit() {
    setButtonLoading(true);
    core.subscription?.perform(
      "add_partner",
      {
        partnerCode: (inputRef.current! as HTMLInputElement).value,
      },
      () => setButtonLoading(false),
    );

    (inputRef.current! as HTMLInputElement).value = "";
  }

  return (
    <Wrapper>
      <i>Share your code with a partner, or enter theirs to group up.</i>
      <Inner>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <label>Add a partner:</label>
          <div>
            <input
              type="number"
              inputMode={"numeric"}
              enterKeyHint={"go"}
              ref={inputRef}
              placeholder={"Partner code"}
            />
            <button type={"submit"} enterKeyHint={"go"} value={"Submit"} className="addPartner">
              Add
            </button>
          </div>
        </form>
        <OwnCode>
          <span className="number">{curParticipant.personalCode}</span>
          <span className="label">
            <FormattedMessage id="add_partner.your_personal_code" defaultMessage="Your code" />
          </span>
        </OwnCode>
      </Inner>
    </Wrapper>
  );
};
