import { Core } from "../core";
import { ClearLoaderFunc } from "../../lib/requests/postAction";

export const toggleRaiseHand = function (participantID: number, enabled: boolean, clearLoader?: ClearLoaderFunc) {
  const core = Core.GetInstance();
  core.subscription?.perform(
    "toggle_hand",
    { participantId: participantID, action: enabled ? "raise_hand" : "lower_hand" },
    clearLoader,
  );
};
