import React from "react";
import ErrorMessage from "../ErrorMessages/ErrorMessage";
import { Button } from "../ButtonV2/Button";
import { ReportPartnerIcon } from "../../../assets/icons/Icons";
import { FormattedMessage } from "react-intl";
import { store, useStore } from "../../../session/store";

export const EndScreen = () => {
  const invitation = store.use.invitation();
  const inPerson = store.use.inPerson();

  return (
    <ErrorMessage
      heading="Thank you for using Warmspace"
      lowerArea={
        !inPerson &&
        invitation.enableReportPartner && (
          <div>
            <Button
              className="secondary "
              onClick={() => {
                useStore.setState({ showModals: ["ReportPartner"] });
              }}
            >
              <ReportPartnerIcon />
              <FormattedMessage id="confirm_leave_modal.report_partner" defaultMessage="Report Partner" />
            </Button>
          </div>
        )
      }
    >
      <FormattedMessage id="app.end_of_session_message" defaultMessage="You may now close this window." />
      <br />
      <br />
      <FormattedMessage
        id="app.join_message"
        defaultMessage="Or {link} "
        values={{
          link: (
            <>
              <a href="">
                <FormattedMessage id="app.join_link" defaultMessage="join the call again" />
              </a>
              <br />
            </>
          ),
        }}
      />
    </ErrorMessage>
  );
};
