import React, { memo, useEffect } from "react";
import d3Cloud from "d3-cloud";
import { Group } from "@visx/group";
import { BaseDatum, WordcloudConfig } from "@visx/wordcloud/lib/types";
import { useWordcloud } from "@visx/wordcloud";

export interface WordcloudProps<Datum extends BaseDatum> extends WordcloudConfig<Datum> {
  children: (words: d3Cloud.Word[]) => React.ReactNode;
  setWordsPlaced: (value: ((prevState: number) => number) | number) => void;
}

function Wordcloud<Datum extends BaseDatum>(props: WordcloudProps<Datum>) {
  const { children, setWordsPlaced, ...wordcloudConfig } = props;
  const { width, height } = wordcloudConfig;

  const words = useWordcloud(wordcloudConfig);

  useEffect(() => {
    if (words.length > 0) {
      setWordsPlaced(words.length);
    }
  }, [words]);

  if (width === 0 || height === 0) return null;

  return (
    <svg width={width} height={height}>
      <Group left={width / 2} top={height / 2}>
        {/* @ts-ignore */}
        {children(words)}
      </Group>
    </svg>
  );
}

export default memo(Wordcloud);
