import React, { FC } from "react";
import { store } from "../../../session/store";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";

const Wrapper = styled.div`
  font-size: 30px;
  margin-top: 30px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 60px;
  position: relative;
  top: 10px;

  background-color: #0000000c;
  margin-left: 10px;
  padding: 20px;
  padding-top: 15px;
  border-radius: 10px;
  padding-bottom: 5px;

  img {
    width: 25px;
    margin: auto;
  }

  small {
    margin-top: 5px;
    font-size: 15px;
    color: #666;
  }
`;

export const FacilitatorControl: FC = () => {
  const rooms = store.use.rooms();
  const allParticipants = store.use.allParticipants();
  const invitation = store.use.invitation();

  // Allow all users to see the completed steps if facilitator control is enabled
  if (!invitation.controllingInqUserId) {
    return;
  }

  // Find all the rooms with online participants
  // todo: this code is duplicated in RoomsPage.tsx
  const activeRooms = rooms.filter((r) =>
    r.participantIds.find((p) => {
      const participant = allParticipants[p];
      return participant?.location == "session" && participant.curRoomHashId == r.hashId;
    }),
  );

  if (activeRooms.length <= 1) return;

  const numStepsCompleted = activeRooms.filter((r) => r.stepCompleted).length;

  const stepsStatus = `${numStepsCompleted > activeRooms.length - 1 ? activeRooms.length - 1 : numStepsCompleted} / ${activeRooms.length - 1}`;

  return (
    <Wrapper>
      {stepsStatus}
      <small>
        <FormattedMessage id="facilitator_control.complete" defaultMessage="complete" />
      </small>
    </Wrapper>
  );
};
