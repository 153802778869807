import { Core } from "../core";
import { useStore } from "../store";
import { ClearLoaderFunc } from "../../lib/requests/postAction";

export const doAction = async function (
  core: Core,
  action: ActionConsequence,
  stepID: number,
  userID: number,
  choice = "",
  clearLoader: ClearLoaderFunc | undefined = undefined,
) {
  // This is here to load audio files on click of a button if they haven't been loaded yet.
  if (Object.keys(useStore.getState().flowAudioVideo).length == 0) {
    try {
      console.debug("[session-ui]: Loading narration audio from Content.tsx");
      await core.loadAudio();
    } catch {
      console.debug("[session-ui]: Failed to load the audio files.");
      //TODO: Error handling and surfacing to user.
    }
  }

  // Until Flow Builder is deployed with the fix for this:
  // @ts-ignore
  if (action === "userFeedback") {
    action = "end_session";
  }

  core.subscription?.perform(
    "do_action",
    {
      actionType: action,
      stepID,
      userID,
      choice,
    },
    clearLoader,
  );
};
