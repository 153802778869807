import styled from "styled-components";

export const SetupWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--clr-fg-0);
  width: 100%;
  height: 100%;
  padding: 10px 10px;
  box-sizing: border-box;

  h3 {
    font-weight: normal;
  }

  #joinButton {
    min-width: 150px;
  }
`;

export const SetupInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 550px;

  path.letter {
    fill: light-dark(#222, #bbb) !important;
  }
`;

export const CameraAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 16px;
  background-color: #000;
  border-radius: 10px;
`;

export const CameraBox = styled.div`
  background-color: var(--clr-bg-0);
  position: relative;
  display: block;
  flex-shrink: 0;

  width: 100%;
  border-radius: 10px;

  video {
    margin: 0px;
    transform: rotateY(180deg);
  }
`;

export const Camera = styled.div`
  padding-top: 75%;
  height: 100%;
  position: relative;
  overflow: hidden;

  video {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
  }
`;

export const CameraState = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  line-height: 1.6em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 375px) {
    font-size: 14px;
    small {
      font-size: 12px;
    }
  }
`;

export const Toolbox = styled.div`
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 60px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #111;
  overflow: hidden;
  flex-wrap: wrap;
`;

export const CameraAndJoinButton = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: 505px) {
    flex-direction: column;
    font-size: 14px;
  }
`;
