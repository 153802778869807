import React, { FC, useEffect, useRef, useState, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { useLocalSessionId, useVideoTrack, useDevices, useDaily } from "@daily-co/daily-react";
import { Branding } from "../UI/Branding/Branding";
import { SetupInner, SetupWrapper } from "./styles";
import { CameraArea } from "./CameraArea";
import { store } from "../../session/store";

export const Prejoin: FC = () => {
  const callObject = useDaily();

  const core = store.use.core();
  const localSessionId = useLocalSessionId();

  const [joining, setJoining] = useState(false);
  const { cameras } = useDevices();

  const videoTrack = useVideoTrack(localSessionId);
  const videoElement = useRef<HTMLVideoElement | null>(null);

  const mutedVideo = videoTrack.isOff;
  const selectedCamera = cameras.find((x) => x.selected);

  useEffect(() => {
    core.subscription?.perform("update_location", { location: "ready-room" });
  }, []);

  useEffect(() => {
    if (!videoTrack.persistentTrack) return;
    if (videoElement?.current) {
      videoElement.current.srcObject = new MediaStream([videoTrack.persistentTrack]);
    }
  }, [videoTrack.persistentTrack]);

  const [cameraState, setCameraState] = useState({
    enabled: !mutedVideo,
    activating: true,
    active: false,
    state: "camera_off",
    message: <FormattedMessage id="prejoin.setup_starting_camera" defaultMessage="Starting Camera" />,
  });

  const cameraStarted = useCallback(() => {
    setCameraState({
      ...cameraState,
      activating: false,
      active: true,
      state: "camera_started",
      message: <FormattedMessage id="prejoin.setup_camera_started" defaultMessage="Camera started" />,
    });
  }, [cameraState]);

  useEffect(() => {
    if (mutedVideo && callObject?.localVideo() !== undefined) {
      if (videoElement.current) {
        videoElement.current.srcObject = null;
      }

      setCameraState((cameraState) => ({
        ...cameraState,
        activating: false,
        active: false,
        enabled: false,
        state: "camera_off",
        message: <FormattedMessage id="prejoin.setup_camera_off" defaultMessage="Camera Off" />,
      }));
    } else {
      setCameraState((cameraState) => ({
        ...cameraState,
        activating: true,
        active: false,
        enabled: false,
        state: "camera_off",
        message: <FormattedMessage id="prejoin.setup_state_off_starting_camera" defaultMessage="Camera off" />,
      }));
    }
  }, [mutedVideo]);

  const joinDigital = async () => {
    setJoining(true);

    core.subscription?.perform("switch_in_person_mode", {
      in_person: false,
    });

    await core.joinCall();
    setJoining(false);
  };

  return (
    <SetupWrapper>
      <SetupInner>
        <Branding fill={"#fff"} style={{ marginBottom: "15px", width: "225px" }} />

        <CameraArea
          cameraState={cameraState}
          selectedCamera={selectedCamera}
          cameraErrorMessage={""}
          cameraStarted={cameraStarted}
          videoTrack={videoTrack}
          localSessionId={localSessionId}
          videoElement={videoElement}
          joining={joining}
          join={joinDigital}
        />
      </SetupInner>
    </SetupWrapper>
  );
};
