import styled from "styled-components";
import React, { FC } from "react";
import { HeatUpdateMessage } from "../../../types/CableMessage";
import { store } from "../../../session/store";
import { getTitle } from "../Participants/getTitle";

const Wrapper = styled.div`
  color: #f00;
  font-weight: 600;
  /*     background-image: linear-gradient(90deg, #fcecfe, #fbf6e7, #e6fcf5); */
  background-image: linear-gradient(315deg, #ffc4ec -10%, #efdbfd 50%, #ffedd6 110%);

  position: relative;
  isolation: isolate;
  box-shadow: inset 0 -10px 20px -10px hsla(var(--shadow-hue), 30%, 50%, 95%);
  border-radius: 0.66em;
  scale: 1;
  transition: all var(--spring-duration) var(--spring-easing);
  z-index: 1000;

  animation: LevelUpToast 6.5s var(--spring-easing) forwards;
  max-width: 280px;
  opacity: 0;

  @keyframes LevelUpToast {
    0% {
      opacity: 0;
      transform: translate(-50%, -53%) scale(0);
    }
    20% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    75% {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0);
    }
  }

  &:hover:not(:active),
  &.active {
    transition-duration: calc(var(--spring-duration) * 0.5);
  }
  &:active {
    scale: 1.1;
    transition-duration: calc(var(--spring-duration) * 0.5);
  }

  .shimmer {
    position: absolute;
    inset: -40px;
    border-radius: inherit;
    mask-image: conic-gradient(
      from var(--shimmer, 0deg),
      transparent 0%,
      transparent 10%,
      black 36%,
      black 45%,
      transparent 50%,
      transparent 60%,
      black 85%,
      black 95%,
      transparent 100%
    );
    mask-size: cover;
    mix-blend-mode: plus-lighter;
    animation: shimmer 2.6s linear 1 both;
    animation-delay: 1.9s;
  }

  .shimmer::before,
  .shimmer::after {
    opacity: 1;
    animation: heatToastShine 2.6s linear 1 forwards;
    animation-delay: 1.9s;
    transition: all 0.5s linear;
    opacity: 0;
    content: "";
    border-radius: inherit;
    position: absolute;
    mix-blend-mode: color;
    inset: 40px;
    pointer-events: none;
  }

  .shimmer::before {
    box-shadow:
      0 0 6px 2px hsl(var(--glow-hue) 90% 45%),
      0 0 10px 4px hsl(var(--glow-hue) 20% 80%),
      0 0 16px 4px hsl(var(--glow-hue) 50% 70%),
      0 0 30px 5px hsl(var(--glow-hue) 100% 70%);
    z-index: 99;
  }

  .shimmer::after {
    box-shadow:
      inset 0 0 0 1px hsl(var(--glow-hue) 90% 95%),
      inset 0 0 2px 1px hsl(var(--glow-hue) 100% 80%),
      inset 0 0 5px 2px hsl(var(--glow-hue) 100% 70%);
    z-index: 100;
  }

  .text {
    color: transparent;

    background-color: #fff;
    background-clip: text;
    background-image: linear-gradient(
      120deg,
      hsla(var(--glow-hue), 100%, 90%, 0.3) 0%,
      hsla(var(--glow-hue), 100%, 80%, 0.6) 40%,
      hsla(var(--glow-hue), 100%, 100%, 0.3) 100%
    );

    background-size: 300% 200%;
    background-position: -100% 0;
    animation: text 1.06s ease-in 1 both;
  }

  position: fixed;
  top: 50%;
  left: 50%;
  background: #333;
  border-top: 1px solid #777;
  border-radius: 15px;
  transform: translate(-50%, -50%);

  padding: 15px 13px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px;
  padding-top: 2px;
`;

const Level = styled.h2`
  margin: 0px;
  font-size: 20px;
  font-weight: 300;
  position: absolute;
  top: 0px;
  width: 100px;
  text-align: center;

  &.before {
    animation:
      roll 1s var(--spring-easing) forwards,
      fadeOut 1s var(--spring-easing) forwards;

    animation-delay: 0.9s;
  }

  &.after {
    top: 100%;
    animation: roll 1s var(--spring-easing) forwards;
    animation-delay: 0.9s;
  }
`;

const Title = styled.h1`
  margin: 0px;
  font-size: 24px;
  font-weight: bold;

  text-align: center;
  position: absolute;
  top: 0px;
  width: 200px;

  &.before {
    opacity: 1;
    animation:
      roll 1s var(--spring-easing) forwards,
      fadeOut 1s var(--spring-easing) forwards;
    animation-delay: 1.4s;
  }

  &.after {
    top: 100%;
    animation: roll 1s var(--spring-easing) forwards;
    animation-delay: 1.4s;
  }
`;

const LevelContainer = styled.div`
  position: relative;
  width: 100px;
  height: 20px;
  overflow: hidden;
`;
const TitleContainer = styled.div`
  position: relative;
  width: 200px;
  height: 24px;
  overflow: hidden;
`;

export const LevelUpToast: FC<HeatUpdateMessage> = ({ previousLevel, level, heatType }) => {
  const settings = store.use.settings();
  const heatToTitles = settings?.heatTypeToTitles?.[heatType];

  if (!heatToTitles) {
    return null;
  }

  const previousTitle = getTitle(previousLevel, heatToTitles);
  const title = getTitle(level, heatToTitles);

  return (
    <Wrapper>
      <span className="shimmer"></span>
      <Container>
        <LevelContainer>
          <Level className="text before">Level {previousLevel}</Level>
          <Level className="text after">Level {level}</Level>
        </LevelContainer>

        <TitleContainer>
          <Title className="text before">{previousTitle.titleString}</Title>
          <Title className="text after">{title.titleString}</Title>
        </TitleContainer>
      </Container>
    </Wrapper>
  );
};
