import React, { useEffect, useState } from "react";
import { store, useStore } from "../../../session/store";
import { replacePlaceholder } from "../../../lib/getFeedbackURL";
import styled from "styled-components";
import { RoomsList } from "../FlowsModal/RoomsList";
import { FormattedMessage } from "react-intl";
import { isIframeStep } from "../../../types/type_guards";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  z-index: 2;
  width: 100%;
  position: relative;
  opacity: 0;
  transition: all 1.5s;

  &.visible {
    opacity: 1;
  }
`;

const Heading = styled.div`
  font-size: 24px;
  font-family: "ITC Clearface", sans-serif;
  height: fit-content;
  padding-top: 15px;
  padding-bottom: 25px;
  text-align: center;
`;

const minTimeOnStep = 3;
export const useShowMatchingStep = (currentStep: AnyStep | undefined) => {
  const [showMatchingStep, setShowMatchingStep] = useState(
    useStore.getState().timeOnStep >= minTimeOnStep && isIframeStep(currentStep),
  );

  // Don't show the matching info for 5 seconds to allow the matching to
  // happen without causing flickering on the screen when this step renders
  useEffect(() => {
    const timeOnStep = useStore.getState().timeOnStep;
    if (!isIframeStep(currentStep)) {
      setShowMatchingStep(false);
    } else if (timeOnStep >= minTimeOnStep) {
      setShowMatchingStep(true);
    } else {
      // If it's too soon, then wait until it's time to show the MatchingStep
      setTimeout(() => setShowMatchingStep(isIframeStep(currentStep)), (minTimeOnStep - timeOnStep) * 1000);
    }
  }, [currentStep]);

  const result = showMatchingStep && isIframeStep(currentStep);

  return result;
};

interface MatchingStepProps {
  showMatchingStep: boolean;
}

export const MatchingStep = ({ showMatchingStep }: MatchingStepProps) => {
  const currentStep = store.use.currentStep();
  const invitation = store.use.invitation();
  const currentUser = store.use.currentUser();
  const rooms = store.use.rooms();
  const myRoom = rooms.find((r) => r.hashId === invitation.hashedID);

  useEffect(() => {
    if (showMatchingStep && isIframeStep(currentStep)) {
      const interval = setTimeout(() => {
        document.getElementById("matching-step")?.classList.add("visible");
      }, 10);

      return () => {
        clearInterval(interval);
      };
    }
  }, [currentStep, showMatchingStep]);

  // Only show rooms that are
  // a) either in the same group or there are no groups
  // b) active
  // c) breakouts (non-lobby space)
  // d) have at least 1 participant present in the room
  const myVisibleRooms = rooms.filter(
    (r) =>
      myRoom &&
      r.hashId != myRoom.hashId &&
      (!myRoom.groupHashId || myRoom.groupHashId == r.groupHashId) &&
      (r.status == "ongoing" || r.status == "new") &&
      r.hashId != invitation.lobbyHashId &&
      r.participantNames.find((name) => name) &&
      r.sequenceIndex == myRoom.sequenceIndex,
  );

  if (!isIframeStep(currentStep)) return;

  const srcUrl = replacePlaceholder((currentStep as IframeStep).settings.url, {
    hashedInvitationID: invitation.hashedID,
    yourID: currentUser.id,
  });
  return (
    <Wrapper id="matching-step">
      <Heading>
        <FormattedMessage
          id="matching_step.lets_breathe_and_wait"
          defaultMessage="Let's all breathe as we wait for your next partner(s) "
        />
      </Heading>
      <RoomsList rooms={myVisibleRooms} allParticipants={{}} />
      <iframe
        key="iframe"
        src={srcUrl}
        style={{
          zIndex: 12,
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          position: "absolute",
          width: "100%",
          height: "100%",
          border: "0px",
          display: "none",
        }}
      />
    </Wrapper>
  );
};
