import React, { FC, useMemo } from "react";
import { Card, LightOverlay } from "../Modal/ModalStyle";
import { store, useStore } from "../../../session/store";
import { Button } from "../ButtonV2/Button";
import { Close, Flame } from "../../../assets/icons/Icons";
import styled from "styled-components";
import { getTitle } from "./getTitle";
import { HeatBox } from "../Badge/HeatBadge";
import { FormattedMessage } from "react-intl";
import { moveToWaitingRoom } from "../../../session/SessionChannel/moveToWaitingRoom";
import { can } from "../../../helpers/can";
import { makeParticipantFacilitator } from "../../../session/SessionChannel/makeParticipantFacilitator";
import { admitParticipant } from "../../../session/SessionChannel/admitParticipant";
import { muteParticipant } from "../../../session/SessionChannel/muteParticipant";
import { useParticipantProperty } from "@daily-co/daily-react";
import { getLevel } from "./getLevel";
import { toggleRaiseHand } from "../../../session/SessionChannel/toggleRaiseHand";
import { dropParticipant } from "../../../session/SessionChannel/dropParticipant";

interface Props {
  participantId: number;
}

const HeatStats = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  align-items: center;
`;
const ParticipantWrapper = styled.div`
  ${HeatBox} {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px 15px;

    .subtitle {
      font-size: 16px;
      color: #c8b2b2;
      font-weight: 300;
    }
    .name-icon {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      .name-block {
        position: relative;
        top: 3px;
        white-space: nowrap;
        .name {
          font-size: 20px;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 160px;
          position: relative;

          span {
            font-size: 12px;
            position: relative;
            top: -2px;
            background: #333;
            padding: 3px 7px;
            border-radius: 5px;
          }
        }
      }

      img {
        width: 60px;
        height: 60px;

        align-self: flex-start;

        border-top: 1px solid #444;
        background: #333;
        border-radius: 10px;
      }
    }
  }

  hr {
    width: 100%;
    border: none;
    background: #444;
    height: 1px;
    margin-top: -10px;
    margin-bottom: -5px;
  }

  .heat {
    color: #ff8c1a;
    svg {
      margin-right: 7px;
      position: relative;
      top: 1px;
    }
  }

  ${Card} {
    padding: 0;
    background: none;
    animation: ParticipantWrapper 0.3s;
    max-width: 280px;

    @keyframes ParticipantWrapper {
      from {
        opacity: 0;
        transform: translate(-50%, -53%) scale(0.95);
      }
      to {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
      }
    }
  }

  &.closing {
    ${Card} {
      animation: ParticipantWrapperClose 0.3s forwards;
      transition: all 0.3s;

      @keyframes ParticipantWrapperClose {
        0% {
          opacity: 1;
          transform: translate(-50%, -50%) scale(1);
        }
        100% {
          opacity: 0;
          transform: translate(-50%, -53%) scale(0.95);
        }
      }
    }
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    background: none;
    padding: 0px;
  }

  .level-up {
    width: 100%;

    color: #bbb;
    font-size: 16px;
  }
`;

const ParticipantAction = styled(Button)`
  display: block;
  background: #333;
  font-weight: 400;
  font-size: 16px;
  border-radius: 5px;
  color: #eee;
  width: 100%;
  border-top: 1px #444 solid;

  &:hover {
    transform: unset;
    background: #444;
  }
`;

const ParticipantActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
`;

export const ParticipantModal: FC<Props> = ({ participantId }) => {
  const allParticipants = store.use.participants();
  const invitation = store.use.invitation();
  const currentUser = store.use.currentUser();
  const settings = store.use.core().globalSettings;
  const raisedHands = store.use.raisedHands();
  const participant = allParticipants[participantId];
  const [closing, setClosing] = React.useState(false);

  const popover = document.getElementById("heatPopover");
  if (popover) {
    popover.hidePopover();
  }

  function clearParticipantModal() {
    store.setState({ showParticipantModal: -1 });
  }

  const { level, pointsForNextLevel } = useMemo(
    () => getLevel(participant.cumulativeHeat[invitation.heatType], settings.heatTypeToLevels[invitation.heatType]),
    [participant.cumulativeHeat, settings],
  );

  const { heatLevelTitle, titleString } = useMemo(
    () => getTitle(level, settings.heatTypeToTitles[invitation.heatType]),
    [participant.cumulativeHeat, level, settings],
  );

  const canManageParticipants = can("manageParticipants", currentUser, invitation);
  const mutingEnabled = can("muteOthers", currentUser, invitation) && !invitation.inPerson;
  const showMute = mutingEnabled && participant.location === "session";
  const trackState = useParticipantProperty(participant.dailySessionID, "tracks");
  const hasAudio = trackState && trackState.audio.state === "playable";

  const inWaitingRoom = participant.location == "waitingRoom";

  const closeModal = () => {
    setClosing(true);
    setTimeout(() => {
      clearParticipantModal();
    }, 200);
  };

  const handRaised = raisedHands.includes(participantId);

  const lowerHand = () => {
    toggleRaiseHand(participantId, false);
  };

  return (
    <>
      <ParticipantWrapper className={closing ? "closing" : ""}>
        <Card className="card">
          <HeatBox>
            <div>
              <div className="name-icon">
                <img
                  src={`${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}${heatLevelTitle.imageUrl}?w=256`}
                  alt={heatLevelTitle.title}
                />
                <div className="name-block">
                  <div className="name">
                    {participant.name} <span>Lvl {level}</span>
                  </div>
                  <div className="subtitle">{titleString}</div>
                </div>
              </div>
            </div>
            <Button className="close-button" onClick={closeModal}>
              <Close stroke={"white"} />
            </Button>
            <HeatStats>
              <div>
                <div className="heat">
                  <Flame fill={"#ff8c1a"} />
                  {participant.recentHeat[invitation.heatType] || 0}
                </div>
                <div className="subtitle">
                  <FormattedMessage id="participant_modal.recent_heat" defaultMessage="Recent Warmth" />
                </div>
              </div>
              <div>
                <div className="heat">
                  <Flame fill={"#ff8c1a"} />
                  {participant.cumulativeHeat[invitation.heatType] || 0}
                </div>
                <div className="subtitle">
                  <FormattedMessage id="participant_modal.lifetime_heat" defaultMessage="Lifetime Warmth" />
                </div>
              </div>
            </HeatStats>

            <div className="level-up">
              <FormattedMessage
                id="participant_modal.heat_until_next_level"
                defaultMessage="{heat} more warmth until next level up"
                values={{ heat: pointsForNextLevel }}
              />
            </div>

            <ParticipantActions>
              {canManageParticipants && (
                <>
                  {inWaitingRoom ? (
                    <ParticipantAction
                      className="button"
                      onClick={() => {
                        admitParticipant(participant.id);
                      }}
                    >
                      <FormattedMessage id="manage_participant_modal.admit" defaultMessage="Admit" />
                    </ParticipantAction>
                  ) : (
                    showMute && (
                      <ParticipantAction
                        className="button"
                        onClick={() => {
                          muteParticipant(participant.id, hasAudio);
                        }}
                      >
                        {hasAudio ? (
                          <FormattedMessage id="manage_participant_modal.mute" defaultMessage="Mute" />
                        ) : (
                          <FormattedMessage id="manage_participant_modal.unmute" defaultMessage="Unmute" />
                        )}
                      </ParticipantAction>
                    )
                  )}

                  {currentUser.authorizations.indexOf("facilitator") == -1 ? (
                    <ParticipantAction
                      className="button"
                      onClick={() => {
                        makeParticipantFacilitator(participant.id, true);
                      }}
                    >
                      <FormattedMessage
                        id="manage_participant_modal.make_facilitator"
                        defaultMessage="Make Facilitator"
                      />
                    </ParticipantAction>
                  ) : (
                    <ParticipantAction
                      className="button"
                      onClick={() => {
                        makeParticipantFacilitator(participant.id, false);
                      }}
                    >
                      <FormattedMessage
                        id="manage_participant_modal.remove_facilitator"
                        defaultMessage="Remove Facilitator"
                      />
                    </ParticipantAction>
                  )}
                  {!inWaitingRoom && !invitation.inPerson && (
                    <ParticipantAction
                      className="button"
                      onClick={() => {
                        moveToWaitingRoom(participant.id);
                      }}
                    >
                      <FormattedMessage
                        id="manage_participant_modal.move_to_waiting_room"
                        defaultMessage="Move to Waiting Room"
                      />
                    </ParticipantAction>
                  )}
                  <ParticipantAction
                    className="button"
                    onClick={() => {
                      dropParticipant(participant.id);
                    }}
                  >
                    <FormattedMessage
                      id="manage_participant_modal.drop_participant"
                      defaultMessage="Drop Participant"
                    />
                  </ParticipantAction>
                </>
              )}

              {(canManageParticipants || participant.id == currentUser.id) && (
                <ParticipantAction
                  className="button"
                  onClick={() => {
                    closeModal();
                    useStore.setState({ showRenameParticipantModal: participant.id });
                  }}
                >
                  <FormattedMessage id="participant_modal.rename_user" defaultMessage="Edit Name" />
                </ParticipantAction>
              )}

              {(canManageParticipants || participant.id == currentUser.id) && handRaised && (
                <ParticipantAction
                  className="button"
                  onClick={() => {
                    lowerHand();
                    closeModal();
                  }}
                >
                  <FormattedMessage id="participant_modal.lower_hand" defaultMessage="Lower Hand" />
                </ParticipantAction>
              )}
            </ParticipantActions>
          </HeatBox>
        </Card>
        <LightOverlay onClick={closeModal} $blur={false} />
      </ParticipantWrapper>
    </>
  );
};
