import axios from "axios";

export default async (hashedInvitationID: string, signal: AbortSignal): Promise<InvitationResponse> => {
  return axios
    .get(`${process.env.WARMSPACE_SCHEDULE_BACKEND_URL}/api/v1/session_app/invitations/${hashedInvitationID}`, {
      signal,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
    .then((response) => {
      const invitation: InvitationResponse = response.data;

      // If we were given a redirectUrl, then we must go there before we can join this invitation

      if (invitation.redirectUrl) {
        window.location.href = invitation.redirectUrl;
        throw Error("Redirecting to: " + invitation.redirectUrl);
      }

      invitation.startTime = new Date(invitation.startTime);
      return invitation;
    })
    .catch();
};
