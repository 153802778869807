export function hasRole(user: { authorizations: Authorization[] }, ...roles: Authorization[]) {
  return roles.some((role) => user.authorizations.includes(role));
}

export type Permissions =
  | "advanceAudio"
  | "autoLaunchFlow"
  | "bypassLockedFlows"
  | "controlSteps"
  | "editCustomPrompts"
  | "manageBreakouts"
  | "manageOwnMuteState"
  | "manageParticipants" // this is only for facilitators and has no optional permission for regular users
  | "muteOthers"
  | "partnerCodeJoin"
  | "record"
  | "sendToRoom"
  | "updateInvitationSettings";

export function can(
  permission: Permissions,
  user: Pick<UserResponse, "authorizations" | "id">,
  invitation: Pick<
    InvitationResponse,
    | "restrictFacilitatorActions"
    | "restrictUserActions"
    | "muteAll"
    | "controllingInqUserId"
    | "disableCustomPrompts"
    | "facilitators"
  >,
) {
  // Superusers and admins can do anything
  if (hasRole(user, "admin", "super_user")) return true;

  switch (permission) {
    case "bypassLockedFlows":
      return false;
  }

  // Facilitators can do most things
  if (hasRole(user, "facilitator", "admin", "super_user")) return true;

  switch (permission) {
    // Facilitator actions
    case "muteOthers":
    case "manageBreakouts":
    case "record":
    case "sendToRoom":
    case "updateInvitationSettings":
    case "autoLaunchFlow":
    case "partnerCodeJoin":
      return !invitation.restrictFacilitatorActions;

    // Other actions
    case "manageOwnMuteState":
      return !invitation.muteAll;

    case "advanceAudio":
      return !invitation.restrictUserActions;

    case "controlSteps":
      return user.id === invitation.controllingInqUserId;

    case "editCustomPrompts":
      return !invitation.disableCustomPrompts;

    default:
      return false;
  }
}
