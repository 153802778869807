import styled from "styled-components";

export const PaginationControls = styled.div`
  margin-top: 10px;
  height: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;

  .pageIndicator {
    height: 20px;
    width: 20px;
    display: flex;
    color: #000;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    font-weight: bold;
    border-radius: 1px;
    background-color: ${({ theme }) => theme.controls.inactive};
    margin: 0px 2px;
    cursor: pointer;
    border-radius: 50%;

    &.active {
      background-color: ${({ theme }) => theme.controls.active};
    }
  }
`;
