import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { can } from "../../../helpers/can";
import { LoadingButton } from "../ButtonV2/Button";
import { admitParticipant } from "../../../session/SessionChannel/admitParticipant";
import { Spinner } from "../../../assets/icons/Icons";
import { store } from "../../../session/store";
import styled from "styled-components";
import { Branding } from "../Branding/Branding";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .text {
    width: 100%;
    text-align: center;
  }

  .button {
    margin: 30px auto 10px;
  }
`;

const ContentBox = styled.div`
  color: #fff;
  max-width: 400px;
  box-sizing: border-box;
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
`;

const Heading = styled.div`
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  text-align: center;
`;

const SpinnerWrapper = styled.div`
  margin: 20px auto;
  width: fit-content;

  svg {
    fill: #cccccc;
  }
`;

export const WaitingRoomScreen = () => {
  const participants = store.use.participants();
  const invitation = store.use.invitation();
  const currentUser = store.use.currentUser();

  const [buttonLoading, setButtonLoading] = useState<boolean | null>(null);

  const canManageWaitingRoom = can("manageParticipants", currentUser, invitation);

  const hostPresent = Object.values(participants).find(
    (p) => p.location == "session" && can("manageParticipants", p, invitation),
  );

  return (
    <Wrapper>
      <Branding fill={"white"} style={{ margin: "40px auto", width: "fit-content", paddingRight: "24px" }} />
      <ContentBox>
        <Heading>
          <FormattedMessage defaultMessage="You are in the waiting room" id="waiting_room.heading" />
        </Heading>
        {canManageWaitingRoom ? (
          <LoadingButton
            onClick={() => admitParticipant(currentUser.id, setButtonLoading)}
            loading={buttonLoading}
            className="button"
          >
            {hostPresent ? (
              <FormattedMessage defaultMessage="Join Session" id="waiting_room.join_from_waiting_room" />
            ) : (
              <FormattedMessage defaultMessage="Start Session" id="waiting_room.start_from_waiting_room" />
            )}
          </LoadingButton>
        ) : (
          <>
            <SpinnerWrapper>
              <Spinner />
            </SpinnerWrapper>
            {hostPresent ? (
              <FormattedMessage
                defaultMessage="The session facilitator is present and has been notified that you are here"
                id="waiting_room.facilitator_notified"
              />
            ) : (
              <FormattedMessage
                defaultMessage="The meeting host has not arrived yet"
                id="waiting_room.host_not_present"
              />
            )}
          </>
        )}
      </ContentBox>
    </Wrapper>
  );
};
